import '../../assets/css/LgvPopup/LgvPopup.css';
import {statusColorRenderer} from '../../util/helper/helperFunctions';


const LgvIcon = ({data, status}) => {
    return (
        <span style={{backgroundColor: `${statusColorRenderer(status)}`}} className='lgv-icon-style'>{data}</span>
    )
}

export default LgvIcon;