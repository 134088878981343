import '../../../assets/css/AdditionalDashboard/additionalDashboard.css';
import LogisticsImage from '../../../assets/images/logistic.png'
import PerformanceImage from '../../../assets/images/performance.png'
import ShipmentImage from '../../../assets/images/shipment.png'
import SummaryImage from '../../../assets/images/summary.png'
import PairingImage from '../../../assets/images/pairing.png'
import close from '../../../assets/icons/close.png';
import { useContext } from 'react';
import { AppContext } from '../../../Context/Context';
import { externalLinkNavigator } from '../../../util/helper/helperFunctions';

const AdditionalDashboardModal = () => {
    const {additionalDashboardModalIsOpen, setAdditionalDashboardModalIsOpen} = useContext(AppContext);
    const closeAdditionalDashboard = () => {
        setAdditionalDashboardModalIsOpen(!additionalDashboardModalIsOpen);
        document.body.classList.remove('prevent-scroll');
    }

    document.addEventListener('click', (e) => {
        if(e.target == document.getElementById('add-dashboard-wrapper-id')) {
            closeAdditionalDashboard();
        }
    })

    return (
        <div id='add-dashboard-wrapper-id' className="add-dashboard-wrapper">
            <div className="add-dashboard-container">
                <div className='close-section'>
                    <img onClick={() => closeAdditionalDashboard()} src={close}/>
                </div>
                <div className='dash-items-section'>
                    <a href={externalLinkNavigator('logistics')} target="_blank" rel="noopener noreferrer">
                        <div className="add-dashboard-item">
                            <img src={LogisticsImage}/>
                            <p>Plant Logistics Dashboard</p>
                        </div>
                    </a>
                    <a href={externalLinkNavigator('performance')} target="_blank" rel="noopener noreferrer">
                        <div className="add-dashboard-item">
                            <img src={PerformanceImage}/>
                            <p>LGV Performance Summary</p>
                        </div>
                    </a>
                    <a href={externalLinkNavigator('shipment')} target="_blank" rel="noopener noreferrer">
                        <div className="add-dashboard-item">
                            <img src={ShipmentImage}/>
                            <p>Shipment Progress</p>
                        </div>
                    </a>
                    <a href={externalLinkNavigator('downtime')} target="_blank" rel="noopener noreferrer">
                        <div className="add-dashboard-item">
                            <img src={SummaryImage}/>
                            <p>LGV Downtime Summary</p>
                        </div>
                    </a>
                    <a href={externalLinkNavigator('pairing')} target="_blank" rel="noopener noreferrer">
                        <div className="add-dashboard-item">
                            <img src={PairingImage}/>
                            <p>Active Mixed Pairing</p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default AdditionalDashboardModal;