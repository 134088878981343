import { useContext, useEffect, useState } from 'react';
import '../../../assets/css/LgvPopup/LgvPopup.css';
import { AppContext } from '../../../Context/Context';
import { setLocalStorageItem } from '../../../util/helper/helperFunctions';

const LgvPopup = ({lgv}) => {
    const {isMarkerPopupOpen, setIsMarkerPopupOpen} = useContext(AppContext);
    const {socketData, setSocketData} = useContext(AppContext);
    const [lgvData, setLgvData] = useState(lgv);
    
    document.addEventListener('click', (e) => {
        if(e.target == document.getElementById('lgv-wrapper-id')) {
            setIsMarkerPopupOpen(false);
            document.body.classList.remove('prevent-scroll');
        }
    })

    useEffect(() => {
        if(lgvData) {
            document.body.classList.add('prevent-scroll');
            setLocalStorageItem('lgvTouched', lgvData.vehicleNumber);
        }
    }, [])

    useEffect(() => {
        if(socketData) {
            let currentLgv = socketData.find((lgvData) => String(lgvData.vehicleNumber) == String(lgv.vehicleNumber));
            if(currentLgv !== undefined) {
                setLgvData(currentLgv);
            }
        }
    }, [socketData, lgvData])
    return (
        <div id='lgv-wrapper-id' className='popup-wrapper'>
            <div className='popup-container'>
                <div className='popup-title'>LGV Details</div>
                <div className='popup-section'>
                    <h4 className='popup-section-title'>LGV DATA</h4>
                    <div className='details-section'>
                        <div className='details'>
                            <h5 className='details-data-title'>Vehicle Number</h5>
                            <h5 className='details-data'>{lgvData.vehicleNumber !== null && lgvData?.vehicleNumber !== undefined ? lgvData.vehicleNumber : 'null'}</h5>
                        </div>
                        <div className='details'>
                            <h5 className='details-data-title'>Status</h5>
                            <h5 className='details-data'>{lgvData.status !== null && lgvData?.status !== undefined ? lgvData.status : 'null'}</h5>
                        </div>
                        <div className='details'>
                            <h5 className='details-data-title'>Source</h5>
                            <h5 className='details-data'>{lgvData.source !== null && lgvData?.source !== undefined ? lgvData.source : 'null'}</h5>
                        </div>
                        <div className='details'>
                            <h5 className='details-data-title'>Destination</h5>
                            <h5 className='details-data'>{lgvData.destination !== null && lgvData?.destination !== undefined ? lgvData.destination : 'null'}</h5>
                        </div>
                        <div className='details'>
                            <h5 className='details-data-title'>Segment</h5>
                            <h5 className='details-data'>{lgvData.segment !== null && lgvData?.segment !== undefined ? lgvData.segment : 'null'}</h5>
                        </div>
                        <div className='details'>
                            <h5 className='details-data-title'>X Axis</h5>
                            <h5 className='details-data'>{lgvData.currentPos?.lat !== null && lgvData.currentPos?.lat !== undefined ? (lgvData.currentPos.lat).toFixed(5) : 'null'}</h5>
                        </div>
                        <div className='details'>
                            <h5 className='details-data-title'>Y Axis</h5>
                            <h5 className='details-data'>{lgvData.currentPos?.lng !== null && lgvData.currentPos?.lng !== undefined ? (lgvData.currentPos.lng).toFixed(5) : 'null'}</h5>
                        </div>
                        <div className='details'>
                            <h5 className='details-data-title'>Duration</h5>
                            <h5 className='details-data'>{lgvData.duration !== null && lgvData?.duration !== undefined ? lgvData.duration : 'null'}</h5>
                        </div>
                        <div className='details'>
                            <h5 className='details-data-title'>Alarm Name</h5>
                            <h5 className='details-data'>{lgvData.alarmName !== null && lgvData?.alarmName !== undefined ? lgvData.alarmName : 'null'}</h5>
                        </div>
                    </div>
                </div>
                <div className='popup-section'>
                    <h4 className='popup-section-title'>ONBOARD PALLET DATA</h4>
                    <div className='details-section'>
                        <div className='details'>
                            <h5 className='details-data-title'>SKU</h5>
                            <h5 className='details-data'>{lgvData.sku ? lgvData.sku : 'null'}</h5>
                        </div>
                        <div className='details'>
                            <h5 className='details-data-title'>LPN 1</h5>
                            <h5 className='details-data'>{lgvData.lpn_1 ? lgvData.lpn_1 : 'null'}</h5>
                        </div>
                        <div className='details'>
                            <h5 className='details-data-title'>LPN 2</h5>
                            <h5 className='details-data'>{lgvData.lpn_2 ? lgvData.lpn_2 : 'null'}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default LgvPopup;