import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../Context/Context";
import { setLocalStorageItem } from "../../../util/helper/helperFunctions";

const LineProductionShowModal = ({lineData}) => {
    const {isMarkerPopupOpen, setIsMarkerPopupOpen} = useContext(AppContext);
    const [productionData, setProductionData] = useState(lineData);
    const {lineProductionData, setLineProductionData} = useContext(AppContext);
    const {selectedMarkerData, setSelectedMarkerData} = useContext(AppContext);

    useEffect(() => {
        if(lineProductionData.length !== 0) {
            let currentProductionData = lineProductionData.find((currentLine) => currentLine.LINE_NAME == lineData.LINE_NAME);
            setProductionData(currentProductionData);
        }
    }, [lineProductionData]);

    useEffect(() => {
        if(selectedMarkerData) {
            setProductionData(selectedMarkerData);
        }
    }, [selectedMarkerData])
    
    document.addEventListener('click', (e) => {
        if(e.target == document.getElementById('line-prod-popup-wrapper-id')) {
            document.body.classList.remove('prevent-scroll');
            setIsMarkerPopupOpen(false);
        }
    })

    useEffect(() => {
        document.body.classList.add('prevent-scroll');
        setLocalStorageItem('productionLineTouched', productionData.LINE_NAME)
    }, [])

    return (
        <div id="line-prod-popup-wrapper-id" className='line-prod-popup-wrapper'>
            <div className='line-prod-popup-container'>
                <div className='prod-popup-top-section'>
                    <h3>{lineData['LINE_NAME']} Production</h3>
                </div>
                <div className='prod-popup-bottom-section'>
                    <div className='bottom-item-container'>
                        <p className='prod-title'>Job Number</p>
                        <p className='prod-item-value'>{lineData['JOB_NUMBER'] !== null ? lineData['JOB_NUMBER'] : "-"}</p>
                    </div>
                    <div className='bottom-item-container'>
                        <p className='prod-title'>Item/SKU</p>
                        <p className='prod-item-value'>{lineData['ITEM'] !== null ? lineData['ITEM'] : "-"}</p>
                    </div>
                    <div className='bottom-item-container'>
                        <p className='prod-title'>Cases Produced</p>
                        <p className='prod-item-value'>{lineData['CASES_PRODUCED'] !== null ? lineData['CASES_PRODUCED'] : null}</p>
                    </div>
                    <div className='bottom-item-container'>
                        <p className='prod-title'>Cases Remaining</p>
                        <p className='prod-item-value'>{lineData['CASES_REMAINING'] !== null ? lineData['CASES_REMAINING'] : null}</p>
                    </div>
                    <div className='bottom-item-container'>
                        <p className='prod-title'>Cases Scheduled</p>
                        <p className='prod-item-value'>{lineData['CASES_SCHEDULED'] !== null ? lineData['CASES_SCHEDULED'] : null}</p>
                    </div>
                    <div className='bottom-item-container'>
                        {lineData.STATION_1 === 0 && lineData.STATION_2 === 0 ? 
                            <p className='prod-title'>{`No Position Calling`}</p> : 
                            <p className='prod-title'>
                                Position
                                <span> </span>
                                {lineData.STATION_1 === 1 ? 1 : ''}
                                {lineData.STATION_1 === 1 && lineData.STATION_2 === 1 ? <span>,</span> : ""}
                                <span> </span>
                                {lineData.STATION_2 === 1 ? 2 : ''}
                                <span> </span>
                                Calling
                            </p>
                        }
                        <p className='prod-item-value'></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LineProductionShowModal;