import '../../assets/css/DockDoor/dockDoor.css';
import { dockDoorStatusColor, findDockDoorPosition } from '../../util/helper/helperFunctions';

const DockIcon = ({dockMode, dockData, index}) => {
    if(!dockMode) {
        return <>
            {findDockDoorPosition() == 'UP' ? <div className='dock-top-section'>{dockData[0]['DOCK'].split('.')[1].slice(1)}</div> : null}
            <div className='dock-bottom-section'>
                {dockData.map((value, index) => {
                    return <div 
                        key={index} 
                        style={{backgroundColor: dockDoorStatusColor(value.STATUS, 'SIMPLE')}} 
                        className={`dock ${value.PALLETS == 1 ? 'dock-small' : 'dock-big'}`}>
                    </div>
                })}
            </div>
            {findDockDoorPosition() == 'DOWN' ? <div className='dock-top-section'>{dockData[0]['DOCK'].split('.')[1].slice(1)}</div> : null}
        </>
    } else {
        return <>
            {findDockDoorPosition() == 'UP' ? <div className='dock-top-section'>{dockData[0]['DOCK'].split('.')[1].slice(1)}</div> : null}
            <div className='dock-bottom-section'>
                {dockData.map((value, index) => {
                    if(value.PALLETS == 1) {
                        return <div key={index} style={{backgroundColor: dockDoorStatusColor(value.STATUS, 'COMPLEX')}} className={`dock dock-small`}>s</div>
                    } else {
                        return <div key={index} className='dock dock-big'>
                            <div style={{backgroundColor: dockDoorStatusColor(value.STATUS, 'COMPLEX')}} className='dock dock-big-complex-left'>s</div>
                            <div style={{backgroundColor: dockDoorStatusColor(value.STATUS, 'COMPLEX')}} className='dock dock-big-complex-right'>s</div>
                        </div>
                    }
                })}
            </div>
            {findDockDoorPosition() == 'DOWN' ? <div className='dock-top-section'>{dockData[0]['DOCK'].split('.')[1].slice(1)}</div> : null}
        </>
    }
}

export default DockIcon;