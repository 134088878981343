import React, { useContext, useEffect, useMemo, useState } from "react";
import '../assets/css/Dashboard/dashboard.css';

import Navbar from "../components/Navbar/Navbar";
import Header from "../components/Header/Header";
import Status from "../components/Status/Status";
import IndoorMapV2 from "../components/IndoorMap/IndoorMapV2";
import RemovedStatus from "../components/RemovedStatus/RemovedStatus";
import ActiveShipment from "../components/ActiveShipment/ActiveShipment";
import TimeMachine from "../components/TimeMachine/TimeMachine";

import getApi from "../util/network/getApi";
import { isTokenExpired, authExpirationNavigator, getScreenSize, setLocalStorageItem, apiHeadersFormatter, getLocalStorageItem, filterActivePlant, expireAuthToken, getAvailablePlant, clearLocalStorageItem, getPlantStates, isScreenBigger, reloadApp } from "../util/helper/helperFunctions";
import WarehouseModal from "../components/Modal/WarehouseModal/WarehouseModal";
import { AppContext } from "../Context/Context";
import CustomizePopup from "../components/Modal/CustomizePopup/CustomizePopup";
import AdditionalDashboardModal from "../components/Modal/AdditionalDashboard/AdditionalDashboardModal";
import { plantLocations } from "../util/network/apiUrl";
import LegendsPopup from "../components/Modal/LegendPopup/LegendPopup";
import LgvPopup from "../components/Modal/LgvPopup/LgvPopup";
import DockDoorPopup from "../components/Modal/DockDoorPopup/DockDoorPopup";
import LineProductionShowModal from '../components/Modal/LineProductionShowModal/LineProductionShowModal';
import MapModal from "../components/Modal/MapModal/MapModal";
import Greetings from "../components/Greetings/Greetings";
import TimeMachineNoDataModal from "../components/Modal/TimeMachineNoData/TimeMachineNoData";
import { initiateSocketConnection } from "../socket/socket";
import Heatmap from "../components/HeatMap/Heatmap";

const Dashboard = () => {
    //Removed Status
    const [fleetData, setFleetData] = useState({fleetDataCount:[], lgvStatus:[]});
    const [fleetStatus, setFleetStatus] = useState();
    const [fleetCount, setFleetCount] = useState();
    const {removedLgvViewIsOpen, setRemovedLgvViewIsOpen} = useContext(AppContext);

    //Shipment Status
    const [shipmentData, setShipmentData] = useState();
    const [activeShipmentData, setActiveShipmentData] = useState();
    const {locationsData, setLocationsData} = useContext(AppContext);

    //Shift Performance
    const [shiftPerformanceData, setShiftPerformanceData] = useState();

    //Warehouse
    const [warehouseData, setWarehouseData] = useState();
    const {warehouseModelIsOpen, setWarehouseModelIsOpen} = useContext(AppContext);
    const {warehouseModel, setWarehouseModel} = useContext(AppContext);

    //Line Production
    const {lineProductionData, setLineProductionData} = useContext(AppContext);
    const {productionLineViewIsOpen, setProductionLineViewIsOpen} = useContext(AppContext); 

    //Dock door
    const [dockDoorData, setDockDoorData] = useState();
    const {dockDoorViewIsOpen, setDockDoorViewIsOpen} = useContext(AppContext);

    //Additional Dashboard
    const {additionalDashboardModalIsOpen, setAdditionalDashboardModalIsOpen} = useContext(AppContext);
    
    const [errorApi, setErrorApi] = useState(0);
    const {selectedMarkerData, setSelectedMarkerData} = useContext(AppContext);
    const {selectedMarkerType, setSelectedMarkerType} = useContext(AppContext);

    //Popup
    const {isLegendsPopupOpen, setIsLegendsPopupOpen} = useContext(AppContext);
    const {customizePopup, setCustomizePopup} = useContext(AppContext);
    const {isMarkerPopupOpen, setIsMarkerPopupOpen} = useContext(AppContext);
    const [location, setLocation] = useState("");
    const {locationModal, setLocationModal} = useContext(AppContext);
    const {locationCode, setLocationCode} = useContext(AppContext);

    //Heatmap
    const {isNoHeatMapDataModalOpen, setIsNoHeatMapDataModalOpen} = useContext(AppContext);
    const {isHeatmapModeEnabled, setIsHeatmapModeEnabled} = useContext(AppContext);

    //TimeMachine
    const {timeMachineModeIsEnabled, setTimeMachineModeIsEnabled} = useContext(AppContext);

    //Socket Connection
    const [socketConnection, setSocketConnection] = useState();
    const {connectionStatus, setConnectionStatus} = useContext(AppContext);

    useEffect(() => {
        const pathName = window.location.pathname
        let existingLocation = getLocalStorageItem('locationCode');
        let pathCode = pathName.split('/')[1];
        if(existingLocation && pathCode) {
            if(existingLocation !== pathCode) {
                window.location.replace(`${window.location.origin}`);
            }
        }
    }, [getLocalStorageItem('locationCode')])
    

    const closeLocationModal = () => {   
        setLocationModal(false);
    }

    const openLocationModel = () => {
        setLocationModal(true);
    }

    const closeHeatmapModal = () => {   
        setIsHeatmapModeEnabled(false);
    }

    const openHeatmapModel = () => {
        setIsHeatmapModeEnabled(true);
    }

    const setLocationToURL = () => {
        let appUrl = new URL(window.location.href);
        appUrl.searchParams.append('location', getLocalStorageItem('locationCode'));
    }

    useEffect(() => {
        const pathName = window.location.pathname
        if(pathName.length !== 1) {
            let locationCode = pathName.split('/')[1];
            if(locationCode && getAvailablePlant(locationCode)) {
                let stateName = getPlantStates(locationCode);
                setLocation(stateName);
                setLocalStorageItem('locationSelected',stateName);
                setLocalStorageItem('locationCode', locationCode);
            } else {
                let existingLocation = getLocalStorageItem('locationCode');
                if(existingLocation) {
                    window.location.replace(`${window.location.origin}/${existingLocation}`)
                } else {
                    window.location.replace(window.location.origin);
                }
            }
        } else {
            let locationCode = getLocalStorageItem('locationCode');
            if(locationCode && locationCode.length !== 0) {
                window.location.replace(`${window.location.origin}/${locationCode}`);
            }
        }
        setTimeMachineModeIsEnabled(false);
        localStorage.setItem('isTimeMachineModeEnabled', "false");
        setLocalStorageItem('seekPosition', 1);
        setLocationToURL();
        setLocalStorageItem('enableLgvMarkers', true);
    }, [])

    useEffect(() => {
        if(getLocalStorageItem('locationCode')) {
            try{
                let locationCode = getLocalStorageItem('locationCode');
                let socketInstance = initiateSocketConnection(locationCode); 
                setSocketConnection(socketInstance);
                setConnectionStatus(true);

                socketInstance.on("reconnect", () => {
                    setConnectionStatus(true);
                })

                socketInstance.on("disconnect", (reason) => {
                    setConnectionStatus(false);
                    if(reason === "io server disconnect") {
                        socketInstance.connect();
                        setConnectionStatus(true);
                    }
                });
            
                return () => {
                    socketInstance.disconnect();
                    setConnectionStatus(false);
                };
            } catch(err) {
                console.log(err);
            }
        }
        clearLocalStorageItem('dockCoordinates');
    }, [getLocalStorageItem('locationCode')])

    useEffect(() => {
        let selectedLocation = localStorage.getItem('locationSelected');
        if(selectedLocation !== '') {
            setLocation(selectedLocation);
        }
    },[localStorage.getItem('locationSelected')]);

    useEffect(() => {
        const getLocations = async(auth) => {
            let locData = await getApi(plantLocations, apiHeadersFormatter(auth.accessToken));
            if(locData.status == 200 && locData.data) {
                let filteredPlants = filterActivePlant(locData);
                const pathName = window.location.pathname
                if(pathName.length !== 1) {
                    let locationCode = pathName.split('/')[1];
                    filteredPlants.map((plant) => {
                        if(plant.code == locationCode) {
                            setLocalStorageItem('timeZone', plant.timeZone);
                        }
                    })
                }
                setLocationsData(filteredPlants);
            } else {
                if(locData.response.status == 401 || locData?.error == 'Authentication Error!') {
                    expireAuthToken();
                }
                getLocations(auth);
            }
        }
        setTimeout(() => {
            let auth = getLocalStorageItem('auth');
            if(auth) {
                getLocations(auth);
            }
        }, 2500)
    }, []);
    
    useEffect(() => {
        let auth = JSON.parse(localStorage.getItem('auth'));
        let locationCodeLocal = JSON.parse(localStorage.getItem('locationCode'));
        if(auth !== null && locationCodeLocal) {
            if (checkTimeMachineStatus() && socketConnection) {
                const initiateSimpleDockDoorSocketChannel = () => {
                    socketConnection.on(`${locationCodeLocal}_DOCK_DETAILS_SIMPLE`, (response) => {
                        if(response) {
                            if(response.results.length !== 0 || response.results !== 'Error connecting to datasource!') {
                                setDockDoorData(response.results);
                            }
                        }
                    })
                }

                const initiateComplexDockDoorSocketChannel = () => {
                    socketConnection.on(`${locationCodeLocal}_DOCK_DETAILS_COMPLEX`, (response) => {
                        if(response) {
                            if(response.results.length !== 0 || response.results !== 'Error connecting to datasource!') {
                                setDockDoorData(response.results);
                            }
                        }
                    })
                }

                if(!getLocalStorageItem('isTimeMachineModeEnabled')) {
                    let dockMode = !(JSON.parse(localStorage.getItem("dockMode"))) ? 1 : 0;
                    if(dockMode == 0) {
                        initiateComplexDockDoorSocketChannel();
                    } else {
                        initiateSimpleDockDoorSocketChannel();
                    }
                }
            }
        }
    }, [socketConnection, getLocalStorageItem('isTimeMachineModeEnabled'), getLocalStorageItem('auth'), getLocalStorageItem('dockMode'), getLocalStorageItem('locationCode'), timeMachineModeIsEnabled, locationCode])

    const checkTimeMachineStatus = () => {
        if(!timeMachineModeIsEnabled || !getLocalStorageItem("isTimeMachineModeEnabled")) {
            return true;
        } else {
            return false;
        }
    }

    // useEffect(() => {
    //     console.log(getLocalStorageItem('auth'))
    // }, [getLocalStorageItem('auth')])

    useEffect(() => {
        let auth = getLocalStorageItem('auth');
        let locationCodeLocal = JSON.parse(localStorage.getItem('locationCode'));
        if(auth !== null && locationCodeLocal) {
            if(checkTimeMachineStatus()){
                const initiateFleetStatusWebSocket = () => {
                    socketConnection.on(`${locationCodeLocal}_LGV_FLEET_STATUS`, (data) => {
                        if(data.results && data.results !== undefined) {
                            setFleetStatus(data.results.lgvStatus);
                        }
                    })
                }

                const initiateFleetCountWebSocket = () => {
                    socketConnection.on(`${locationCodeLocal}_LGV_FLEET_COUNT`, (data) => {
                        if(data.results && data.results !== undefined) {
                            setFleetCount(data.results.fleetData);
                        }
                    })
                }

                const initiateActiveShipmentWebSocket = () => {
                    socketConnection.on(`${locationCodeLocal}_SHIPMENT_DETAILS`, (data) => {
                        if(data.results && data.results !== undefined) {
                            setActiveShipmentData(data.results);
                        }
                    })
                }

                const initiateShipmentWebSocket = () => {
                    socketConnection.on(`${locationCodeLocal}_SHIPMENT_STATUS`, (data) => {
                        if(data.results && data.results !== undefined) {
                            setShipmentData(data.results);
                        }
                    })
                }

                const initiateShiftPerformanceWebSocket = () => {
                    socketConnection.on(`${locationCodeLocal}_SHIFT_DETAILS`, (data) => {
                        if(data.results && data.results !== undefined) {
                            setShiftPerformanceData(data.results);
                        }
                    })
                }

                const initiateWarehouseWebSocket = () => {
                    socketConnection.on(`${locationCodeLocal}_WAREHOUSE_CAPACITY`, (data) => {
                        if(data.results && data.results !== undefined) {
                            setWarehouseData(data.results);
                        }
                    })
                }

                const initiateLineProductionWebSocket = () => {
                    socketConnection.on(`${locationCodeLocal}_PRODUCTION_DETAILS`, (data) => {
                        if(data.results && data.results !== undefined) {
                            setLineProductionData(data.results);
                        }
                    })
                }

                if(socketConnection && !getLocalStorageItem('isTimeMachineModeEnabled')) {
                    initiateFleetStatusWebSocket();
                    initiateFleetCountWebSocket();
                    initiateShipmentWebSocket();
                    initiateActiveShipmentWebSocket();
                    initiateShiftPerformanceWebSocket();
                    initiateWarehouseWebSocket();
                    initiateLineProductionWebSocket();
                }
            }
        }
    }, [socketConnection, getLocalStorageItem('isTimeMachineModeEnabled'), getLocalStorageItem('auth'), getLocalStorageItem('locationCode'), timeMachineModeIsEnabled, locationCode]);

    useEffect(() => {
        if(localStorage.getItem('appSettings')) {
            let prevSwitchState = getLocalStorageItem('appSettings');
            if(prevSwitchState.removedLgv == true) {
                setRemovedLgvViewIsOpen(true);
            }
            prevSwitchState.destinationLines = false;
            setLocalStorageItem('appSettings', prevSwitchState);
        }
    }, [])

    return (
        <div className="dashboard-container">
            {location && localStorage.getItem('auth') ? 
                <>
                    <Navbar siteLocations={locationsData}/>
                    <div className="flexible-screen">
                        <div className="flexible-left-screen">
                            <Header/>
                            {timeMachineModeIsEnabled ? 
                                <TimeMachine socket={socketConnection}/>
                            : 
                                <>
                                    <Status title='Shipment Status' statusData={shipmentData}/>
                                    {socketConnection !== null ? <IndoorMapV2 socket={socketConnection} lineProductionData={lineProductionData} dockDoorData={dockDoorData}/> : null}
                                    <ActiveShipment title='Active Shipment Metrics' statusData={activeShipmentData}/>
                                    {removedLgvViewIsOpen ? 
                                        <RemovedStatus title='Removed LGV Status Time' fleetStatus={fleetStatus} fleetCount={fleetCount} removedStatusData={fleetData} scrollable={true}/>
                                    : null}
                                    {customizePopup ? 
                                        <CustomizePopup/> 
                                    : null}
                                    {warehouseModelIsOpen ? <WarehouseModal warehouseData={warehouseData} shiftData={shiftPerformanceData}/> : null}
                                    {additionalDashboardModalIsOpen ? <AdditionalDashboardModal/> : null}
                                    {isLegendsPopupOpen ? <LegendsPopup/> : null}

                                    {selectedMarkerData && selectedMarkerType == 'lgv' && isMarkerPopupOpen ? 
                                        <LgvPopup lgv={selectedMarkerData}/> 
                                    : null}
                                    {socketConnection && selectedMarkerData && selectedMarkerType == 'dock' && isMarkerPopupOpen ? 
                                        <DockDoorPopup dockData={selectedMarkerData} dockMode={JSON.parse(localStorage.getItem('dockMode'))} socket={socketConnection}/> 
                                    : null}
                                    {selectedMarkerData && selectedMarkerType == 'productionLine' && isMarkerPopupOpen ? 
                                        <LineProductionShowModal lineData={selectedMarkerData}/> 
                                    : null}
                                    {isNoHeatMapDataModalOpen ? <TimeMachineNoDataModal/> : null}
                                </>
                            }
                        </div>
                        <div className="flexible-right-screen">
                            {getScreenSize() > 2500 || isScreenBigger() ? 
                                <WarehouseModal warehouseData={warehouseData} shiftData={shiftPerformanceData}/>
                                : null
                            }
                        </div>
                    </div>
                </>
            : <div>
                <Greetings openLocationModal={openLocationModel}/>
            </div>}
            {isHeatmapModeEnabled ? <Heatmap closeModal={closeHeatmapModal}/> : null}
            {locationModal ? <MapModal selectedLocation={location} siteLocations={locationsData} closeModal={closeLocationModal}/> : null}
        </div>
    )
}

export default Dashboard;