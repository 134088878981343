export const lgvIconStyles = `
    display:flex;
    background-color: #2b2b2b;
    width:18px;
    height:18px;
    border:1px solid #ffffff;
    border-radius:2px;
    font-size: 12px;
    color:white;
    display:flex;
    justify-content:center;
    align-items:center;
    font-family: 'Poppins' !important;
    z-index: 1008 !important;
`
export const heatmapHorizontalCount = 25;
export const heatmapVerticalCount = 57;

export const plantCarLatSize = 27;
export const plantCarLngSize = 27;

export const defaultMarkerData = {vehicleNumber: '1', status: "null", source:'null', destination: 'null', segment:"null", currentPos:{lat:1, lng:1}, duration: '1', alarmName: "null", sku:"1", lpn:'1'};

export const noDataDockDetails = {
    "DOOR": "No Data Found",
    "STATUS": "No Data Found",
    "TYPE": "No Data Found",
    "SHIPMENT": "No Data Found",
    "LOADTIME": 0,
    "ALLOCATED": 0,
    "RELEASED": 0,
    "ACTIVE": 0,
    "REMAINPALL": 0,
    "CURRENT_DATE_TIME": "No Data Found",
    "PROCESSTIME": 0,
    "PROGRESS": 0
}

export const getColorPallets = () => {
    return [
        {
            "name": "LGV LEGENDS",
            "colors": {
                "Available": "#008000",
                "Loaded": "#0000FF",
                "In Operation/traffic": "#D96E0B",
                "Deadlock": "#EF1FDA",
                "Lost": "#B9B9B9",
                "Removed": "#000000",
                "In Alarm": "#EE3232",
                "In Manual": "#800000"
            }
        },
        {
            "name": "PRODUCTION LINE LEGENDS",
            "colors": {
                "Line Running": "#4133E1",
                "Pallets waiting for pickup": "#42C721",
                "# LGVs to pickup at line": "#238749",
            }
        },
        {
            "name": "TRAILER PALLET LEGENDS",
            "colors": {
                "Completed": "#42C721",
                "Released": "#E1CC0F",
                "Allocated": "#B9B9B9",
                "In Transit/Started": "#4133E1",
                "Can Allocate": "#D90000",
                "New": "#FF5733"
            }
        }
    ]
}

export const dummyProductionLine = [
    {
        "LINE_NAME": "L1",
        "STATUS": "Active",
        "ITEM": "MMK05L40PDMPCNR02",
        "JOB_NUMBER": "L00878253",
        "CASES_SCHEDULED": 37536,
        "CASES_PRODUCED": 8112,
        "CASES_REMAINING": 29424,
        "LGV": 0,
        "PALLETS": 3,
        "STATION_1": 1,
        "STATION_2": 0,
        "FIP": 0,
        "LONGITUDE": 275639,
        "LATITUDE": 37930
    },
    {
        "LINE_NAME": "L2",
        "STATUS": "Active",
        "ITEM": "CLO05L32PDRPCN",
        "JOB_NUMBER": "L00879370",
        "CASES_SCHEDULED": 10860,
        "CASES_PRODUCED": 9420,
        "CASES_REMAINING": 1440,
        "LGV": 0,
        "PALLETS": 3,
        "STATION_1": 0,
        "STATION_2": 0,
        "FIP": 0,
        "LONGITUDE": 254550,
        "LATITUDE": 37970
    },
    {
        "LINE_NAME": "L3",
        "STATUS": "Active",
        "ITEM": "GRV05L40PDMPCN",
        "JOB_NUMBER": "L00879877",
        "CASES_SCHEDULED": 47280,
        "CASES_PRODUCED": 37008,
        "CASES_REMAINING": 10272,
        "LGV": 0,
        "PALLETS": 3,
        "STATION_1": 0,
        "STATION_2": 0,
        "FIP": 0,
        "LONGITUDE": 149253,
        "LATITUDE": 38006
    },
    {
        "LINE_NAME": "L4",
        "STATUS": "Active",
        "ITEM": "GRV05L40PDMPCN",
        "JOB_NUMBER": "L00879877",
        "CASES_SCHEDULED": 47280,
        "CASES_PRODUCED": 37008,
        "CASES_REMAINING": 10272,
        "LGV": 0,
        "PALLETS": 3,
        "STATION_1": 0,
        "STATION_2": 0,
        "FIP": 0,
        "LONGITUDE": 149253,
        "LATITUDE": 38006
    }
]