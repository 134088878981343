import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import '../../../assets/css/Heatmap/heatmapModal.css';
import { ImageOverlay, MapContainer, Marker, Rectangle } from 'react-leaflet';
import L from 'leaflet';
import { apiHeadersFormatter, clearLocalStorageItem, convertGridCoordinates, convertGridCoordinatesX, expireAuthToken, getHeatmapWeightage, getLocalStorageItem, getPlantCoordinates, getResponsiveGridLatLng, heatmapCoordinateCorrection, lgvCoordinateCorrection, reloadApp, setLocalStorageItem, timeFrameFormatter } from '../../../util/helper/helperFunctions';
import { AppContext } from '../../../Context/Context';
import getApi from '../../../util/network/getApi';
import { heatmapDetails, lgvHistoryStatus } from '../../../util/network/apiUrl';
import { heatmapHorizontalCount, heatmapVerticalCount, plantCarLatSize, plantCarLngSize } from '../../../util/helper/constants';
import LgvIcon from '../../LgvIcon/LgvIcon';
import ReactDOMServer from 'react-dom/server';

const HeatmapModal = ({closeModal}) => {
    const [mapObject, setMapObject] = useState();
    const heatmapModalRef = useRef(null);
    const heatmapModalContainerRef = useRef(null);
    const [rawHeatmapData, setRawHeatmapData] = useState([]);
    const [gridData, setGridData] = useState();
    const [lgvObjects, setLgvObjects] = useState();

    const {isHeatmapModeEnabled, setIsHeatmapModeEnabled} = useContext(AppContext);
    const {isHeatmapDataAvailable, setIsHeatmapDataAvailable} = useContext(AppContext);
    const {convertedHeatmapData, setConvertedHeatmapData} = useContext(AppContext);
    const {heatmapTimeFrames, setHeatmapTimeFrames} = useContext(AppContext);
    const {heatmapLoader, setHeatmapLoader} = useContext(AppContext);
    const {heatmapRef, setHeatmapRef} = useContext(AppContext);
    const {loaderText, setLoaderText} = useContext(AppContext);

    useEffect(() => {
        if(heatmapModalContainerRef !== null) {
            setHeatmapRef(heatmapModalContainerRef);
        }
    }, [heatmapModalContainerRef])

    const mapWhenReadyHandler = (map) => {
        setMapObject({map});
    }

    let rectBottomLeftXNew = 25; //L
    let rectBottomLeftYNew = 640.453125; // B
    let rectTopRightXNew = 1515; //R
    let rectTopRightYNew = 25.453125; //T

    //Web Object Coordinates converter
    const mapCoordinate = (coordinateX, coordinateY, renderObject) => {
        let locationCode = getLocalStorageItem('locationCode');
        if(locationCode) {
            const {
                rectBottomLeftX,
                rectBottomLeftY,
                rectTopRightX,
                rectTopRightY,
            } = getPlantCoordinates(locationCode, renderObject);
            
            let ratioX = (coordinateX - rectBottomLeftX) / (rectTopRightX - rectBottomLeftX);
            let ratioY = (coordinateY - rectBottomLeftY) / (rectTopRightY - rectBottomLeftY);
    
            let translatedX = rectBottomLeftXNew + ratioX * (rectTopRightXNew - rectBottomLeftXNew);
            let translatedY = rectBottomLeftYNew + ratioY * (rectTopRightYNew - rectBottomLeftYNew);
    
            return {
                x: translatedX,
                y: translatedY
            }
        }
    }

    //Rendering Map on the Map container with Map Object and coordinates
    const RenderMap = useMemo(() => {
        if(mapObject) {
            const plantDetails = getLocalStorageItem('plantHeatMapDetails');
            if(plantDetails !== undefined) {
                const {leftBottom, rightTop} = plantDetails.bounds;
                const {image} = plantDetails;
                let convertedCoordinates = mapCoordinate(leftBottom.lat, leftBottom.lng, 'map'); //L , B
                let convertedPoints = L.point(convertedCoordinates.x, convertedCoordinates.y);
                let convertedLatLng = mapObject.map.target.layerPointToLatLng(convertedPoints);
        
                let convertedCoordinates1 = mapCoordinate(rightTop.lat, rightTop.lng, 'map'); //R,T
                let convertedPoints1 = L.point(convertedCoordinates1.x, convertedCoordinates1.y);
                let convertedLatLng1 = mapObject.map.target.layerPointToLatLng(convertedPoints1);
        
                let overlayBounds = [[convertedLatLng.lat, convertedLatLng.lng], [convertedLatLng1.lat, convertedLatLng1.lng]]
                return <ImageOverlay url={image} bounds={overlayBounds}/>
            } else {
                clearLocalStorageItem('plantDetails');
                reloadApp();
            }
        }
    })

    //Reducing the border width of grid boxes after rendering
    const changeStrokeWidth = () => {
        let boxElements = document.querySelectorAll('path.leaflet-interactive')
        boxElements.forEach((element) => {
            element.style.strokeWidth = '0.5';
        })
    }

    //Filtering Alarm and Traffic LGV and converting the coordinates to our LGV One system
    const heatmapCoordinateConverter = (data) => {
        let filteredData = [];
        if(mapObject) {
            let correctionUnits = heatmapCoordinateCorrection();
            data.map((marker) => {
                if(marker.status == 'Alarm' || marker.status == 'Traffic') {
                    let convertedCoordinates = mapCoordinate(marker['longitude'], marker['latitude'], 'heatmap');
                    let convertedPoints = L.point(convertedCoordinates.x, convertedCoordinates.y);
                    let convertedLatLng = mapObject.map.target.layerPointToLatLng(convertedPoints);
                    marker.currentPos = {lat: convertedLatLng.lat + correctionUnits.x, lng: convertedLatLng.lng + correctionUnits.y};
                    filteredData.push(marker);
                }
            })
            return filteredData;
        }
    }

    //API call to get the Traffic and Alarm LGV with timeframe
    const getHeatmapDetails = async () => {
        let auth = JSON.parse(localStorage.getItem('auth'));
        let locationCode = getLocalStorageItem('locationCode');
        if(auth.accessToken && locationCode) {
            const {fromTimePlant, toTimePlant} = timeFrameFormatter(heatmapTimeFrames);
            let timeMachinePagesResp = await getApi(`${heatmapDetails}/${fromTimePlant}/${toTimePlant}?location=${locationCode}`, apiHeadersFormatter(auth.accessToken));
            if(timeMachinePagesResp.status === 200 && timeMachinePagesResp.data !== 'Error connecting to datasource!') {
                if(timeMachinePagesResp.data) {
                    setLocalStorageItem('heatmapTotalPageCount', timeMachinePagesResp.data.totalPages);
                    if(rawHeatmapData.length !== 0) {
                        let rawHeatmapDataLocal = JSON.parse(JSON.stringify(rawHeatmapData)).concat(timeMachinePagesResp.data.results);
                        setRawHeatmapData(rawHeatmapDataLocal);
                        setHeatmapLoader(false);
                        return rawHeatmapDataLocal;
                    } else {
                        setRawHeatmapData(timeMachinePagesResp.data);
                        setHeatmapLoader(false);
                        return timeMachinePagesResp.data;
                    }
                } else {
                    setIsHeatmapDataAvailable(false);
                    setLoaderText('No Data');
                }
            } else {
                if(timeMachinePagesResp.response.status === 401) {
                    expireAuthToken();
                } 
                return [];
            }
        }
    }

    //Converting empty grids to LGV Objects to find the weightage of each boxes
    const convertGridObjectToLgvObject = (gridData, heatmapData) => {
        gridData.map((gridBounds, index) => {
            gridBounds.index = index;
            gridBounds.count = 0;
            gridBounds.lgvList = [];
            let rectBounds = L.rectangle(gridBounds.bounds);
            heatmapData && heatmapData.map((lgv) => {
                if(lgv.currentPos) {
                    let lgvPosition = L.latLng(lgv.currentPos);
                    if(rectBounds.getBounds().contains(lgvPosition)) {
                        gridBounds.lgvList.push(lgv);
                        gridBounds.count += 1;
                    }
                }
            })
        })
        return gridData;
    }

    //Create Empty Grid Box with coordinates
    const createGridBoxes = () => {
        const grid = [];
        const verticalBoxCount = heatmapHorizontalCount;
        const horizontalBoxCount = heatmapVerticalCount;
        if(mapObject) {
            let convertedLatLng = convertGridCoordinates(mapObject);
            let convertedLatLngX = convertGridCoordinatesX(mapObject);
            let gridLatLng = getResponsiveGridLatLng();
            console.log(gridLatLng);
            // Calculate the coordinates for the grid of rectangles
            for (let i = 0; i < verticalBoxCount; i++) {
                for (let j = 0; j < horizontalBoxCount; j++) {
                    const lat1 = i * plantCarLatSize - (convertedLatLngX.lat + gridLatLng.x);
                    const lng1 = j * plantCarLngSize + convertedLatLng.lng + gridLatLng.y;
                    const lat2 = lat1 + plantCarLatSize;
                    const lng2 = lng1 + plantCarLngSize;
                    const center = [(lat1 + lat2) / 2, (lng1 + lng2) / 2];
                    grid.push({ bounds: [[lat1, lng1], [lat2, lng2]], center });
                }
            }
            return grid;
        }
    }

    useEffect(() => {
        //Initiating Heatmap on start of the Component with conditions
        const initiateHeatmapOnStart = async () => {
            let heatmapRespData;
            if(isHeatmapModeEnabled && rawHeatmapData.length == 0 && mapObject) {
                if(Object.keys(heatmapTimeFrames).length !== 0) {
                    setHeatmapLoader(true);
                    heatmapRespData = await getHeatmapDetails();
                    if(heatmapRespData.length !== 0) {
                        let filteredAndConvertedData = heatmapCoordinateConverter(heatmapRespData);
                        if(filteredAndConvertedData.length !== 0){
                            let emptyGridData = getLocalStorageItem('emptyGridData');
                            if(emptyGridData == null) {
                                let gridData = createGridBoxes();
                                setLocalStorageItem('emptyGridData', gridData);
                                setGridData(gridData);
                                let gridLgvData = convertGridObjectToLgvObject(gridData, filteredAndConvertedData);
                                if(gridLgvData) {
                                    setConvertedHeatmapData(gridLgvData);
                                }
                            } else {
                                let gridLgvData = convertGridObjectToLgvObject(emptyGridData, filteredAndConvertedData);
                                if(gridLgvData) {
                                    setConvertedHeatmapData(gridLgvData);
                                }
                            }
                        }
                    } else {
                        initiateHeatmapOnStart();
                    }
                }
            }
        }
        initiateHeatmapOnStart();
    }, [isHeatmapModeEnabled, heatmapTimeFrames, rawHeatmapData, mapObject])

    //Creating Icons for LGVs
    const convertReactComponentToHtmlIcon = (reactComponent, className, iconSize, iconAnchor) => {
        const reactToHtml = ReactDOMServer.renderToString(reactComponent);
        return L.divIcon({
            className: className,
            iconSize: iconSize,
            iconAnchor: iconAnchor ? iconAnchor : null,
            html: reactToHtml
        })
    }

    const RenderLgv = useMemo(() => {
        if(lgvObjects) {
            return lgvObjects.map((marker, index) => {
                let markerData = marker;
                let reactIcon = convertReactComponentToHtmlIcon(
                    <LgvIcon data={markerData.vehicleNumber} status={'Traffic'}/>, 'lgv-marker', [25,25], [42.5,50]
                )
                return <Marker
                    key={index} 
                    riseOnHover
                    position={[markerData.currentPos.lat, markerData.currentPos.lng]} 
                    icon={reactIcon}>
                </Marker>
            })
        }
    })

    //Rendering the Heatmap as Rectangle with the Grid boxes with weitage as color 
    const RenderHeatmap = useMemo(() => {
        if(isHeatmapModeEnabled && convertedHeatmapData) {
            return convertedHeatmapData && convertedHeatmapData.map((rectangle, index) => {
                let bounds = L.rectangle(rectangle.bounds).getBounds();
                return <Rectangle
                    pathOptions={getHeatmapWeightage(rectangle.lgvList.length)} 
                    key={index}
                    bounds={bounds}
                    color='black'
                    strokeColor='black'
                >
                    {changeStrokeWidth()}
                </Rectangle>
            })
        }
    })

    //Centering the World space to a particular coordinates to center the Map
    const [convertedCenter, setConvertedCenter] = useState();
    const centerMapView = () => {
        let convertedCoordinates = mapCoordinate(275000, 100000);
        let convertedPoints = L.point(convertedCoordinates.x, convertedCoordinates.y);
        let convertedLatLng = mapObject.map.target.layerPointToLatLng(convertedPoints);
        mapObject.map.target.setView([convertedLatLng.lat, convertedLatLng.lng]);
        setConvertedCenter(convertedLatLng);
    }

    //Centering the Map whenever there is a change in the screen size or map object.
    useEffect(() => {
        if(mapObject) {
            centerMapView();
            mapObject.map.target.doubleClickZoom.disable();
        }
    }, [mapObject])

    return (
        <div ref={heatmapModalContainerRef} id='heatmap-modal-map-container-id' className='modal-map-container'>
            <MapContainer
                style={{height:'100%', width:'100%', borderRadius:'20px'}}
                center={[0,0]}
                zoom={0}
                crs={L.CRS.Simple}
                zoomControl={false}
                attributionControl={false}
                whenReady={(map) => mapWhenReadyHandler(map)}
                scrollWheelZoom={false}
                disableDoubleClickZoom={false}
                dragging={false}
                ref={heatmapModalRef}
            >
                {RenderMap}
                {convertedHeatmapData ? RenderHeatmap : null}
            </MapContainer>
        </div>
    )
}

export default HeatmapModal;