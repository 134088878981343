import './App.css';
import React, { useState, useEffect } from 'react';
import Dashboard from './pages/Dashboard';
import { useMsalAuthentication } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { AppContext } from './Context/Context';
import { defaultMarkerData } from './util/helper/constants';
import ProtectedRoute from './Routes/ProtectedRoute';
import { getLocalStorageItem, reloadApp, setLocalStorageItem } from './util/helper/helperFunctions';
import BackendConnectivity from './components/BackendConnectivity/BackendConnectivity';
import { BrowserRouter } from 'react-router-dom';

const App = () => {
  useMsalAuthentication(InteractionType.Redirect);
  const [msalUser, setMsalUser] = useState("");
  const [accessToken, setAccessToken] = useState(null);

  //Warehouse
  const [warehouseModel, setWarehouseModel] = useState(false);
  const [warehouseModelIsOpen, setWarehouseModelIsOpen] = useState(false);

  //Popups
  const [customizePopup, setCustomizePopup] = useState(false);
  const [timeRangePopup, setTimeRangePopup] = useState(false);
  const [additionalDashboardModalIsOpen, setAdditionalDashboardModalIsOpen] = useState(false);
  const [isLegendsPopupOpen, setIsLegendsPopupOpen] = useState(false);

  //TimeMachine
  const [timeMachineControllerIsOpen, setTimeMachineControllerIsOpen] = useState(true);
  const [timeMachineModeIsEnabled, setTimeMachineModeIsEnabled] = useState(false);
  const [timeMachineTimeFrames, setTimeMachineTimeFrames] = useState({});
  const [timeMachineState, setTimeMachineState] = useState(true);
  const [timeMachineLoader, setTimeMachineLoader] = useState(true);
  const [isTimeMachineDataAvailable, setIsTimeMachineDataAvailable] = useState(true);
  const [currentPageCount, setCurrentPageCount] = useState();

  //Heatmap
  const [isHeatmapDataAvailable, setIsHeatmapDataAvailable] = useState(true);
  const [isNoHeatMapDataModalOpen, setIsNoHeatMapDataModalOpen] = useState(false);
  const [isHeatmapModeEnabled, setIsHeatmapModeEnabled] = useState(false);
  const [heatmapTimeFrames, setHeatmapTimeFrames] = useState({});
  const [enableHeatmap, setEnableHeatmap] = useState(false);
  const [convertedHeatmapData, setConvertedHeatmapData] = useState(null);
  const [heatmapLoader, setHeatmapLoader] = useState(true);
  const [heatmapRef, setHeatmapRef] = useState(null);
  const [loaderText, setLoaderText] = useState('Loading Heatmap...');
  
  //Production Line
  const [enableProductionLine, setEnableProductionLine] = useState(true);
  const [productionLineViewIsOpen, setProductionLineViewIsOpen] = useState(true);
  const [lineProductionData, setLineProductionData] = useState();

  //Dock door
  const [enableDockDoor, setEnableDockDoor] = useState(true);
  const [dockDoorViewIsOpen, setDockDoorViewIsOpen] = useState(true);
  const [dockDoorMode, setDockDoorMode] = useState(false);

  //LGV
  const [lgvObjectViewIsOpen, setLgvObjectViewIsOpen] = useState(true);
  const [socketData, setSocketData] = useState();

  //Removed LGV
  const [removedLgvViewIsOpen, setRemovedLgvViewIsOpen] = useState(true);

  //Destination Pointers
  const [destinationViewIsOpen, setDestinationViewIsOpen] = useState(true);
  const [destinationPointersViewIsOpen, setDestinationPointersViewIsOpen] = useState(false);
  const [enableDestinationPointers, setEnableDestinationPointers] = useState(false);

  //Logic Variables
  const [selectedMarkerData, setSelectedMarkerData] = useState(defaultMarkerData);
  const [selectedMarkerType, setSelectedMarkerType] = useState('Lgv');
  const [isMarkerPopupOpen, setIsMarkerPopupOpen] = useState(false);
  const [locationModal, setLocationModal] = useState(false);
  const [locationsData, setLocationsData] = useState();
  const [locationCode, setLocationCode] = useState("");
  
  //Internet Connectivity
  const [connectionStatus, setConnectionStatus] = useState(true);

  useEffect(() => {
    if(getLocalStorageItem('version')) {
      if(getLocalStorageItem('version') !== process.env.REACT_APP_VERSION) {
        localStorage.clear();
        setLocalStorageItem('version', process.env.REACT_APP_VERSION);
        reloadApp();
      } else {
        setLocalStorageItem('version', process.env.REACT_APP_VERSION);
      }
    } else {
      localStorage.clear();
      setLocalStorageItem('version', process.env.REACT_APP_VERSION);
      reloadApp();
    }

    if(localStorage.getItem('appSettings')) {
      let prevSwitchState = JSON.parse(localStorage.getItem('appSettings'));
      if(prevSwitchState.removedLgv == true) {
          setRemovedLgvViewIsOpen(true);
      } else {
        setRemovedLgvViewIsOpen(false);
      }
      if(prevSwitchState.productionLines == true) {
        setProductionLineViewIsOpen(true);
      } else {
        setProductionLineViewIsOpen(false);
      }
      if(prevSwitchState.dockDoors == true) {
        setDockDoorViewIsOpen(true);
      } else {
        setDockDoorViewIsOpen(false);
      }
      setDockDoorMode(prevSwitchState.dockDoorMode);
    } else {
      localStorage.setItem('appSettings', JSON.stringify({
        productionLines: true,
        removedLgv: true,
        dockDoors: true,
        lgvObjects: true,
        destinationLines: false,
        dockDoorMode: false
    }))
    }
  }, [])

  return (
    <AppContext.Provider value={{warehouseModel, 
      setWarehouseModel, 
      timeRangePopup, setTimeRangePopup, 
      timeMachineTimeFrames, setTimeMachineTimeFrames, 
      timeMachineModeIsEnabled, setTimeMachineModeIsEnabled, 
      timeMachineControllerIsOpen, setTimeMachineControllerIsOpen, 
      timeMachineState, setTimeMachineState,
      customizePopup, setCustomizePopup,  
      productionLineViewIsOpen, setProductionLineViewIsOpen, 
      removedLgvViewIsOpen, setRemovedLgvViewIsOpen, 
      dockDoorViewIsOpen, setDockDoorViewIsOpen, 
      lgvObjectViewIsOpen, setLgvObjectViewIsOpen, 
      warehouseModelIsOpen, setWarehouseModelIsOpen, 
      additionalDashboardModalIsOpen, setAdditionalDashboardModalIsOpen,
      timeMachineLoader, setTimeMachineLoader, 
      isTimeMachineDataAvailable, setIsTimeMachineDataAvailable,
      dockDoorMode, setDockDoorMode,
      isLegendsPopupOpen, setIsLegendsPopupOpen,
      selectedMarkerData, setSelectedMarkerData,
      selectedMarkerType, setSelectedMarkerType,
      isMarkerPopupOpen, setIsMarkerPopupOpen,
      enableProductionLine, setEnableProductionLine,
      enableDockDoor, setEnableDockDoor,
      socketData, setSocketData,
      lineProductionData, setLineProductionData,
      isHeatmapModeEnabled, setIsHeatmapModeEnabled,
      heatmapTimeFrames, setHeatmapTimeFrames,
      enableHeatmap, setEnableHeatmap,
      locationModal, setLocationModal,
      locationsData, setLocationsData,
      locationCode, setLocationCode,
      currentPageCount, setCurrentPageCount,
      convertedHeatmapData, setConvertedHeatmapData,
      isNoHeatMapDataModalOpen, setIsNoHeatMapDataModalOpen,
      heatmapLoader, setHeatmapLoader,
      heatmapRef, setHeatmapRef,
      loaderText, setLoaderText,
      isHeatmapDataAvailable, setIsHeatmapDataAvailable,
      destinationPointersViewIsOpen, setDestinationPointersViewIsOpen,
      enableDestinationPointers, setEnableDestinationPointers,
      connectionStatus, setConnectionStatus}}>
      <div className='App'>
        <BrowserRouter>
          <ProtectedRoute msalUser={msalUser} setMsalUser={setMsalUser}>
            <BackendConnectivity status={connectionStatus}/>
            <Dashboard/>
          </ProtectedRoute>
        </BrowserRouter>
      </div>
    </AppContext.Provider>
  )
}

export default App;