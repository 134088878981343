import { useIsAuthenticated, useMsal, useMsalAuthentication } from '@azure/msal-react';
import { InteractionRequiredAuthError, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from '../../authConfig';

export const MsalAuth = (setMsalUser) => {
    const { instance, accounts } = useMsal();
    try {
        if(accounts.length !== 0) {
            let user = accounts[0].username;
            if(user) {
                setMsalUser(user);
                let accessToken = getAccessTokenWithScope(instance, accounts[0]);
                accessToken.then((resp) => {
                    if(resp) {
                        localStorage.setItem("auth", JSON.stringify(resp));
                        localStorage.setItem('isAuthExpiry', JSON.stringify(false));
                    }
                })
            }
        }
    } catch(err) {
        console.log(err);
    }
}

export const getAccessTokenWithScope = async(instance, account) => {
    if(account) {
        let auth = {};
        const config = {
            scopes: msalConfig.scopes,
            account: account
        };
        try {
            await instance.acquireTokenSilent(config).then((res) => {
                if(res) {
                    auth.accessToken = res.accessToken;
                    auth.expiryTime = res.expiresOn;
                }
            })
        } catch(err) {
            if (err instanceof InteractionRequiredAuthError) {
                await instance.acquireTokenRedirect(config).then(response => {
                    auth.accessToken = response.accessToken;
                    auth.expiryTime = response.expiresOn;
                });
            }
        }
        return auth;
    }
}

export const useIsUserAuthenticated = () => {
    return useIsAuthenticated();
};

export const useLogin = () => {
    const {instance} = useMsal();
    return instance.loginRedirect({
        scopes: msalConfig.scopes,
    })
}

export const useLogout = () => {
    const {instance} = useMsal();
    return instance.logout();
}