import React, { useContext } from 'react';
import '../../assets/css/Header/header.css';

import Button from '../Reusables/Button/Button';
import Dropdown from '../Reusables/Dropdown/Dropdown';
import Arrow from '../../assets/icons/arrow.png';
import LeftArrow from '../../assets/icons/leftArrow.png';
import LeftArrowTail from '../../assets/icons/arrowLeft.png';
import { AppContext } from '../../Context/Context';
import { reloadApp } from '../../util/helper/helperFunctions';

const Header = () => {
    const {timeMachineModeIsEnabled, setTimeMachineModeIsEnabled} = useContext(AppContext);
    const {timeMachineState, setTimeMachineState} = useContext(AppContext);
    const {timeMachineTimeFrames, setTimeMachineTimeFrames} = useContext(AppContext);
    const {timeRangePopup, setTimeRangePopup} = useContext(AppContext);
    const {customizePopup, setCustomizePopup} = useContext(AppContext);
    const {warehouseModelIsOpen, setWarehouseModelIsOpen} = useContext(AppContext);

    const exitTimeMachineMode = () => {
        setTimeMachineModeIsEnabled(false);
        localStorage.setItem('isTimeMachineModeEnabled', "false");
        setTimeMachineState(false);
        setTimeMachineTimeFrames({
            fromDate: "",
            toDate: "",
            fromTime: "",
            toTime: ""
        });
        reloadApp();
    }

    const openWarehouseModal = () => {
        setWarehouseModelIsOpen(!warehouseModelIsOpen);
        document.body.classList.add('prevent-scroll');
    }
    return (
        <div className="header-container">
            <div className='title-section'>
                {timeMachineModeIsEnabled ? 
                    <div className='time-machine-header-topic'>
                        <img onClick={() => exitTimeMachineMode()} src={LeftArrowTail}/>
                        <h3>Time Machine</h3>
                    </div> : 
                    <h3>Dashboard</h3>
                }
            </div>
            <div className='time-options-section'>
                {timeMachineModeIsEnabled ? 
                    <div onClick={() => setTimeRangePopup(!timeRangePopup)} className='time-frame-displayer'>
                        <div className='from-date'>
                            {timeMachineTimeFrames.fromDate} - {timeMachineTimeFrames.fromTime}
                        </div>
                        to
                        <div className='to-date'>
                            {timeMachineTimeFrames.toDate} - {timeMachineTimeFrames.toTime}
                        </div>
                    </div> : 
                    <div className='options-section'>
                        <Button 
                            placeholder='Customize' 
                            icon={Arrow} 
                            btnClass='reverse-content customize-btn' 
                            imgClass='reverse-content-img' 
                            onClick={() => setCustomizePopup(!customizePopup)}
                        />
                        <Button 
                            placeholder='Warehouse Info' 
                            icon={LeftArrow} 
                            btnClass='reverse-content customize-btn warehouse-btn' imgClass='reverse-content-img' 
                            onClick={() => openWarehouseModal()}
                        />
                    </div>
                }
            </div>
        </div>
    )
}

export default Header;