import L from 'leaflet';
import "leaflet.markercluster"
import { ImageOverlay, MapContainer, Marker } from 'react-leaflet';
import { useEffect, useRef, React, Component, useState, useContext, useMemo } from 'react';
import { apiHeadersFormatter, expireAuthToken, getBottomTopCoordinates, getLocalStorageItem, getPlantCoordinates, getScreenSize, lgvCoordinateCorrection, setDynamicZoomLevelToMap, setLocalStorageItem, statusColorRenderer, timeFrameFormatter } from '../../util/helper/helperFunctions';
import getApi from '../../util/network/getApi';
import '../../assets/css/TimeMachine/timeMachine.css';
import { lgvHistoryStatus } from '../../util/network/apiUrl';
import { AppContext } from '../../Context/Context';
import TimeMachineController from '../Modal/TimeMachineController/TimeMachineController';
import LgvIcon from '../LgvIcon/LgvIcon';
import ReactDOMServer from 'react-dom/server';
import zoomInIcon from '../../assets/icons/add.png';
import zoomOutIcon from '../../assets/icons/minus.png';
import zoomRefreshIcon from '../../assets/icons/refresh.png';
import { useIsUserAuthenticated } from '../../util/Auth/AzureAuth';

const TimeMachine = () => {
    //Context API
    const {timeMachineTimeFrames, setTimeMachineTimeFrames} = useContext(AppContext);
    const {timeMachineControllerIsOpen, setTimeMachineControllerIsOpen} = useContext(AppContext);
    const {timeMachineState, setTimeMachineState} = useContext(AppContext);
    const {isTimeMachineDataAvailable, setIsTimeMachineDataAvailable} = useContext(AppContext);
    const [startTimeFrameIsAvailable, setStartTimeFrameIsAvailable] = useState(timeMachineTimeFrames && timeMachineState ? Object.keys(timeMachineTimeFrames).length !== 0 ? true : false : false);
    const {timeMachineLoader, setTimeMachineLoader} = useContext(AppContext);
    const [timeMachineData, setTimeMachineData] = useState([]);
    const [totalSeekPosition, setTotalSeekPosition] = useState(2);
    const {locationCode, setLocationCode} = useContext(AppContext);
    const [groupIteratingInterval, setGroupIteratingInterval] = useState(null);

    const [totalPagesCount, setTotalPagesCount] = useState(0);
    const {currentPageCount, setCurrentPageCount} = useContext(AppContext);

    let auth = JSON.parse(localStorage.getItem('auth'));
    const indoorMapRef = useRef(null);
    const [mapObject, setMapObject] = useState();
    const [isMapDraggable, setIsMapDraggable] = useState(false);
    const [convertedSocketData, setConvertedSocketData] = useState();
    const [lgvMarkers, setLgvMarkers] = useState([]);
    const [mapLoader, setMapLoader] = useState(true);
    const [timeMachineInterval, setTimeMachineInterval] = useState(null);

    const [loaderText, setLoaderText] = useState('Fetching all LGVs...');

    let isUserAuthenticated = useIsUserAuthenticated();

    const mapCoordinate = (coordinateX, coordinateY, renderObject) => {
        let locationCode = getLocalStorageItem('locationCode');
        if(locationCode) {
            const {
                rectBottomLeftX,
                rectBottomLeftY,
                rectTopRightX,
                rectTopRightY,
            } = getPlantCoordinates(locationCode, renderObject);

            const {
                rectBottomLeftXNew, 
                rectBottomLeftYNew,
                rectTopRightXNew,
                rectTopRightYNew
            } = getBottomTopCoordinates();
    
            let ratioX = (coordinateX - rectBottomLeftX) / (rectTopRightX - rectBottomLeftX);
            let ratioY = (coordinateY - rectBottomLeftY) / (rectTopRightY - rectBottomLeftY);
    
            let translatedX = rectBottomLeftXNew + ratioX * (rectTopRightXNew - rectBottomLeftXNew);
            let translatedY = rectBottomLeftYNew + ratioY * (rectTopRightYNew - rectBottomLeftYNew);
    
            return {
                x: translatedX,
                y: translatedY
            }
        }
    }

    useEffect(() => {
        setLocalStorageItem('seekPosition', 0);
        setLocalStorageItem('totalSeekPosition', 1);
        setLocalStorageItem('timeMachineDataAvailable', true);
        setLocalStorageItem('currentPageTimeMachineData', {pageSize:0, data:['startData']});
        setCurrentPageCount(1);
        setLocalStorageItem('groupDataCount', 0);
        setLocalStorageItem('groupDataCurrentCount', 0);
        setTotalPagesCount(0);
    }, [timeMachineTimeFrames])

    const groupLgvPageData = (filteredData) => {
        let groupedData = {};
        for(const item of filteredData) {
            const itemName = item.TRN_DTTM;
            if(!groupedData[itemName]) {
                groupedData[itemName] = [];
            }
            groupedData[itemName].push(item);
        }
        
        Object.entries(groupedData).forEach(([key, value]) => {
            value.sort((a,b) => a.LGV_ID - b.LGV_ID);
        })
        let filteredGroup = {};
        Object.entries(groupedData).forEach(([key, value]) => {
            if(value.length == groupedData[Object.keys(groupedData)[1]].length) {
                filteredGroup[key] = value;
            }
        })
        setLocalStorageItem('groupDataCount', Object.keys(filteredGroup).length);
        return filteredGroup;
    }

    useEffect(() => {
        if(startTimeFrameIsAvailable && timeMachineState) {
            const {fromTimePlant, toTimePlant} = timeFrameFormatter(timeMachineTimeFrames);
            let locationCode = getLocalStorageItem('locationCode');

            const iterateGroupedData = (groupedData) => {
                setLocalStorageItem('groupDataCurrentCount', 0);
                let currentGroupDataCount = getLocalStorageItem('groupDataCurrentCount');
                setGroupIteratingInterval(setInterval(() => {
                    if(currentGroupDataCount < getLocalStorageItem('groupDataCount')) {
                        if(getLocalStorageItem('groupDataCurrentCount') == getLocalStorageItem('groupDataCount')) {
                            setLocalStorageItem('groupDataCurrentCount', 0);
                            clearInterval(groupIteratingInterval);
                        }
                        setTimeMachineData(Object.values(groupedData)[currentGroupDataCount]);
    
                        currentGroupDataCount += 1;
                        setLocalStorageItem('groupDataCurrentCount', currentGroupDataCount);
                    }
                }, 1000));
                setLocalStorageItem('seekPosition', Number(currentPageCount));
                setCurrentPageCount(Number(currentPageCount) + 1);
            }

            const getTimeMachinePages = async () => {

                let timeMachinePagesResp = await getApi(`${lgvHistoryStatus}/${fromTimePlant}/${toTimePlant}/1?location=${locationCode}`, apiHeadersFormatter(auth.accessToken));

                if(timeMachinePagesResp.status === 200 && timeMachinePagesResp.data !== 'Error connecting to datasource!') {
                    if(timeMachinePagesResp.data && timeMachinePagesResp.data.totalCount > 0) {
                        setTotalPagesCount(timeMachinePagesResp.data.totalPages);
                        setLocalStorageItem('totalSeekPosition', timeMachinePagesResp.data.totalPages);
                        setLoaderText('Loading Time Machine...')
                    } else {
                        // getTimeMachinePages();
                        setTimeMachineControllerIsOpen(false);
                        setIsTimeMachineDataAvailable(false);
                        setLocalStorageItem('timeMachineDataAvailable', false);
                    }
                } else {
                    if(timeMachinePagesResp.status === 401) {
                        expireAuthToken(isUserAuthenticated);
                    }
                    setTotalPagesCount(0);
                    getTimeMachinePages();
                }
            }
            const getCurrentPageData = async (pageSize) => {
                
                let timeMachineDataResp = await getApi(`${lgvHistoryStatus}/${fromTimePlant}/${toTimePlant}/${pageSize}?location=${locationCode}`, apiHeadersFormatter(auth.accessToken));

                if(timeMachineDataResp.status === 200 && timeMachineDataResp.data !== 'Error connecting to datasource!') {
                    if(timeMachineDataResp.data.results.length !== 0) {
                        setLocalStorageItem('groupDataCurrentCount', 0);
                        iterateGroupedData(groupLgvPageData(timeMachineDataResp.data.results));
                        setTimeMachineLoader(false);
                        setIsTimeMachineDataAvailable(true);
                        setTimeMachineControllerIsOpen(true);
                    } else {
                        getCurrentPageData(pageSize);
                        setLocalStorageItem('timeMachineDataAvailable', false);
                    }
                } else {
                    if(timeMachineDataResp.status === 401) {
                        expireAuthToken(isUserAuthenticated);   
                    }
                    if(timeMachineDataResp.status !== 400) {
                        getCurrentPageData(pageSize);
                    }
                }
            }

            if(timeMachineTimeFrames && totalPagesCount == 0) {
                getTimeMachinePages();
            } else {
                if(getLocalStorageItem('groupDataCurrentCount') == getLocalStorageItem('groupDataCount') || getLocalStorageItem('groupDataCount') == 0) {
                    clearInterval(groupIteratingInterval);
                    getCurrentPageData(currentPageCount);
                }
            }
        }
    }, [timeMachineTimeFrames, currentPageCount, totalPagesCount, startTimeFrameIsAvailable, getLocalStorageItem('seekPosition'), timeMachineState, getLocalStorageItem('groupDataCurrentCount'), getLocalStorageItem('groupDataCount')])


    useEffect(() => {
        if(getLocalStorageItem('seekPosition') == getLocalStorageItem('totalSeekPosition')) {
            setTimeMachineState(false);
        }
        
    }, [getLocalStorageItem('seekPosition'), getLocalStorageItem('totalSeekPosition')])

    const filterDuplicatesAndNoCoordinates = (rawData) => {
        let noCoordinatesFiltered = rawData.filter((data) => data['X'] !== null);
        let noDuplicatesFiltered = noCoordinatesFiltered.filter((data, index, self) => {
            let firstInstance = self.findIndex((innerObject) => innerObject['LGV_ID'] === data['LGV_ID']);
            return index === firstInstance;
        });
        return noDuplicatesFiltered;
    }

    useEffect(() => {
        if(timeMachineTimeFrames && timeMachineState) {
            if(Object.keys(timeMachineTimeFrames).length !== 0) {
                setStartTimeFrameIsAvailable(true);
            }
        }
    }, [timeMachineTimeFrames, timeMachineState])

    const convertReactComponentToHtmlIcon = (reactComponent, className, iconSize, iconAnchor) => {
        const reactToHtml = ReactDOMServer.renderToString(reactComponent);
        return L.divIcon({
            className: className,
            iconSize: iconSize,
            iconAnchor: iconAnchor ? iconAnchor : null,
            html: reactToHtml
        })
    }

    const convertReactComponentToStringHtml = (reactComponent) => {
        return ReactDOMServer.renderToString(reactComponent);
    }

    useEffect(() => {
        if(timeMachineData) {
            timeMachineLgvCoordinateConverter();
        }
    }, [timeMachineData])

    const timeMachineLgvCoordinateConverter = () => {
        if(mapObject && timeMachineData) {
            let filteredData = filterDuplicatesAndNoCoordinates(timeMachineData);
            filteredData.map((marker, index) => {
                let convertedCoordinates = mapCoordinate(marker['X'], marker['Y'], 'lgv');
                let convertedPoints = L.point(convertedCoordinates.x, convertedCoordinates.y);
                let convertedLatLng = mapObject.map.target.layerPointToLatLng(convertedPoints);
                marker.currentPos = {lat: convertedLatLng.lat, lng: convertedLatLng.lng};
            })
            setConvertedSocketData(filteredData);
        }
    }

    const RenderLgv = useMemo(() => {
        if(convertedSocketData) {
            return convertedSocketData.map((marker, index) => {
                let reactIcon = convertReactComponentToHtmlIcon(
                    <LgvIcon data={marker['LGV_ID']} status={""}/>, 'blinking', [25,25], [42.5,50]
                )
                let lgvCorrection = lgvCoordinateCorrection();
                return <Marker 
                    key={index} 
                    riseOnHover 
                    position={[marker.currentPos.lat + lgvCorrection.x, marker.currentPos.lng + lgvCorrection.y]} icon={reactIcon}>
                </Marker>
            })
        }
    })

    const [convertedCenter, setConvertedCenter] = useState();

    const centerMapView = (unit1, unit2) => {
        let convertedCoordinates;
        if(unit1 && unit2) {
            convertedCoordinates = mapCoordinate(unit1, unit2);
        } else {
            convertedCoordinates = mapCoordinate(275000, 70000);
        }
        let convertedPoints = L.point(convertedCoordinates.x, convertedCoordinates.y);
        let convertedLatLng = mapObject.map.target.layerPointToLatLng(convertedPoints);
        mapObject.map.target.setView([convertedLatLng.lat, convertedLatLng.lng]);
        setConvertedCenter(convertedLatLng);
    }

    useEffect(() => {
        if(mapObject) {
            centerMapView();
        }
    }, [mapObject])

    useEffect(() => {
        if(mapObject) {
            let screenSize = getScreenSize();
            if(screenSize >= 1440 && screenSize < 1800) {
                setTimeout(() => {
                    centerMapView();
                    setDynamicZoomLevelToMap('1.0', 'timeMachine');
                }, 1000)
            } else if(screenSize >= 1800 && screenSize < 2500) {
                setTimeout(() => {
                    centerMapView(312000, 60000);
                    setDynamicZoomLevelToMap('1.2', 'timeMachine');
                }, 500)
            } else if(screenSize >= 2560 && screenSize < 3000) {
                setTimeout(() => {
                    centerMapView(350000, 60000);
                    setDynamicZoomLevelToMap('1.2', 'timeMachine');
                }, 500)
            } else if(screenSize >= 3000 && screenSize < 4000) {
                setTimeout(() => {
                    centerMapView(312000, 60000);
                    setDynamicZoomLevelToMap('1.4', 'timeMachine');
                }, 500)
            } else if(screenSize >= 4000 && screenSize < 5000) {
                setTimeout(() => {
                    centerMapView(390000, 23000);
                    setDynamicZoomLevelToMap('1.6', 'timeMachine');
                }, 500)
            } else if(screenSize >= 5000 && screenSize < 6000) {
                setTimeout(() => {
                    centerMapView(312000, 60000);
                    setDynamicZoomLevelToMap('2.0', 'timeMachine');
                }, 500)
            } else {
                centerMapView();
            }
            mapObject.map.target.doubleClickZoom.disable();
        }
    }, [mapObject, window.innerWidth])

    const RenderMap = useMemo(() => {
        if(mapObject) {
            const plantDetails = getLocalStorageItem('plantDetails');
            if(plantDetails !== undefined) {
                const {leftBottom, rightTop} = plantDetails.bounds;
                const {image} = plantDetails;
                let convertedCoordinates = mapCoordinate(leftBottom.lat, leftBottom.lng, 'map');
                let convertedPoints = L.point(convertedCoordinates.x, convertedCoordinates.y);
                let convertedLatLng = mapObject.map.target.layerPointToLatLng(convertedPoints);
        
                let convertedCoordinates1 = mapCoordinate(rightTop.lat, rightTop.lng, 'map');
                let convertedPoints1 = L.point(convertedCoordinates1.x, convertedCoordinates1.y);
                let convertedLatLng1 = mapObject.map.target.layerPointToLatLng(convertedPoints1);
        
                let overlayBounds = [[convertedLatLng.lat, convertedLatLng.lng], [convertedLatLng1.lat, convertedLatLng1.lng]]
                return <ImageOverlay url={image} bounds={overlayBounds}/>
            }
        }
    })

    const mapWhenReadyHandler = (map) => {
        setMapObject({map});
    }

    const [zoomLevel, setZoomLevel] = useState(1);
    const [isZoomInTriggeredOnce, setIsZoomInTriggeredOnce] = useState(false);
    useEffect(() => {
        document.getElementById('time-machine-map-id-reference').style.transform = 'scale(1.0)';
    }, []);
    const zoomIn = () => {
        let mapContainerZoom = document.getElementById('time-machine-map-id-reference').style.transform;
        if(mapContainerZoom == 'scale(1.0)' || mapContainerZoom == 'scale(1)') {
            document.getElementById('time-machine-map-id-reference').style.transform = 'scale(1.2)'
        }
        if(mapContainerZoom == 'scale(1.2)') {
            document.getElementById('time-machine-map-id-reference').style.transform = 'scale(1.4)'
        }
        if(mapContainerZoom == 'scale(1.4)') {
            document.getElementById('time-machine-map-id-reference').style.transform = 'scale(1.6)'
        }
        if(mapContainerZoom == 'scale(1.6)') {
            document.getElementById('time-machine-map-id-reference').style.transform = 'scale(1.8)'
        }
        if(mapContainerZoom == 'scale(1.8)') {
            document.getElementById('time-machine-map-id-reference').style.transform = 'scale(2.0)'
        }
        setIsZoomInTriggeredOnce(true)
        setIsMapDraggable(true);
        centerMapView();
    }

    const zoomOut = () => {
        let mapContainerZoom = document.getElementById('time-machine-map-id-reference').style.transform;
        if(mapContainerZoom == 'scale(2)') {
            document.getElementById('time-machine-map-id-reference').style.transform = 'scale(1.8)'
        }
        if(mapContainerZoom == 'scale(1.8)') {
            document.getElementById('time-machine-map-id-reference').style.transform = 'scale(1.6)'
        }
        if(mapContainerZoom == 'scale(1.6)') {
            document.getElementById('time-machine-map-id-reference').style.transform = 'scale(1.4)'
        }
        if(mapContainerZoom == 'scale(1.4)') {
            document.getElementById('time-machine-map-id-reference').style.transform = 'scale(1.2)'
        }
        if(mapContainerZoom == 'scale(1.2)') {
            document.getElementById('time-machine-map-id-reference').style.transform = 'scale(1.0)'
        }
        setIsMapDraggable(true);
        centerMapView();
    }

    const checkZoomInLimit = () => {
        if(document.getElementById('time-machine-map-id-reference') && document.querySelector('.time-machine-container')) {
            if(document.getElementById('time-machine-map-id-reference').style.transform == 'scale(2)') {
                document.querySelector('.time-machine-container').style.cursor = 'default';
                return true;
            } else {
                document.querySelector('.time-machine-container').style.cursor = 'grab';
                return false;
            }
        }
    }

    const checkZoomOutLimit = () => {
        if(document.getElementById('time-machine-map-id-reference') && document.querySelector('.time-machine-container')) {
            if(document.getElementById('time-machine-map-id-reference').style.transform == 'scale(1)') {
                document.querySelector('.time-machine-container').style.cursor = 'default';
                return true;
            } else {
                document.querySelector('.time-machine-container').style.cursor = 'grab';
                return false;
            }
        }
    }

    const resetZoom = () => {
        document.getElementById('time-machine-map-id-reference').style.transform = 'scale(1.0)'
    }

    useEffect(() => {
        const slider = document.querySelector('#time-machine-map-container-id');
        let mouseDown = false;
        let startX, scrollLeft, startY, scrollTop;

        let startDragging = function (e) {
            mouseDown = true;
            startX = e.pageX - slider.offsetLeft;
            startY = e.pageY - slider.offsetTop;
            scrollTop = slider.scrollTop;
            scrollLeft = slider.scrollLeft;
        };
        let stopDragging = function (event) {
            mouseDown = false;
        };

        slider.addEventListener('mousemove', (e) => {
            e.preventDefault();
            if(!mouseDown) { return; }
            const x = e.pageX - slider.offsetLeft;
            const scroll = x - startX;
            slider.scrollLeft = scrollLeft - scroll;
        });

        slider.addEventListener('mousemove', (e) => {
            e.preventDefault();
            if(!mouseDown) { return; }
            const y = e.pageY - slider.offsetTop;
            const scroll = y - startY;
            slider.scrollTop = scrollTop - scroll;
        });

        slider.addEventListener('mousedown', startDragging, false);
        slider.addEventListener('mouseup', stopDragging, false);
        slider.addEventListener('mouseleave', stopDragging, false);
    }, [])

    return (
        <div className='map-container-wrapper'>
            <div id='time-machine-map-container-id' className='time-machine-container'>
            {timeMachineLoader ? <div className='loader'>
                {isTimeMachineDataAvailable ? 
                    <>
                        <h4>{loaderText}</h4>
                        <div className='spinner'></div>
                    </>
                    :
                    <h4>No Data Available! <br/> Please select a different Time Range...</h4>
                }
            </div> : null}
            <MapContainer 
                id='time-machine-map-id-reference'
                style={{height: '100%', width:'100%', borderRadius: '10px'}}
                center={[0,0]}
                zoom={0}
                maxZoom={3}
                crs={L.CRS.Simple}
                attributionControl={false}
                scrollWheelZoom={false}
                whenReady={(map) => mapWhenReadyHandler(map)}
                dragging={isMapDraggable}
                zoomControl={false}
            >
                {RenderMap}
                {RenderLgv}
            </MapContainer>
            </div>
            <TimeMachineController/>
            <div className='zoom-control-center'>
                <button className='zoom-controls zoom-in-control' disabled={checkZoomInLimit()} onClick={() => zoomIn()}>
                    <img src={zoomInIcon}/>
                </button>
                <button className='zoom-controls zoom-out-control' disabled={checkZoomOutLimit()} onClick={() => zoomOut()}>
                    <img style={{opacity : checkZoomOutLimit() == true ? '0.5' : "1"}} src={zoomOutIcon}/>
                </button>
                <button className='zoom-controls zoom-reset-controls' disabled={checkZoomOutLimit()} onClick={() => resetZoom()}>
                    <img style={{opacity : checkZoomOutLimit() == true ? '0.5' : "1"}} src={zoomRefreshIcon}/>
                </button>
            </div>
        </div>
    )
}

export default TimeMachine;