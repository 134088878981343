import "../../assets/css/Map/map.css";

import L from "leaflet";
import "leaflet.markercluster";
import {
  ImageOverlay,
  MapContainer,
  Marker,
  Polygon,
  Popup,
} from "react-leaflet";
import { useEffect, useRef, React, useState, useContext, useMemo } from "react";
import {
  checkIsLocationCodeValid,
  checkIsTimeMachineEnabled,
  clearLocalStorageItem,
  combineKafkaSocketAndLGVDetailsSocket,
  convertReactComponentToHtmlIcon,
  destinationPointersCoordinateCorrection,
  eachDockDoorCoordinateCorrection,
  eachProductionLineCoordinateCorrection,
  filterDuplicateProductionLine,
  findDockDoorPosition,
  getBottomTopCoordinates,
  getLocalStorageItem,
  getPlantCoordinates,
  getPlantDetails,
  getScreenSize,
  isKafkaDataAvailable,
  lgvCoordinateCorrection,
  lgvNameRenderer,
  mapCoordinate,
  plantDockDoorCoordinateCorrection,
  plantProductionLineCoordinateCorrection,
  reloadApp,
  removeDockDoorLayer,
  removeExistingProductionLines,
  removeLgvs,
  setDynamicZoomLevelToMap,
  setLocalStorageItem,
  storeDockDoorCoordinates,
} from "../../util/helper/helperFunctions";

import LineProduction from "../LineProduction/LineProduction";
import { AppContext } from "../../Context/Context";
import DockDoor from "../DockDoor/DockDoor";
import ReactDOMServer from "react-dom/server";
import DockIcon from "../DockDoor/DockIcon";
import LgvIcon from "../LgvIcon/LgvIcon";
import LineProductionPopup from "../Modal/LineProductionPopup/LineProductionPopup";
import zoomInIcon from "../../assets/icons/add.png";
import zoomOutIcon from "../../assets/icons/minus.png";
import zoomRefreshIcon from "../../assets/icons/refresh.png";
import { dummyProductionLine } from "../../util/helper/constants";
import { useNavigate } from "react-router";

const IndoorMap = ({ socket, lineProductionData, dockDoorData }) => {
  //Context API List
  const { productionLineViewIsOpen, setProductionLineViewIsOpen } =
    useContext(AppContext);
  const { dockDoorViewIsOpen, setDockDoorViewIsOpen } = useContext(AppContext);
  const { socketData, setSocketData } = useContext(AppContext);
  const { isMarkerPopupOpen, setIsMarkerPopupOpen } = useContext(AppContext);
  const { selectedMarkerData, setSelectedMarkerData } = useContext(AppContext);
  const { selectedMarkerType, setSelectedMarkerType } = useContext(AppContext);
  const { enableProductionLine, setEnableProductionLine } =
    useContext(AppContext);
  const { timeMachineModeIsEnabled, setTimeMachineModeIsEnabled } =
    useContext(AppContext);
  const { locationCode, setLocationCode } = useContext(AppContext);
  const { lgvObjectViewIsOpen, setLgvObjectViewIsOpen } =
    useContext(AppContext);
  const { enableDockDoor, setEnableDockDoor } = useContext(AppContext);
  const { isNoHeatMapDataModalOpen, setIsNoHeatMapDataModalOpen } =
    useContext(AppContext);
  const { destinationPointersViewIsOpen, setDestinationPointersViewIsOpen } =
    useContext(AppContext);
  const { enableDestinationPointers, setEnableDestinationPointers } =
    useContext(AppContext);
  const { connectionStatus, setConnectionStatus } = useContext(AppContext);

  //Hooks List
  const [mapLoader, setMapLoader] = useState(true);
  const [convertedSocketData, setConvertedSocketData] = useState();
  const [convertedDoorDockData, setConvertedDockDoorData] = useState();
  const [convertedProductionLineData, setConvertedProductionLineData] =
    useState();
  const [mapObject, setMapObject] = useState();
  const [isMapDraggable, setIsMapDraggable] = useState(false);
  const [dockDoorMode, setDockDoorMode] = useState(false);
  const [groupedDockData, setGroupedDockData] = useState({});
  const [groupedArrayData, setGroupedArrayData] = useState();
  const [dummyMarkerPoint, setDummyMarkerPoint] = useState();
  const [convertedCenter, setConvertedCenter] = useState();
  const navigation = useNavigate();

  //Customize dashboard switch settings
  useEffect(() => {
    if (localStorage.getItem("appSettings")) {
      let prevSwitchState = JSON.parse(localStorage.getItem("appSettings"));
      if (prevSwitchState.productionLines == true) {
        setProductionLineViewIsOpen(true);
      }
      if (prevSwitchState.dockDoors == true) {
        setDockDoorViewIsOpen(true);
      }
    }
    setLocalStorageItem("heatmapTotalPageCount", 0);
    setLocalStorageItem("heatmapCurrentPageCount", 1);
    setLocalStorageItem("existingHeatmap", []);
    setLocalStorageItem(
      "plantDetails",
      getPlantDetails(getLocalStorageItem("locationCode"), "indoorMap")
    );
    setLocalStorageItem(
      "plantHeatMapDetails",
      getPlantDetails(getLocalStorageItem("locationCode"), "heatmap")
    );
  }, []);

  //Set location code on plant change
  useEffect(() => {
    setLocalStorageItem(
      "plantDetails",
      getPlantDetails(getLocalStorageItem("locationCode"), "indoorMap")
    );
    setLocalStorageItem(
      "plantHeatMapDetails",
      getPlantDetails(getLocalStorageItem("locationCode"), "heatmap")
    );
  }, [getLocalStorageItem("locationCode")]);

  //Format Raw LGV data to Marker data set
  const lgvJsonFormatter = (data, socket) => {
    let jsonArray = [];
    if (data && data !== "Error connecting to datasource!") {
      let filteredData = data?.filter((item) => {
        return item.longitude !== null && item.latitude != null;
      });
      if (filteredData.length !== 0) {
        filteredData.map((dataValue) => {
          let formattedJson = {
            destination: "",
            source: "",
            duration: "",
            lgvName: "",
            lpn: "",
            segment: "",
            sku: "",
            status: "",
            vehicleNumber: "",
            currentPos: {
              lat: "",
              lng: "",
            },
            path: [],
          };

          Object.entries(dataValue).forEach(([key, value]) => {
            formattedJson[key] = dataValue[key];
          });
          formattedJson.currentPos["lat"] = dataValue.latitude;
          formattedJson.currentPos["lng"] = dataValue.longitude;
          jsonArray.push(formattedJson);
        });
        return jsonArray;
      }
    }
  };

  //Convert LGV Coordinate from Ignition system to LGV One system
  const lgvCoordinateConverter = () => {
    try {
      if (mapObject) {
        socketData.map((marker) => {
          let convertedCoordinates = mapCoordinate(
            marker["longitude"],
            marker["latitude"],
            "lgv"
          );
          let convertedPoints = L.point(
            convertedCoordinates.x,
            convertedCoordinates.y
          );
          let convertedLatLng =
            mapObject.map.target.layerPointToLatLng(convertedPoints);
          marker.currentPos = {
            lat: convertedLatLng.lat,
            lng: convertedLatLng.lng,
          };

          let destinationConvertedCoordinates = mapCoordinate(
            marker["destinationLongitude"],
            marker["destinationLatitude"],
            "lgv"
          );
          let destinationConvertedPoints = L.point(
            destinationConvertedCoordinates.x,
            destinationConvertedCoordinates.y
          );
          let destinationConvertedLatLng =
            mapObject.map.target.layerPointToLatLng(destinationConvertedPoints);
          marker.destinationCurrentPos = {
            lat: destinationConvertedLatLng.lat,
            lng: destinationConvertedLatLng.lng,
          };
        });
        setConvertedSocketData(socketData);
        setMapLoader(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  //Convert Dock door Coordinate from Ignition system to LGV One system
  const dockDoorCoordinateConverter = () => {
    if (mapObject && groupedArrayData) {
      groupedArrayData.map((dockData) => {
        let convertedCoordinates = mapCoordinate(
          dockData[0]["LONGITUDE"],
          dockData[0]["LATITUDE"]
        );
        let coordinateCorrection = plantDockDoorCoordinateCorrection();
        let convertedPoints = L.point(
          convertedCoordinates.x + coordinateCorrection.x,
          convertedCoordinates.y + coordinateCorrection.y
        );
        let convertedLatLng =
          mapObject.map.target.layerPointToLatLng(convertedPoints);
        dockData.map((pallet) => {
          pallet.currentPos = {
            lat: convertedLatLng.lat,
            lng: convertedLatLng.lng,
          };
        });
      });
      // setMapLoader(false);
      setConvertedDockDoorData(groupedArrayData);
    }
  };

  //Convert Production line Coordinate from Ignition system to LGV One system
  const productionLineCoordinateConverter = () => {
    if (mapObject && lineProductionData !== "Error connecting to datasource!") {
      const correctionUnits = plantProductionLineCoordinateCorrection();
      lineProductionData.map((data, index) => {
        let convertedCoordinates = mapCoordinate(
          data["LONGITUDE"],
          data["LATITUDE"]
        );
        let convertedPoints = L.point(
          convertedCoordinates.x - correctionUnits.x,
          convertedCoordinates.y - correctionUnits.y
        );
        let convertedLatLng =
          mapObject.map.target.layerPointToLatLng(convertedPoints);
        data.currentPos = {
          lat: convertedLatLng.lat,
          lng: convertedLatLng.lng,
        };
      });
      // setMapLoader(false);
      setConvertedProductionLineData(lineProductionData);
    }
  };

  //Move Map to the center of view
  const centerMapView = (unit1, unit2) => {
    let convertedCoordinates;
    if (unit1 && unit2) {
      convertedCoordinates = mapCoordinate(unit1, unit2);
    } else {
      convertedCoordinates = mapCoordinate(275000, 70000);
    }
    let convertedPoints = L.point(
      convertedCoordinates.x,
      convertedCoordinates.y
    );
    let convertedLatLng =
      mapObject.map.target.layerPointToLatLng(convertedPoints);
    mapObject.map.target.setView([convertedLatLng.lat, convertedLatLng.lng]);
    setConvertedCenter(convertedLatLng);
  };

  //Get LGV data on Page load from socket and combine if needed with Kafka
  useEffect(() => {
    let locationCodeLocal = JSON.parse(localStorage.getItem("locationCode"));
    try {
      const initiateLgvDetailWebSocket = () => {
        if (!isKafkaDataAvailable()) {
          //---------------------LGV TABLE SOCKET START---------------------//
          socket.on(`${locationCodeLocal}_LGV_DETAILS`, (data) => {
            if (
              data.results.length !== 0 &&
              data.results !== "Error connecting to datasource!"
            ) {
              setSocketData(
                JSON.parse(JSON.stringify(lgvJsonFormatter(data.results)))
              );
              lgvCoordinateConverter();
              setConnectionStatus(true);
            } else {
              initiateLgvDetailWebSocket();
            }
          });
        } else {
          //Get LGV data from LGV DETAILS socket
          //---------------------LGV TABLE SOCKET START---------------------//
          socket.on(`${locationCodeLocal}_LGV_DETAILS`, (data) => {
            if (
              data.results.length !== 0 &&
              data.results !== "Error connecting to datasource!"
            ) {
              setSocketData(
                JSON.parse(JSON.stringify(lgvJsonFormatter(data.results)))
              );
              lgvCoordinateConverter();
              setConnectionStatus(true);
              //Get LGV data from LGV KAFKA socket
              //---------------------LGV KAFKA SOCKET START---------------------//
              socket.on(`${locationCodeLocal}_LGV_POSITION`, (kafkaData) => {
                if (
                  kafkaData.results.length !== 0 &&
                  kafkaData.results !== "Error connecting to datasource!"
                ) {
                  let filteredData = kafkaData.results.filter(
                    (lgv) =>
                      lgv.latitude !== "undefined" &&
                      lgv.longitude !== "undefined"
                  );
                  let combinedData = combineKafkaSocketAndLGVDetailsSocket(
                    filteredData,
                    data.results
                  );
                  setSocketData(lgvJsonFormatter(combinedData));
                  lgvCoordinateConverter();
                } else {
                  initiateLgvDetailWebSocket();
                }
              });
              //---------------------KAFKA SOCKET END---------------------//
            } else {
              initiateLgvDetailWebSocket();
            }
            //---------------------LGV TABLE SOCKET END---------------------//
          });
        }
      };

      if (
        socket &&
        !checkIsTimeMachineEnabled() &&
        checkIsLocationCodeValid(locationCode, locationCodeLocal)
      ) {
        initiateLgvDetailWebSocket();
      } else {
        console.log("Below conditions failed");
        console.log("socket instance", socket);
        console.log(
          "time machine status",
          checkIsTimeMachineEnabled(timeMachineModeIsEnabled)
        );
        console.log(
          "location code",
          checkIsLocationCodeValid(locationCode, locationCodeLocal)
        );
      }
    } catch (err) {
      console.log(err);
    }
  }, [
    socket,
    localStorage.getItem("locationCode"),
    localStorage.getItem("locationSelected"),
  ]);

  //Convert LGV, dock Door and Production Line coordinates if data available
  useEffect(() => {
    if (socketData) {
      lgvCoordinateConverter();
    }
    if (groupedArrayData) {
      dockDoorCoordinateConverter();
    }
    if (lineProductionData) {
      productionLineCoordinateConverter();
    }
  }, [socketData, groupedArrayData, lineProductionData]);

  //Adjust the Map to the screen
  useEffect(() => {
    if (mapObject) {
      let screenSize = getScreenSize();
      if (screenSize >= 1440 && screenSize < 1800) {
        setTimeout(() => {
          centerMapView();
          setDynamicZoomLevelToMap("1.0", "indoor");
        }, 1000);
      } else if (screenSize >= 1800 && screenSize < 2500) {
        setTimeout(() => {
          centerMapView(312000, 60000);
          setDynamicZoomLevelToMap("1.2", "indoor");
        }, 500);
      } else if (screenSize >= 2560 && screenSize < 3000) {
        setTimeout(() => {
          centerMapView(350000, 60000);
          setDynamicZoomLevelToMap("1.2", "indoor");
        }, 500);
      } else if (screenSize >= 3000 && screenSize < 4000) {
        setTimeout(() => {
          centerMapView(312000, 60000);
          setDynamicZoomLevelToMap("1.4", "indoor");
        }, 500);
      } else if (screenSize >= 4000 && screenSize < 5000) {
        setTimeout(() => {
          centerMapView(390000, 23000);
          setDynamicZoomLevelToMap("1.6", "indoor");
        }, 500);
      } else if (screenSize >= 5000 && screenSize < 6000) {
        setTimeout(() => {
          centerMapView(312000, 60000);
          setDynamicZoomLevelToMap("2.0", "indoor");
        }, 500);
      } else {
        centerMapView();
      }
      mapObject.map.target.doubleClickZoom.disable();
    }
  }, [mapObject, window.innerWidth]);

  //Remove Dock door if disabled
  useEffect(() => {
    if (dockDoorViewIsOpen == false) {
      removeDockDoorLayer();
      setEnableDockDoor(false);
    }
  }, [dockDoorViewIsOpen]);

  //Remove Production Line if disabled
  useEffect(() => {
    if (productionLineViewIsOpen == false) {
      removeExistingProductionLines();
      setEnableProductionLine(false);
    }
  }, [productionLineViewIsOpen]);

  //Remove Lgv if disabled
  useEffect(() => {
    if (lgvObjectViewIsOpen == false) {
      removeLgvs();
      setLocalStorageItem("enableLgvMarkers", false);
    }
  }, [lgvObjectViewIsOpen]);

  //Group Raw dock door pallet data to dock door groups
  useEffect(() => {
    let dockDoorPosition = findDockDoorPosition();
    if (
      dockDoorData &&
      dockDoorData !== "Error connecting to datasource!" &&
      mapObject
    ) {
      setGroupedDockData({});
      dockDoorData.forEach((data) => {
        const groupName = data["DOCK"];
        if (!groupedDockData[groupName]) {
          groupedDockData[groupName] = [];
        }
        groupedDockData[groupName].push(data);
        let groupedData = Object.values(groupedDockData);
        if (dockDoorPosition == "DOWN") {
          groupedData.forEach((data, index) => {
            let reversedGroupedData = [];
            for (let i = data.length - 1; i >= 0; i--) {
              reversedGroupedData.push(data[i]);
            }
            groupedData[index] = reversedGroupedData;
          });
        }
        setGroupedArrayData(groupedData);
        storeDockDoorCoordinates(mapObject, groupedData);
      });
    }
  }, [dockDoorData, dockDoorMode]);

  useEffect(() => {
    setDockDoorMode(JSON.parse(localStorage.getItem("dockMode")));
  }, [localStorage.getItem("dockMode")]);

  //Set Map Object instance once leaflet map is ready and initiated
  const mapWhenReadyHandler = (map) => {
    setMapObject({ map });
  };

  //LGV, Dock door and production line Modal toggler
  useEffect(() => {
    if (selectedMarkerType == "lgv") {
      let touchedLgv = getLocalStorageItem("lgvTouched");
      if (touchedLgv) {
        let currentLgv = convertedSocketData.find(
          (lgv) => lgv.vehicleNumber == touchedLgv
        );
        setSelectedMarkerData(currentLgv);
      }
    } else if (selectedMarkerType == "dock") {
      if (convertedDoorDockData.length > 25) {
        let touchedDockDoor = getLocalStorageItem("dockDoorTouched");
        if (touchedDockDoor) {
          let currentDockData = convertedDoorDockData.find(
            (data) => data[0].DOCK == touchedDockDoor
          );
          setSelectedMarkerData(currentDockData);
        }
      }
    } else if (selectedMarkerType == "productionLine") {
      let touchedProductionLine = getLocalStorageItem("productionLineTouched");
      if (touchedProductionLine) {
        let currentProductionLine = convertedProductionLineData.find(
          (production) => production.LINE_NAME == touchedProductionLine
        );
        setSelectedMarkerData(currentProductionLine);
      }
    }
  }, [convertedSocketData, convertedDoorDockData, convertedProductionLineData]);

  //Render LGV Markers on the map
  const RenderLgv = useMemo(() => {
    if (convertedSocketData && getLocalStorageItem("enableLgvMarkers")) {
      return convertedSocketData.map((marker, index) => {
        let markerData = marker;
        lgvNameRenderer(markerData.vehicleNumber);
        let reactIcon = convertReactComponentToHtmlIcon(
          <LgvIcon
            data={lgvNameRenderer(markerData.vehicleNumber)}
            status={markerData.status}
          />,
          "lgv-marker",
          [15, 15],
          [40.5, 48]
        );
        let lgvCorrection = lgvCoordinateCorrection();
        return (
          <Marker
            type="lgv"
            data={markerData}
            eventHandlers={{
              click: (e) => {
                setSelectedMarkerData(markerData);
                setSelectedMarkerType("lgv");
                setIsMarkerPopupOpen(true);
              },
            }}
            key={index}
            riseOnHover
            position={[
              markerData.currentPos.lat + lgvCorrection.x,
              markerData.currentPos.lng + lgvCorrection.y,
            ]}
            icon={reactIcon}
          ></Marker>
        );
      });
    }
  });

  //Render Dock door Markers on the map
  const RenderDockDoor = useMemo(() => {
    if (convertedDoorDockData && enableDockDoor) {
      return convertedDoorDockData.map((dockMarker, index) => {
        let markerData = dockMarker;
        let reactIcon = convertReactComponentToHtmlIcon(
          <DockIcon
            dockMode={dockDoorMode}
            dockData={markerData}
            index={index}
          />,
          "dock-door",
          [15, 66],
          [0, 55]
        );
        let coordinateCorrection = eachDockDoorCoordinateCorrection(index);
        return (
          <Marker
            type="dock"
            data={markerData}
            eventHandlers={{
              click: (e) => {
                setSelectedMarkerData(markerData);
                setSelectedMarkerType("dock");
                setIsMarkerPopupOpen(true);
              },
            }}
            key={index}
            riseOnHover={true}
            position={
              coordinateCorrection !== undefined
                ? [
                    markerData[0].currentPos.lat + coordinateCorrection[0],
                    markerData[0].currentPos.lng + coordinateCorrection[1],
                  ]
                : [markerData[0].currentPos.lat, markerData[0].currentPos.lng]
            }
            icon={reactIcon}
          ></Marker>
        );
      });
    }
  });

  //Render Production line Markers on the map
  const RenderProductionLine = useMemo(() => {
    if (convertedProductionLineData && enableProductionLine) {
      let filteredData = filterDuplicateProductionLine(
        convertedProductionLineData
      );
      return filteredData.map((productionLine, index) => {
        let coordinateCorrectionUnitsX = eachProductionLineCoordinateCorrection(
          index
        )
          ? eachProductionLineCoordinateCorrection(index)[0]
          : 0;
        let coordinateCorrectionUnitsY = eachProductionLineCoordinateCorrection(
          index
        )
          ? eachProductionLineCoordinateCorrection(index)[1]
          : 0;
        let productionData = productionLine;
        let reactIcon = convertReactComponentToHtmlIcon(
          <LineProductionPopup lineData={productionData} index={index} />
        );
        return (
          <Marker
            type="productionLine"
            data={productionData}
            eventHandlers={{
              click: (e) => {
                setSelectedMarkerData(productionData);
                setSelectedMarkerType("productionLine");
                setIsMarkerPopupOpen(true);
              },
            }}
            key={index}
            riseOnHover
            position={[
              productionData.currentPos.lat + coordinateCorrectionUnitsX,
              productionData.currentPos.lng + coordinateCorrectionUnitsY,
            ]}
            icon={reactIcon}
          ></Marker>
        );
      });
    }
  });

  //Render Map Object Instance
  const RenderMap = useMemo(() => {
    if (mapObject) {
      const plantDetails = getLocalStorageItem("plantDetails");
      if (plantDetails !== undefined) {
        const { leftBottom, rightTop } = plantDetails.bounds;
        const { image } = plantDetails;
        let convertedCoordinates = mapCoordinate(
          leftBottom.lat,
          leftBottom.lng,
          "map"
        );
        let convertedPoints = L.point(
          convertedCoordinates.x,
          convertedCoordinates.y
        );
        let convertedLatLng =
          mapObject.map.target.layerPointToLatLng(convertedPoints);

        let convertedCoordinates1 = mapCoordinate(
          rightTop.lat,
          rightTop.lng,
          "map"
        );
        let convertedPoints1 = L.point(
          convertedCoordinates1.x,
          convertedCoordinates1.y
        );
        let convertedLatLng1 =
          mapObject.map.target.layerPointToLatLng(convertedPoints1);

        let overlayBounds = [
          [convertedLatLng.lat, convertedLatLng.lng],
          [convertedLatLng1.lat, convertedLatLng1.lng],
        ];
        return <ImageOverlay url={image} bounds={overlayBounds} />;
      } else {
        clearLocalStorageItem("plantDetails");
        reloadApp();
      }
    }
  });

  const getConvertedDockCoordinates = (dockName) => {
    let dockDetails = getLocalStorageItem("dockCoordinates");
    let dockItem = dockDetails.find(
      (dockItem, index) => dockItem.DOCK == dockName
    );
    return dockItem;
  };

  //Render Destination Pointers Markers on the map
  const RenderDestinationPointers = useMemo(() => {
    if (enableDestinationPointers && convertedSocketData) {
      let filteredData = convertedSocketData.filter(
        (lgv) => lgv.destination !== null
      );
      return filteredData.map((lgv, index) => {
        let sourceAndDestination;
        if (String(lgv.destination).includes("STAGE")) {
          if (
            lgv.destination.split(".").length == 2 &&
            (lgv.destination.split(".")[1].length == 2 ||
              lgv.destination.split(".")[1].length == 3)
          ) {
            let correctionUnits =
              destinationPointersCoordinateCorrection("lgv");
            sourceAndDestination = [
              [
                lgv.currentPos.lat + correctionUnits.source.x,
                lgv.currentPos.lng + correctionUnits.source.y,
              ],
              [
                lgv.destinationCurrentPos.lat + correctionUnits.destination.x,
                lgv.destinationCurrentPos.lng + correctionUnits.destination.y,
              ],
            ];
          } else if (
            lgv.destination.split(".").length == 2 &&
            lgv.destination.split(".")[1].length > 3
          ) {
            let correctionUnits =
              destinationPointersCoordinateCorrection("others");
            sourceAndDestination = [
              [
                lgv.currentPos.lat + correctionUnits.source.x,
                lgv.currentPos.lng + correctionUnits.source.y,
              ],
              [
                lgv.destinationCurrentPos.lat + correctionUnits.destination.x,
                lgv.destinationCurrentPos.lng + correctionUnits.destination.y,
              ],
            ];
          }
        } else if (String(lgv.destination).includes("NSR")) {
          let correctionUnits = destinationPointersCoordinateCorrection("nsrd");
          sourceAndDestination = [
            [
              lgv.currentPos.lat + correctionUnits.source.x,
              lgv.currentPos.lng + correctionUnits.source.y,
            ],
            [
              lgv.destinationCurrentPos.lat + correctionUnits.destination.x,
              lgv.destinationCurrentPos.lng + correctionUnits.destination.y,
            ],
          ];
        } else if (
          String(lgv.destination).includes("BATT") &&
          String(lgv.destination).split(".").length == 3
        ) {
          let correctionUnits = destinationPointersCoordinateCorrection("batt");
          sourceAndDestination = [
            [
              lgv.currentPos.lat + correctionUnits.source.x,
              lgv.currentPos.lng + correctionUnits.source.y,
            ],
            [
              lgv.destinationCurrentPos.lat + correctionUnits.destination.x,
              lgv.destinationCurrentPos.lng + correctionUnits.destination.y,
            ],
          ];
        } else if (String(lgv.destination).includes("I.E")) {
          let correctionUnits = destinationPointersCoordinateCorrection("ie");
          sourceAndDestination = [
            [
              lgv.currentPos.lat + correctionUnits.source.x,
              lgv.currentPos.lng + correctionUnits.source.y,
            ],
            [
              lgv.destinationCurrentPos.lat + correctionUnits.destination.x,
              lgv.destinationCurrentPos.lng + correctionUnits.destination.y,
            ],
          ];
        } else if (String(lgv.destination).includes("A.W")) {
          let correctionUnits = destinationPointersCoordinateCorrection("awd");
          sourceAndDestination = [
            [
              lgv.currentPos.lat + correctionUnits.source.x,
              lgv.currentPos.lng + correctionUnits.source.y,
            ],
            [
              lgv.destinationCurrentPos.lat + correctionUnits.destination.x,
              lgv.destinationCurrentPos.lng + correctionUnits.destination.y,
            ],
          ];
        } else if (String(lgv.destination).includes("A.E")) {
          let correctionUnits = destinationPointersCoordinateCorrection("ae");
          sourceAndDestination = [
            [
              lgv.currentPos.lat + correctionUnits.source.x,
              lgv.currentPos.lng + correctionUnits.source.y,
            ],
            [
              lgv.destinationCurrentPos.lat + correctionUnits.destination.x,
              lgv.destinationCurrentPos.lng + correctionUnits.destination.y,
            ],
          ];
        } else if (String(lgv.destination).includes("H.W")) {
          let correctionUnits = destinationPointersCoordinateCorrection("hw");
          sourceAndDestination = [
            [
              lgv.currentPos.lat + correctionUnits.source.x,
              lgv.currentPos.lng + correctionUnits.source.y,
            ],
            [
              lgv.destinationCurrentPos.lat + correctionUnits.destination.x,
              lgv.destinationCurrentPos.lng + correctionUnits.destination.y,
            ],
          ];
        } else if (String(lgv.destination).includes("B.W")) {
          let correctionUnits = destinationPointersCoordinateCorrection("bw");
          sourceAndDestination = [
            [
              lgv.currentPos.lat + correctionUnits.source.x,
              lgv.currentPos.lng + correctionUnits.source.y,
            ],
            [
              lgv.destinationCurrentPos.lat + correctionUnits.destination.x,
              lgv.destinationCurrentPos.lng + correctionUnits.destination.y,
            ],
          ];
        } else if (String(lgv.destination).includes("SR.W")) {
          let correctionUnits = destinationPointersCoordinateCorrection("sr");
          sourceAndDestination = [
            [
              lgv.currentPos.lat + correctionUnits.source.x,
              lgv.currentPos.lng + correctionUnits.source.y,
            ],
            [
              lgv.destinationCurrentPos.lat + correctionUnits.destination.x,
              lgv.destinationCurrentPos.lng + correctionUnits.destination.y,
            ],
          ];
        } else if (String(lgv.destination).includes("SR.E")) {
          let correctionUnits = destinationPointersCoordinateCorrection("sre");
          sourceAndDestination = [
            [
              lgv.currentPos.lat + correctionUnits.source.x,
              lgv.currentPos.lng + correctionUnits.source.y,
            ],
            [
              lgv.destinationCurrentPos.lat + correctionUnits.destination.x,
              lgv.destinationCurrentPos.lng + correctionUnits.destination.y,
            ],
          ];
        } else if (String(lgv.destination).includes("G.W")) {
          let correctionUnits = destinationPointersCoordinateCorrection("gw");
          sourceAndDestination = [
            [
              lgv.currentPos.lat + correctionUnits.source.x,
              lgv.currentPos.lng + correctionUnits.source.y,
            ],
            [
              lgv.destinationCurrentPos.lat + correctionUnits.destination.x,
              lgv.destinationCurrentPos.lng + correctionUnits.destination.y,
            ],
          ];
        } else if (String(lgv.destination).includes("I.W")) {
          let correctionUnits = destinationPointersCoordinateCorrection("iw");
          sourceAndDestination = [
            [
              lgv.currentPos.lat + correctionUnits.source.x,
              lgv.currentPos.lng + correctionUnits.source.y,
            ],
            [
              lgv.destinationCurrentPos.lat + correctionUnits.destination.x,
              lgv.destinationCurrentPos.lng + correctionUnits.destination.y,
            ],
          ];
        } else if (String(lgv.destination).includes("SSR")) {
          let correctionUnits = destinationPointersCoordinateCorrection("ssr");
          sourceAndDestination = [
            [
              lgv.currentPos.lat + correctionUnits.source.x,
              lgv.currentPos.lng + correctionUnits.source.y,
            ],
            [
              lgv.destinationCurrentPos.lat + correctionUnits.destination.x,
              lgv.destinationCurrentPos.lng + correctionUnits.destination.y,
            ],
          ];
        } else if (String(lgv.destination).includes("C.E")) {
          let correctionUnits = destinationPointersCoordinateCorrection("ce");
          sourceAndDestination = [
            [
              lgv.currentPos.lat + correctionUnits.source.x,
              lgv.currentPos.lng + correctionUnits.source.y,
            ],
            [
              lgv.destinationCurrentPos.lat + correctionUnits.destination.x,
              lgv.destinationCurrentPos.lng + correctionUnits.destination.y,
            ],
          ];
        } else if (String(lgv.destination).includes("D.E")) {
          let correctionUnits = destinationPointersCoordinateCorrection("ded");
          sourceAndDestination = [
            [
              lgv.currentPos.lat + correctionUnits.source.x,
              lgv.currentPos.lng + correctionUnits.source.y,
            ],
            [
              lgv.destinationCurrentPos.lat + correctionUnits.destination.x,
              lgv.destinationCurrentPos.lng + correctionUnits.destination.y,
            ],
          ];
        } else if (String(lgv.destination).includes("H.E")) {
          let correctionUnits = destinationPointersCoordinateCorrection("hed");
          sourceAndDestination = [
            [
              lgv.currentPos.lat + correctionUnits.source.x,
              lgv.currentPos.lng + correctionUnits.source.y,
            ],
            [
              lgv.destinationCurrentPos.lat + correctionUnits.destination.x,
              lgv.destinationCurrentPos.lng + correctionUnits.destination.y,
            ],
          ];
        } else if (String(lgv.destination).includes("SOR")) {
          let correctionUnits = destinationPointersCoordinateCorrection("sor");
          sourceAndDestination = [
            [
              lgv.currentPos.lat + correctionUnits.source.x,
              lgv.currentPos.lng + correctionUnits.source.y,
            ],
            [
              lgv.destinationCurrentPos.lat + correctionUnits.destination.x,
              lgv.destinationCurrentPos.lng + correctionUnits.destination.y,
            ],
          ];
        } else if (String(lgv.destination).includes("RWRK.DRP")) {
          let correctionUnits = destinationPointersCoordinateCorrection("rwrk");
          sourceAndDestination = [
            [
              lgv.currentPos.lat + correctionUnits.source.x,
              lgv.currentPos.lng + correctionUnits.source.y,
            ],
            [
              lgv.destinationCurrentPos.lat + correctionUnits.destination.x,
              lgv.destinationCurrentPos.lng + correctionUnits.destination.y,
            ],
          ];
        } else if (String(lgv.destination).includes("C.W.GLD")) {
          let correctionUnits =
            destinationPointersCoordinateCorrection("cwgld");
          sourceAndDestination = [
            [
              lgv.currentPos.lat + correctionUnits.source.x,
              lgv.currentPos.lng + correctionUnits.source.y,
            ],
            [
              lgv.destinationCurrentPos.lat + correctionUnits.destination.x,
              lgv.destinationCurrentPos.lng + correctionUnits.destination.y,
            ],
          ];
        } else if (String(lgv.destination).includes("D.GLD")) {
          let correctionUnits = destinationPointersCoordinateCorrection("dgld");
          sourceAndDestination = [
            [
              lgv.currentPos.lat + correctionUnits.source.x,
              lgv.currentPos.lng + correctionUnits.source.y,
            ],
            [
              lgv.destinationCurrentPos.lat + correctionUnits.destination.x,
              lgv.destinationCurrentPos.lng + correctionUnits.destination.y,
            ],
          ];
        } else if (String(lgv.destination).includes("GLD.HSKY")) {
          let correctionUnits =
            destinationPointersCoordinateCorrection("gldhsky");
          sourceAndDestination = [
            [
              lgv.currentPos.lat + correctionUnits.source.x,
              lgv.currentPos.lng + correctionUnits.source.y,
            ],
            [
              lgv.destinationCurrentPos.lat + correctionUnits.destination.x,
              lgv.destinationCurrentPos.lng + correctionUnits.destination.y,
            ],
          ];
        } else if (String(lgv.destination).includes("SR.B.E")) {
          let correctionUnits = destinationPointersCoordinateCorrection("srbe");
          sourceAndDestination = [
            [
              lgv.currentPos.lat + correctionUnits.source.x,
              lgv.currentPos.lng + correctionUnits.source.y,
            ],
            [
              lgv.destinationCurrentPos.lat + correctionUnits.destination.x,
              lgv.destinationCurrentPos.lng + correctionUnits.destination.y,
            ],
          ];
        } else if (String(lgv.destination).includes("SR.W")) {
          let correctionUnits = destinationPointersCoordinateCorrection("srw");
          sourceAndDestination = [
            [
              lgv.currentPos.lat + correctionUnits.source.x,
              lgv.currentPos.lng + correctionUnits.source.y,
            ],
            [
              lgv.destinationCurrentPos.lat + correctionUnits.destination.x,
              lgv.destinationCurrentPos.lng + correctionUnits.destination.y,
            ],
          ];
        } else if (String(lgv.destination).includes("J.W")) {
          let correctionUnits = destinationPointersCoordinateCorrection("jwd");
          sourceAndDestination = [
            [
              lgv.currentPos.lat + correctionUnits.source.x,
              lgv.currentPos.lng + correctionUnits.source.y,
            ],
            [
              lgv.destinationCurrentPos.lat + correctionUnits.destination.x,
              lgv.destinationCurrentPos.lng + correctionUnits.destination.y,
            ],
          ];
        } else if (String(lgv.destination).includes("F.W")) {
          let correctionUnits = destinationPointersCoordinateCorrection("fw");
          sourceAndDestination = [
            [
              lgv.currentPos.lat + correctionUnits.source.x,
              lgv.currentPos.lng + correctionUnits.source.y,
            ],
            [
              lgv.destinationCurrentPos.lat + correctionUnits.destination.x,
              lgv.destinationCurrentPos.lng + correctionUnits.destination.y,
            ],
          ];
        } else if (String(lgv.destination).includes("E.W")) {
          let correctionUnits = destinationPointersCoordinateCorrection("ewd");
          sourceAndDestination = [
            [
              lgv.currentPos.lat + correctionUnits.source.x,
              lgv.currentPos.lng + correctionUnits.source.y,
            ],
            [
              lgv.destinationCurrentPos.lat + correctionUnits.destination.x,
              lgv.destinationCurrentPos.lng + correctionUnits.destination.y,
            ],
          ];
        } else if (String(lgv.destination).includes("E.E")) {
          let correctionUnits = destinationPointersCoordinateCorrection("eed");
          sourceAndDestination = [
            [
              lgv.currentPos.lat + correctionUnits.source.x,
              lgv.currentPos.lng + correctionUnits.source.y,
            ],
            [
              lgv.destinationCurrentPos.lat + correctionUnits.destination.x,
              lgv.destinationCurrentPos.lng + correctionUnits.destination.y,
            ],
          ];
        } else if (String(lgv.destination).includes("RDR.K")) {
          let correctionUnits = destinationPointersCoordinateCorrection("rdrk");
          sourceAndDestination = [
            [
              lgv.currentPos.lat + correctionUnits.source.x,
              lgv.currentPos.lng + correctionUnits.source.y,
            ],
            [
              lgv.destinationCurrentPos.lat + correctionUnits.destination.x,
              lgv.destinationCurrentPos.lng + correctionUnits.destination.y,
            ],
          ];
        } else if (String(lgv.destination).includes("J.E")) {
          let correctionUnits = destinationPointersCoordinateCorrection("jed");
          sourceAndDestination = [
            [
              lgv.currentPos.lat + correctionUnits.source.x,
              lgv.currentPos.lng + correctionUnits.source.y,
            ],
            [
              lgv.destinationCurrentPos.lat + correctionUnits.destination.x,
              lgv.destinationCurrentPos.lng + correctionUnits.destination.y,
            ],
          ];
        } else if (String(lgv.destination).includes("RSR.P.N")) {
          let correctionUnits = destinationPointersCoordinateCorrection("rsp");
          sourceAndDestination = [
            [
              lgv.currentPos.lat + correctionUnits.source.x,
              lgv.currentPos.lng + correctionUnits.source.y,
            ],
            [
              lgv.destinationCurrentPos.lat + correctionUnits.destination.x,
              lgv.destinationCurrentPos.lng + correctionUnits.destination.y,
            ],
          ];
        } else if (String(lgv.destination).includes("D.W")) {
          let correctionUnits = destinationPointersCoordinateCorrection("dw");
          sourceAndDestination = [
            [
              lgv.currentPos.lat + correctionUnits.source.x,
              lgv.currentPos.lng + correctionUnits.source.y,
            ],
            [
              lgv.destinationCurrentPos.lat + correctionUnits.destination.x,
              lgv.destinationCurrentPos.lng + correctionUnits.destination.y,
            ],
          ];
        } else if (String(lgv.destination).includes("G.E")) {
          let correctionUnits = destinationPointersCoordinateCorrection("ge");
          sourceAndDestination = [
            [
              lgv.currentPos.lat + correctionUnits.source.x,
              lgv.currentPos.lng + correctionUnits.source.y,
            ],
            [
              lgv.destinationCurrentPos.lat + correctionUnits.destination.x,
              lgv.destinationCurrentPos.lng + correctionUnits.destination.y,
            ],
          ];
        } else if (String(lgv.destination).includes("C.W")) {
          let correctionUnits = destinationPointersCoordinateCorrection("cw");
          sourceAndDestination = [
            [
              lgv.currentPos.lat + correctionUnits.source.x,
              lgv.currentPos.lng + correctionUnits.source.y,
            ],
            [
              lgv.destinationCurrentPos.lat + correctionUnits.destination.x,
              lgv.destinationCurrentPos.lng + correctionUnits.destination.y,
            ],
          ];
        } else if (String(lgv.destination).includes("B.E")) {
          let correctionUnits = destinationPointersCoordinateCorrection("bed");
          sourceAndDestination = [
            [
              lgv.currentPos.lat + correctionUnits.source.x,
              lgv.currentPos.lng + correctionUnits.source.y,
            ],
            [
              lgv.destinationCurrentPos.lat + correctionUnits.destination.x,
              lgv.destinationCurrentPos.lng + correctionUnits.destination.y,
            ],
          ];
        } else if (String(lgv.destination).includes("F.E")) {
          let correctionUnits = destinationPointersCoordinateCorrection("fe");
          sourceAndDestination = [
            [
              lgv.currentPos.lat + correctionUnits.source.x,
              lgv.currentPos.lng + correctionUnits.source.y,
            ],
            [
              lgv.destinationCurrentPos.lat + correctionUnits.destination.x,
              lgv.destinationCurrentPos.lng + correctionUnits.destination.y,
            ],
          ];
        } else if (String(lgv.destination).includes("L1")) {
          let correctionUnits = destinationPointersCoordinateCorrection("l1");
          sourceAndDestination = [
            [
              lgv.currentPos.lat + correctionUnits.source.x,
              lgv.currentPos.lng + correctionUnits.source.y,
            ],
            [
              lgv.destinationCurrentPos.lat + correctionUnits.destination.x,
              lgv.destinationCurrentPos.lng + correctionUnits.destination.y,
            ],
          ];
        } else if (String(lgv.destination).includes("L3")) {
          let correctionUnits = destinationPointersCoordinateCorrection("l3");
          sourceAndDestination = [
            [
              lgv.currentPos.lat + correctionUnits.source.x,
              lgv.currentPos.lng + correctionUnits.source.y,
            ],
            [
              lgv.destinationCurrentPos.lat + correctionUnits.destination.x,
              lgv.destinationCurrentPos.lng + correctionUnits.destination.y,
            ],
          ];
        } else {
          let correctionUnits =
            destinationPointersCoordinateCorrection("others");
          sourceAndDestination = [
            [
              lgv.currentPos.lat + correctionUnits.source.x,
              lgv.currentPos.lng + correctionUnits.source.y,
            ],
            [
              lgv.destinationCurrentPos.lat + correctionUnits.destination.x,
              lgv.destinationCurrentPos.lng + correctionUnits.destination.y,
            ],
          ];
        }
        if (sourceAndDestination == undefined) {
          console.log(
            "Something wrong with this LGV's location and Destination"
          );
          console.log(lgv);
        }
        if (sourceAndDestination !== undefined) {
          return (
            <div key={index} className="destination-pointers">
              <Polygon
                pathOptions={{ color: "black" }}
                positions={sourceAndDestination}
                weight={1}
              />
            </div>
          );
        }
      });
    }
  });

  const RenderDummyLgv = useMemo(() => {
    const lgvObjects = [
      {
        name: "lgv1",
        source: [-321.66260162601634, -1040.5968992248063],
        destination: [-190, -700],
        original: true,
      },
      // {name: 'lgv2', source:[18000,179000], destination:[-190,-400]}, //old
      // {name: 'lgv3', source:[18000,177000], destination:[-190,-400]}, //old
      // {name: 'lgv3', source:[21149,266145], destination:[-190,-400]}, //old
      // {name: 'lgv3', source:[200,300], destination:[-190,-100]},
      // {name: 'lgv4', source:[200,400], destination:[-190,200]},
      // {name: 'lgv5', source:[200,500], destination:[-190,400]},
      // {name: 'lgv6', source:[200,600], destination:[-190,600]},
    ];
    return lgvObjects.map((data, index) => {
      if (!data.original) {
        let coords = mapCoordinate(data.source[0], data.source[1], "lgv");
        return (
          <Marker key={index} position={[coords.x + 100, coords.y + 350]}>
            <Popup>{`lat:${data.source[0]}, lng: ${data.source[1]}`}</Popup>
          </Marker>
        );
      } else {
        return (
          <Marker
            key={index}
            position={[data.source[0] + 100, data.source[1] + 350 - 20]}
          >
            <Popup>{`lat:${data.source[0]}, lng: ${data.source[1]}`}</Popup>
          </Marker>
        );
      }
    });
  });

  useEffect(() => {
    if (mapObject) {
      let convertedCoordinates = mapCoordinate(38000, 182000);
      let convertedPoints = L.point(
        convertedCoordinates.x,
        convertedCoordinates.y
      );
      let convertedLatLng =
        mapObject.map.target.layerPointToLatLng(convertedPoints);
      setDummyMarkerPoint(convertedLatLng);
    }
  }, [mapObject]);

  //Map Custom Zoom in and Zoom out
  const [zoomLevel, setZoomLevel] = useState(1);
  const [isZoomInTriggeredOnce, setIsZoomInTriggeredOnce] = useState(false);
  useEffect(() => {
    document.getElementById("indoor-map-id-reference").style.transform =
      "scale(1.0)";
  }, []);
  const zoomIn = () => {
    let mapContainerZoom = document.getElementById("indoor-map-id-reference")
      .style.transform;
    if (mapContainerZoom == "scale(1.0)" || mapContainerZoom == "scale(1)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(1.2) translate(120px, 50px)";
    }
    if (mapContainerZoom == "scale(1.2) translate(120px, 50px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(1.4) translate(210px, 90px)";
    }
    if (mapContainerZoom == "scale(1.4) translate(210px, 90px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(1.6) translate(275px, 120px)";
    }
    if (mapContainerZoom == "scale(1.6) translate(275px, 120px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(1.8) translate(325px, 135px)";
    }
    if (mapContainerZoom == "scale(1.8) translate(325px, 135px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(2.0) translate(360px, 155px)";
    }
    setIsMapDraggable(true);
    centerMapView();
  };

  const zoomOut = () => {
    let mapContainerZoom = document.getElementById("indoor-map-id-reference")
      .style.transform;
    if (mapContainerZoom == "scale(2) translate(360px, 155px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(1.8) translate(325px, 135px)";
    }
    if (mapContainerZoom == "scale(1.8) translate(325px, 135px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(1.6) translate(275px, 120px)";
    }
    if (mapContainerZoom == "scale(1.6) translate(275px, 120px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(1.4) translate(210px, 90px)";
    }
    if (mapContainerZoom == "scale(1.4) translate(210px, 90px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(1.2) translate(120px, 50px)";
    }
    if (mapContainerZoom == "scale(1.2) translate(120px, 50px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(1.0)";
    }
    setIsMapDraggable(true);
    centerMapView();
  };

  const resetZoom = () => {
    document.getElementById("indoor-map-id-reference").style.transform =
      "scale(1.0)";
  };

  const checkZoomInLimit = () => {
    if (
      document.getElementById("indoor-map-id-reference") &&
      document.querySelector(".map-container")
    ) {
      if (
        document.getElementById("indoor-map-id-reference").style.transform ==
        "scale(2)"
      ) {
        document.querySelector(".map-container").style.cursor = "default";
        return true;
      } else {
        document.querySelector(".map-container").style.cursor = "grab";
        return false;
      }
    }
  };

  const checkZoomOutLimit = () => {
    if (
      document.getElementById("indoor-map-id-reference") &&
      document.querySelector(".map-container")
    ) {
      if (
        document.getElementById("indoor-map-id-reference").style.transform ==
        "scale(1)"
      ) {
        document.querySelector(".map-container").style.cursor = "default";
        return true;
      } else {
        document.querySelector(".map-container").style.cursor = "grab";
        return false;
      }
    }
  };

  useEffect(() => {
    const slider = document.querySelector("#map-container-id");
    let mouseDown = false;
    let startX, scrollLeft, startY, scrollTop;

    let startDragging = function (e) {
      mouseDown = true;
      startX = e.pageX - slider.offsetLeft;
      startY = e.pageY - slider.offsetTop;
      scrollTop = slider.scrollTop;
      scrollLeft = slider.scrollLeft;
    };
    let stopDragging = function (event) {
      mouseDown = false;
    };

    slider.addEventListener("mousemove", (e) => {
      e.preventDefault();
      if (!mouseDown) {
        return;
      }
      const x = e.pageX - slider.offsetLeft;
      const scroll = x - startX;
      slider.scrollLeft = scrollLeft - scroll;
    });

    slider.addEventListener("mousemove", (e) => {
      e.preventDefault();
      if (!mouseDown) {
        return;
      }
      const y = e.pageY - slider.offsetTop;
      const scroll = y - startY;
      slider.scrollTop = scrollTop - scroll;
    });

    slider.addEventListener("mousedown", startDragging, false);
    slider.addEventListener("mouseup", stopDragging, false);
    slider.addEventListener("mouseleave", stopDragging, false);
  }, []);

  return (
    <div className="map-container-wrapper">
      <div id="map-container-id" className="map-container">
        {mapLoader ? (
          <div className="loader">
            <h4>Loading Warehouse Map...</h4>
            <div className="spinner"></div>
          </div>
        ) : null}
        <MapContainer
          id="indoor-map-id-reference"
          style={{ height: "100%", width: "100%", borderRadius: "10px" }}
          center={[0, 0]}
          zoom={0}
          maxZoom={3}
          crs={L.CRS.Simple}
          attributionControl={false}
          scrollWheelZoom={false}
          whenReady={(map) => mapWhenReadyHandler(map)}
          dragging={isMapDraggable}
          zoomControl={false}
          disableDoubleClickZoom={false}
        >
          {RenderLgv}
          {RenderMap}
          {/* {RenderDummyLgv} */}
          {RenderDestinationPointers}
          {RenderDockDoor}
          {RenderProductionLine}
        </MapContainer>
      </div>
      {dockDoorViewIsOpen ? <DockDoor /> : null}
      <div className="zoom-control-center">
        <button
          id="map-zoom-in-btn"
          className="zoom-controls zoom-in-control"
          disabled={checkZoomInLimit()}
          onClick={() => zoomIn()}
        >
          <img src={zoomInIcon} />
        </button>
        <button
          id="map-zoom-out-btn"
          className="zoom-controls zoom-out-control"
          disabled={checkZoomOutLimit()}
          onClick={() => zoomOut()}
        >
          <img
            style={{ opacity: checkZoomOutLimit() == true ? "0.5" : "1" }}
            src={zoomOutIcon}
          />
        </button>
        <button
          className="zoom-controls zoom-reset-controls"
          disabled={checkZoomOutLimit()}
          onClick={() => resetZoom()}
        >
          <img
            style={{ opacity: checkZoomOutLimit() == true ? "0.5" : "1" }}
            src={zoomRefreshIcon}
          />
        </button>
      </div>
    </div>
  );
};

export default IndoorMap;
