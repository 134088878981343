import socketIO from 'socket.io-client';
import { getSocketEndPoint } from '../util/network/apiUrl';

export const initiateSocketConnection = (location) => {
    try {
        const socketInstance = socketIO.connect(getSocketEndPoint(),
        {
            path: '/socket.io',
            allowUpgrades: false,
            forceNew:true,
            transports: ['polling', 'websocket'],
            query: { location: location }
        });
        return socketInstance;
    } catch(err) {
        console.log(err);
    }
}