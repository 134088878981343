import axios from 'axios';

const getApi = async (url,headers) => {
    try {
        if(url) {
            let respData = await axios.get(url, headers);
            if(respData?.status == 200) {
                return respData;
            } else {
                return {msg: "Something went wrong"};
            }
        }
    } catch(err) {
        return err;
    }
}

export default getApi;