import React, { useEffect, useState } from "react";
import '../../assets/css/Status/removedStatus.css';
import infoIcon from '../../assets/icons/info.png';
const RemovedStatus = ({title, removedStatusData, fleetStatus, fleetCount, scrollable}) => {
    const [statusLoader, setStatusLoader] = useState(true);
    const [countLoader, setCountLoader] = useState(true);
    const [localFleetStatus, setLocalFleetStatus] = useState();
    const [noFleetStatus, setNoFleetStatus] = useState();
    const [localFleetCount, setLocalFleetCount] = useState();
    const [noFleetCount, setNoFleetCount] = useState();
    useEffect(() => {
        if(fleetStatus !== undefined && fleetStatus.length !== 0 && fleetStatus !== "Error connecting to datasource!") {
            setStatusLoader(false);
            setLocalFleetStatus(fleetStatus);
        } else{
            setStatusLoader(false);
            setNoFleetStatus(0);
        }

        if(fleetCount !== undefined && fleetCount !== "Error connecting to datasource!") {
            setCountLoader(false);
            setLocalFleetCount(fleetCount);
        } else{
            setCountLoader(false);
            setNoFleetCount(0);
        }
    }, [fleetStatus, fleetCount])

    const renderFleetStatusAndCount = () => {
        if(localFleetCount && localFleetStatus) {
            return <p>{localFleetStatus.length} LGVs Out of System (Fleet Size: {localFleetCount[0]?.fleetSize})</p>;
        } else if(noFleetStatus == 0 && noFleetCount == 0) {
            return <p>0 LGVs Out of System (Fleet Size: 0)</p>;
        }
    }

    const renderFleetObjects = () => {
        if(localFleetStatus) {
            localFleetStatus.map((status, index) => {
                if(status) {
                    return (
                        <div key={index} className="status-box">
                            <div className="status-top-section">
                                <p><span>LGV:</span> {status.lgv}</p>
                            </div>
                            <div className="status-bottom-section">
                                <p>{(status.duration).split(' ')[0]} <span>{(status.duration).split(' ')[1]}</span></p>
                            </div>
                        </div>
                    )
                }
            })
        } else {
            return (
                <div className="status-box">
                    <div className="status-top-section">
                        <p><span>LGV:</span> 0</p>
                    </div>
                    <div className="status-bottom-section">
                        <p>0</p>
                    </div>
                </div>
            )
        }
    }
    return (
        <div className="rm-lgv-fleet-status-container">
            {countLoader ? 
                <div className='rm-loader'>
                    <h4>Loading...</h4>
                    <div className='spinner'></div>
                </div> : 
                <div className="fleet-container">
                    <div className="fleet-status">
                        <img src={infoIcon}/>
                        {renderFleetStatusAndCount()}
                    </div>
                </div>
            }
            <div className="rm-status-container">
                <div className="rm-header-section">
                    <h4>{title}</h4>
                </div>
                {statusLoader ? <div className='rm-loader'>
                    <h4>Loading...</h4>
                    <div className='spinner'></div>
                </div> : 
                <div className={`rm-status-section ${scrollable ? 'rm-status-section-wrap' : ''} `}>
                    {localFleetStatus ? 
                        localFleetStatus?.map((status, index) => {
                            if(status) {
                                return (
                                    <div key={index} className="status-box">
                                        <div className="status-top-section">
                                            <p><span>LGV:</span> {status.lgv}</p>
                                        </div>
                                        <div className="status-bottom-section">
                                            <p>{(status.duration).split(' ')[0]} <span>{(status.duration).split(' ')[1]}</span></p>
                                        </div>
                                    </div>
                                )
                            }
                        }
                    ) : <div className="status-box">
                        <div className="status-top-section">
                            <p><span>LGV:</span> 0</p>
                        </div>
                        <div className="status-bottom-section">
                            <p>0 <span>days</span></p>
                        </div>
                </div>}
                </div>
                }
            </div>
        </div>
    )
}

export default RemovedStatus;