export const getDynamicApiLink = () => {
    const origin = window.location.origin;
    if(origin.includes('dev-lgvone') || origin.includes('localhost') || origin.includes('qa-lgvone')) {
        return process.env.REACT_APP_DEV_POINT;
    } else if(origin.includes('uat-lgvone')) {
        return process.env.REACT_APP_UAT_POINT;
    } else if(origin.includes('lgvone.niagarawater')) {
        return process.env.REACT_APP_PROD_POINT;
    }
}

export const getSocketEndPoint = () => {
    const origin = window.location.origin;
    if(origin.includes('dev-lgvone') || origin.includes('localhost') || origin.includes('qa-lgvone')) {
        return process.env.REACT_APP_ON_PREM_POINT;
    } else if(origin.includes('uat-lgvone')) {
        return process.env.REACT_APP_ON_PREM_POINT;
    } else if(origin.includes('lgvone.niagarawater')) {
        return process.env.REACT_APP_PROD_ON_PREM_POINT;
    }
}

// export const lgvDetails = `${getDynamicApiLink()}/lgvdetails`;
// export const fleetStatus = `${getDynamicApiLink()}/lgvfleetstatus`;
// export const shipmentStatus = `${getDynamicApiLink()}/shipmentstatus`;
export const plantLocations = `${getDynamicApiLink()}/plantlocations`;//
// export const activeShipmentStatus = `${getDynamicApiLink()}/shipmentdetails`;
// export const shiftPerformanceStatus = `${getDynamicApiLink()}/shiftdetails`;
// export const warehouseStatus = `${getDynamicApiLink()}/warehousecapacity`;
export const lgvHistoryStatus = `${getDynamicApiLink()}/lgvtraffic`;//
export const heatmapDetails = `${getDynamicApiLink()}/heatmapdetails`;//
// export const lineProductionStatus = `${getDynamicApiLink()}/productiondetails`;
// export const dockDoorStatus = `${getDynamicApiLink()}/dockdetails`;
export const dockDoorBriefStatus = `${getDynamicApiLink()}/dockstatus`;
