import React, { useEffect, useState } from "react";
import '../../assets/css/Status/status.css';
import { statusColorRenderer } from "../../util/helper/helperFunctions";
const Status = ({title, statusData, scrollable}) => {
    const [statusLoader, setStatusLoader] = useState(true);
    const [data, setData] = useState();

    useEffect(() => {
        if(statusData && statusData !== "Error connecting to datasource!") {
            setData(statusData);
            setStatusLoader(false);
        }
    }, [statusData]);

    return (
        <div className="status-container">
            <div className="header-section">
                <h4>{title}</h4>
            </div>
            {statusLoader ? <div className={`status-section loader-alignment ${scrollable ? 'status-section-wrap' : ''} `}>
                <div className='rm-loader'>
                        <h4>Loading...</h4>
                        <div className='spinner'></div>
                </div>
            </div> : <div className={`status-section ${scrollable ? 'status-section-wrap' : ''}`}>
                {data &&
                    data.map((status, index) => {
                        return Object.entries(status).map(([key,value]) => {
                            return (<div key={index} 
                                style={{boxShadow:`0px 6px 12px 0px ${statusColorRenderer(key)}20`}} className="status-box">
                                <div style={key == 'Started' ? {backgroundColor: `#EEEEEE`} : {backgroundColor: `${statusColorRenderer(key)}`}} className="status-top-section">
                                    <p style={key === 'Started' ? {color:'black'} : {color:'white'}}>{key}</p>
                                </div>
                                <div className="status-bottom-section">
                                    <p>{value}</p>
                                </div>
                            </div>)
                        })
                    })
                }
            </div>}
        </div>
    )
}

export default Status;