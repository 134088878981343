import CARMap from "../../assets/images/plantImage/CAR.jpeg";
import ATLMap from "../../assets/images/plantImage/ATL.png";
import DETMap from "../../assets/images/plantImage/DET.png";
import ALAMap from "../../assets/images/plantImage/ALA.png";
import HAZMap from "../../assets/images/plantImage/HAZ.png";
import HOUMap from "../../assets/images/plantImage/HOU.png";
import LASMap from "../../assets/images/plantImage/LAS.png";
import MIAMap from "../../assets/images/plantImage/MIA.png";
import TACMap from "../../assets/images/plantImage/TAC.png";
import JAXMap from "../../assets/images/plantImage/JAX.png";
import LANMap from "../../assets/images/plantImage/LAN.png";
import ST3Map from "../../assets/images/plantImage/ST3.png";
import BALMap from "../../assets/images/plantImage/BAL.png";

import ReactDOMServer from "react-dom/server";
import L from "leaflet";

export const statusColorRenderer = (status) => {
  switch (status) {
    case "Wait Qty Check":
      return "#008300";
    case "Scheduled":
      return "#4647FE";
    case "Wait Dock Cnfrm":
      return "#D2B623";
    case "Wait Start Cnfrm":
      return "#7C4807";
    case "Enqueued To Start":
      return "#FF88FF";
    case "Started":
      return "#8D8D8D";
    case "Staged":
      return "#FF8903";
    case "Suspended":
      return "#7B037F";
    case "Traffic":
      return "#D96E0B";
    case "Alarm":
      return "#EE3232";
    case "Removed":
      return "#000000";
    case "Charging":
      return "#83C4D9";
    case "Iddle":
      return "#008000";
    case "Idle":
      return "#008000";
    case "LGV In Manual":
      return "#9D4B4B";
    case "ActiveFull":
      return "#0000FF";
    case "ActiveEmpty":
      return "#008000";
    default:
      return "#000000";
  }
};

export const isTokenExpired = () => {
  let auth = JSON.parse(localStorage.getItem("isAuthExpiry"));
  if (!auth) {
    return true;
  }
  let expiryTime = new Date(auth.expiryTime);
  let currentTime = new Date();
  if (expiryTime < currentTime) {
    return true;
  }
  return false;
};

export const externalLinkNavigator = (link) => {
  const quickLinks = {
    logistics:
      "https://app.powerbi.com/groups/me/reports/64fa4bc6-35a9-48ea-bdf1-af49555a6d7f/ReportSectionc1d9f7cba778eee595ac?ctid=773bda68-87c6-401a-b9a4-3dfb1f92e189",
    performance:
      "http://pdc-whse-perf:8080/d/wWbpz254z/lgv_one_summary?orgId=1&refresh=1m&var-WHSE=CAR_LGV",
    shipment:
      "http://pdc-whse-perf:8080/d/G7En7Wp4z/shipment-progress?orgId=1&refresh=5s&var-WHSE=CAR_LGV",
    downtime:
      "http://pdc-whse-perf:8080/d/ESqcrJ-4z/lgv-alarm-summary?orgId=1&var-WHSE=CAR_LGV",
    pairing:
      "http://pdc-whse-perf:8080/d/Tdz-8g84k/active-mixed-pairing?orgId=1&refresh=5s",
  };
  return quickLinks[link];
};

export const authExpirationNavigator = (errorApi) => {
  if (errorApi > 4) {
    localStorage.setItem("isAuthExpiry", JSON.stringify(true));
    reloadApp();
  }
};

export const dockDoorStatusColor = (status, mode) => {
  let simpleMode = {
    CanAllocate: "#767676",
    Allocated: "#767676",
    Released: "#767676",
    Started: "#767676",
    InTransit: "#767676",
    Completed: "#309218",
    New: "#FF5733",
    Parked: "#FF5733",
  };
  let dockDoorStatus = {
    CanAllocate: "#D90000",
    Allocated: "#767676",
    Released: "#AD9200",
    Started: "#0000D9",
    InTransit: "#0000D9",
    Completed: "#309218",
    New: "#FF5733",
    Parked: "#FF5733",
  };
  return mode == "SIMPLE" ? simpleMode[status] : dockDoorStatus[status];
};

export const getAdjustedCoordinates = () => {
  let levels = [
    { start: 500, end: 800, level: { x: 56, y: 16 } },
    { start: 801, end: 1200, level: { x: 56, y: 16 } },
    { start: 1201, end: 1400, level: { x: 56, y: 16 } },
    { start: 1401, end: 1600, level: { x: 20, y: 17 } },
    { start: 1601, end: 1800, level: { x: 120, y: 19 } },
    { start: 1801, end: 2000, level: { x: 170, y: 22 } },
    { start: 2001, end: 2200, level: { x: 290, y: 24 } },
    { start: 2201, end: 2400, level: { x: 426, y: 26 } },
    { start: 2401, end: 2600, level: { x: 476, y: 28 } },
    { start: 2601, end: 2800, level: { x: 630, y: 26 } },
  ];
  let windowWidth = window.innerWidth;
  let matchingWindowLevel = levels.find(
    (level) => windowWidth > level.start && windowWidth < level.end
  );
  return matchingWindowLevel;
};

export const getAdjustedLineProductionCoordinates = () => {
  let levels = [
    { start: 500, end: 800, level: { x: 75, y: 170 } },
    { start: 801, end: 1200, level: { x: 240, y: 170 } },
    { start: 1201, end: 1400, level: { x: 75, y: 170 } },
    { start: 1401, end: 1600, level: { x: 60, y: 170 } },
    { start: 1601, end: 1800, level: { x: 60, y: 170 } },
    { start: 1801, end: 2000, level: { x: 110, y: 170 } },
    { start: 2001, end: 2200, level: { x: 230, y: 170 } },
    { start: 2201, end: 2400, level: { x: 610, y: 170 } },
    { start: 2401, end: 2600, level: { x: 650, y: 170 } },
    { start: 2601, end: 2800, level: { x: 550, y: 170 } },
  ];
  let windowWidth = window.innerWidth;
  let matchingWindowLevel = levels.find(
    (level) => windowWidth > level.start && windowWidth < level.end
  );
  return matchingWindowLevel;
};

export const getAdjustedDockDoorCoordinates = () => {
  let levels = [
    { start: 0, end: 200, level: { x: 0, y: 40 } },
    { start: 201, end: 500, level: { x: 0, y: 40 } },
    { start: 501, end: 800, level: { x: 0, y: 40 } },
    { start: 801, end: 1200, level: { x: 0, y: 40 } },
    { start: 1201, end: 1400, level: { x: 0, y: 40 } },
    { start: 1401, end: 1600, level: { x: 0, y: 40 } },
    { start: 1601, end: 1800, level: { x: 120, y: 40 } },
    { start: 1801, end: 2000, level: { x: 180, y: 40 } },
    { start: 2001, end: 2200, level: { x: 300, y: 40 } },
    { start: 2201, end: 2400, level: { x: 650, y: 40 } },
    { start: 2401, end: 2600, level: { x: 650, y: 40 } },
    { start: 2601, end: 2800, level: { x: 650, y: 40 } },
  ];
  let windowWidth = window.innerWidth;
  let matchingWindowLevel = levels.find(
    (level) => windowWidth > level.start && windowWidth < level.end
  );
  return matchingWindowLevel;
};

export const getScreenSize = () => {
  return window.innerWidth;
};

export const setDynamicZoomLevelToMap = (scaleLevel, mapType) => {
  switch (mapType) {
    case "indoor":
      switch (scaleLevel) {
        case "1.0": {
          document.getElementById("indoor-map-id-reference").style.transform =
            "scale(1.0)";
          break;
        }
        case "1.2": {
          document.getElementById("indoor-map-id-reference").style.transform =
            "scale(1.2) translate(120px, 50px)";
          break;
        }
        case "1.4": {
          document.getElementById("indoor-map-id-reference").style.transform =
            "scale(1.4) translate(210px, 90px)";
          break;
        }
        case "1.6": {
          document.getElementById("indoor-map-id-reference").style.transform =
            "scale(1.6) translate(275px, 120px)";
        }
        case "1.8": {
          document.getElementById("indoor-map-id-reference").style.transform =
            "scale(1.8) translate(325px, 135px)";
        }
        case "2.0": {
          document.getElementById("indoor-map-id-reference").style.transform =
            "scale(2.0) translate(360px, 155px)";
        }
      }
      break;

    case "timeMachine":
      switch (scaleLevel) {
        case "1.0": {
          document.getElementById(
            "time-machine-map-id-reference"
          ).style.transform = "scale(1.0)";
          break;
        }
        case "1.2": {
          document.getElementById(
            "time-machine-map-id-reference"
          ).style.transform = "scale(1.2) translate(120px, 50px)";
          break;
        }
        case "1.4": {
          document.getElementById(
            "time-machine-map-id-reference"
          ).style.transform = "scale(1.4) translate(210px, 90px)";
          break;
        }
        case "1.6": {
          document.getElementById(
            "time-machine-map-id-reference"
          ).style.transform = "scale(1.6) translate(275px, 120px)";
        }
        case "1.8": {
          document.getElementById(
            "time-machine-map-id-reference"
          ).style.transform = "scale(1.8) translate(325px, 135px)";
        }
        case "2.0": {
          document.getElementById(
            "time-machine-map-id-reference"
          ).style.transform = "scale(2.0) translate(360px, 155px)";
        }
      }
      break;
  }
};

export const isScreenBigger = () => {
  if (Math.round(((window.outerWidth - 10) / window.innerWidth) * 100) <= 50) {
    setLocalStorageItem("isScreenBigger", true);
    return true;
  }
  return false;
};

export const getHeatmapWeightage = (length) => {
  if (length <= 10) {
    return { fillOpacity: "5%", fillColor: "white" };
  } else if (length > 10 && length < 15) {
    return { fillOpacity: "15%", fillColor: "red" };
  } else if (length >= 15 && length < 20) {
    return { fillOpacity: "25%", fillColor: "red" };
  } else if (length >= 25 && length < 30) {
    return { fillOpacity: "45%", fillColor: "red" };
  } else if (length >= 30) {
    return { fillOpacity: "65%", fillColor: "red" };
  }
};

export const getResponsiveGridLatLng = () => {
  let windowWidth = window.innerWidth;
  if (windowWidth < 1690) {
    return { x: 40, y: 0 };
  }
  if (windowWidth > 1690 && windowWidth < 1700) {
    //80%
    return { x: -40, y: 0 };
  }
  if (windowWidth >= 1700 && windowWidth <= 1820) {
    //75%
    return { x: -70, y: 0 }; // top-bottom, left-right
  }
  if (windowWidth >= 1821 && windowWidth <= 1920) {
    //75%
    return { x: -100, y: 0 }; // top-bottom, left-right
  }
  if (windowWidth >= 1921 && windowWidth <= 2050) {
    //67%
    return { x: -160, y: 0 }; // top-bottom, left-right
  }
  if (windowWidth >= 2051 && windowWidth <= 2732) {
    return { x: -440, y: 0 };
  }
  if (windowWidth >= 2733) {
    return { x: -500, y: 0 };
  }
};

export const convertGridCoordinates = (mapObject) => {
  let convertedCoordinates = mapCoordinate(38000, 182000);
  let convertedPoints = L.point(convertedCoordinates.x, convertedCoordinates.y);
  let convertedLatLng =
    mapObject.map.target.layerPointToLatLng(convertedPoints);
  return convertedLatLng;
};

export const convertGridCoordinatesX = (mapObject) => {
  let convertedCoordinates = mapCoordinate(12500, 168000);
  let convertedPoints = L.point(convertedCoordinates.x, convertedCoordinates.y);
  let convertedLatLng =
    mapObject.map.target.layerPointToLatLng(convertedPoints);
  return convertedLatLng;
};

export const timeFrameFormatter = (timeMachineTimeFrames) => {
  let convertedFromDate = `${timeMachineTimeFrames?.fromDate}T${timeMachineTimeFrames?.fromTime}:00.000Z`;
  let convertedToDate = `${timeMachineTimeFrames?.toDate}T${timeMachineTimeFrames?.toTime}:00.000Z`;
  const { fromTimePlant, toTimePlant } = convertLocaleToPlantTimeZone(
    convertedFromDate,
    convertedToDate
  );
  return { fromTimePlant, toTimePlant };
};

export const apiHeadersFormatter = (accessToken) => {
  return {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET",
      "Access-Control-Allow-Headers": "Content-Type",
    },
  };
};

export const getLocalStorageItem = (objectName) => {
  if (localStorage.getItem(objectName) !== undefined) {
    return JSON.parse(localStorage.getItem(objectName));
  }
};

export const setLocalStorageItem = (objectName, value) => {
  localStorage.setItem(objectName, JSON.stringify(value));
};

export const clearLocalStorageItem = (objectName) => {
  localStorage.removeItem(objectName);
};

export const reloadApp = () => {
  window.location.reload();
};

export const timeStampMinuteToHourFormatter = (time) => {
  let totalTimeParts = time.split(":");
  if (totalTimeParts.length == 2) {
    return `${totalTimeParts[0]}:${totalTimeParts[1]}`;
  } else {
    return `${totalTimeParts[1]}:${totalTimeParts[2]}`;
  }
};

export const getPlantDetails = (plantCode, featureType) => {
  const plantDetails = {
    CAR: {
      image: CARMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -12000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    ATL: {
      image: ATLMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    DET: {
      image: DETMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    ALA: {
      image: ALAMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    // LAYOUT_START_X	15000
    // LAYOUT_END_X	333000
    // LAYOUT_START_Y	0
    // LAYOUT_END_Y	143000
    HAZ: {
      image: HAZMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    HOU: {
      image: HOUMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    LAS: {
      image: LASMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    MIA: {
      image: MIAMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    TAC: {
      image: TACMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    JAX: {
      image: JAXMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    LAN: {
      image: LANMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    ST3: {
      image: ST3Map,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    BAL: {
      image: BALMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
  };
  const plantHeatMapDetails = {
    CAR: {
      image: CARMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 22000 },
        rightTop: { lat: 512000, lng: 178000 },
      },
    },
    ATL: {
      image: ATLMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 22000 },
        rightTop: { lat: 512000, lng: 178000 },
      },
    },
    DET: {
      image: DETMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 22000 },
        rightTop: { lat: 512000, lng: 178000 },
      },
    },
    ALA: {
      image: ALAMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 22000 },
        rightTop: { lat: 512000, lng: 178000 },
      },
    },
    HAZ: {
      image: HAZMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 22000 },
        rightTop: { lat: 512000, lng: 178000 },
      },
    },
    HOU: {
      image: HOUMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 22000 },
        rightTop: { lat: 512000, lng: 178000 },
      },
    },
    LAS: {
      image: LASMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 22000 },
        rightTop: { lat: 512000, lng: 178000 },
      },
    },
    MIA: {
      image: MIAMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 22000 },
        rightTop: { lat: 512000, lng: 178000 },
      },
    },
    TAC: {
      image: TACMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 22000 },
        rightTop: { lat: 512000, lng: 178000 },
      },
    },
    JAX: {
      image: JAXMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 22000 },
        rightTop: { lat: 512000, lng: 178000 },
      },
    },
    LAN: {
      image: LANMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 22000 },
        rightTop: { lat: 512000, lng: 178000 },
      },
    },
    ST3: {
      image: ST3Map,
      bounds: {
        leftBottom: { lat: 38000, lng: 22000 },
        rightTop: { lat: 512000, lng: 178000 },
      },
    },
    BAL: {
      image: BALMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 22000 },
        rightTop: { lat: 512000, lng: 178000 },
      },
    },
  };
  if (featureType == "indoorMap") {
    return plantDetails[plantCode];
  } else {
    return plantHeatMapDetails[plantCode];
  }
};

export const convertLocaleToPlantTimeZone = (
  convertedFromDate,
  convertedToDate
) => {
  if (convertedFromDate && convertedToDate) {
    let fromDate = new Date(convertedFromDate);
    let toDate = new Date(convertedToDate);
    let timeZone = getLocalStorageItem("timeZone");

    const plantTimeZoneFromDate = fromDate.toLocaleString("en-US", {
      timeZone: timeZone,
      timeStyle: "medium",
      dateStyle: "medium",
      hourCycle: "h24",
    });
    const plantTimeZoneToDate = toDate.toLocaleString("en-US", {
      timeZone: timeZone,
      timeStyle: "medium",
      dateStyle: "medium",
      hourCycle: "h24",
    });

    const convert = (dateAndTime) => {
      return new Date(dateAndTime).toISOString();
    };

    let fromTimePlant = convert(plantTimeZoneFromDate);
    let toTimePlant = convert(plantTimeZoneToDate);

    return { fromTimePlant, toTimePlant };
  }
};

export const filterActivePlant = (plantData) => {
  let availableActivePlant = [
    "Carolina",
    "Atlanta",
    "Detroit",
    "Alabama",
    "Hazleton",
    "Houston",
    "Las Vegas",
    "Miami",
  ];
  return plantData.data.filter(
    (data) =>
      data.name == "Carolina" ||
      data.name == "Atlanta" ||
      data.name == "Detroit" ||
      data.name == "Alabama" ||
      data.name == "Hazleton" ||
      data.name == "Houston" ||
      data.name == "Las Vegas" ||
      data.name == "Miami" ||
      data.name == "Tacoma" ||
      data.name == "Jacksonville" ||
      // data.name == "Lancaster" ||
      data.name == "Stockton 3"
    // data.name == "Baltimore"
  );
};

export const plantProductionLineCoordinateCorrection = () => {
  const coordinates = {
    CAR: { x: 40, y: 75 },
    ATL: { x: -215, y: -18 },
    DET: { x: -25, y: -2 },
    ALA: { x: 80, y: -380 },
    HAZ: { x: 250, y: -775 },
    HOU: { x: -130, y: -20 },
    LAS: { x: -250, y: -15 },
    MIA: { x: -75, y: 110 },
    TAC: { x: -425, y: -210 },
    JAX: { x: -20, y: 0 },
    LAN: { x: 10, y: -115 },
    ST3: { x: -290, y: -60 },
    BAL: { x: 720, y: -740 },
  };
  return coordinates[getLocalStorageItem("locationCode")];
};

export const eachProductionLineCoordinateCorrection = (index) => {
  const positions = {
    CAR: { 0: [0, -30], 1: [0, 15], 2: [0, 0], 3: [0, -35], 4: [0, -10] },
    ATL: { 0: [-10, 95], 1: [-10, 70], 2: [-10, 12] },
    DET: { 0: [-10, 100], 1: [-10, 180] },
    ALA: { 0: [-10, 100], 1: [-10, 220] },
    HAZ: {
      0: [60, -335],
      1: [60, -360],
      2: [-19, -255],
      3: [-19, -335],
      4: [15, -225],
      5: [0, -50],
      6: [0, -105],
      7: [0, -5],
    },
    HOU: { 0: [0, 65], 1: [0, 105], 2: [0, 160], 3: [-45, -7] },
    LAS: { 0: [0, -150], 1: [0, 50] },
    MIA: {
      0: [-25, -5],
      1: [0, 380],
      2: [0, 420],
      3: [-32, 500],
      4: [40, 640],
    },
    TAC: { 0: [0, 0], 1: [0, -90], 2: [105, -400] },
    JAX: { 0: [10, 0], 1: [10, 55], 2: [5, 125], 3: [25, 110], 4: [25, 20] },
    LAN: { 0: [10, -90], 1: [10, -55], 2: [10, 20] },
    ST3: { 0: [0, 50], 1: [0, 0] },
    BAL: { 0: [0, 0], 1: [20, -20] },
  };
  return positions[getLocalStorageItem("locationCode")][index];
};

export const plantDockDoorCoordinateCorrection = () => {
  const coordinates = {
    CAR: { x: -40, y: 70 },
    ATL: { x: 340, y: -25 },
    DET: { x: 135, y: 20 },
    ALA: { x: 50, y: 420 },
    HAZ: { x: -332, y: 855 },
    HOU: { x: 200, y: -50 },
    LAS: { x: 190, y: -17 },
    MIA: { x: 210, y: 20 },
    TAC: { x: 745, y: 223 },
    JAX: { x: 85, y: 71 },
    LAN: { x: -60, y: 65 },
    ST3: { x: 100, y: 65 },
    BAL: { x: -862, y: 810 },
  };
  return coordinates[getLocalStorageItem("locationCode")];
};

export const eachDockDoorCoordinateCorrection = (index) => {
  const positions = {
    CAR: {
      0: [0, -33],
      1: [0, -28],
      2: [0, -23],
      3: [0, -18],
      4: [0, -13],
      5: [0, -8],
      6: [0, -5],
      7: [0, 0],
      8: [0, 0],
      9: [0, -7],
      10: [0, 0],
      11: [0, 0],
      12: [0, 0],
      13: [0, -5],
      14: [0, 0],
      15: [0, -5],
      16: [0, 0],
      17: [0, 0],
      18: [0, -5],
      19: [0, 0],
      20: [0, 0],
      21: [0, 0],
      22: [0, -5],
      23: [0, 0],
      24: [0, -5],
      25: [0, 0],
      26: [0, -5],
      27: [0, 0],
      28: [0, 0],
      29: [0, -5],
      30: [0, 0],
      31: [0, 0],
      32: [0, -5],
      33: [0, 0],
      34: [0, 0],
      35: [0, -5],
      36: [0, 0],
      37: [0, 0],
      38: [0, -4],
      39: [0, -2],
      40: [0, 0],
      41: [0, -5],
      42: [0, 0],
      43: [0, 4],
      44: [0, -5],
      45: [0, 0],
      46: [0, 0],
      47: [0, 0],
      48: [0, 0],
      49: [0, 0],
      50: [0, 0],
      51: [0, 0],
      52: [0, 0],
      53: [0, 0],
      54: [0, 0],
      55: [0, 0],
      56: [0, 0],
      57: [0, 0],
    },

    ATL: {
      0: [0, 10],
      1: [0, 0],
      2: [0, -5],
      3: [0, -10],
      4: [0, -20],
      5: [0, -17],
      6: [0, -25],
      7: [0, -30],
      8: [0, -30],
      9: [0, -40],
      10: [0, -40],
      11: [0, -50],
      12: [0, -50],
      13: [0, -60],
      14: [0, -60],
      15: [0, -70],
      16: [0, -75],
      17: [0, -90],
      18: [0, -95],
      19: [0, -100],
      20: [0, -110],
      21: [0, -110],
      22: [0, -115],
      23: [0, -120],
      24: [0, -125],
      25: [0, -135],
      26: [0, -135],
      27: [0, -145],
      28: [0, -150],
      29: [0, -150],
      30: [0, -157],
      31: [0, -165],
      32: [0, -170],
      33: [0, -175],
      34: [0, -190],
      35: [0, -180],
    },

    DET: {
      0: [0, -33],
      1: [0, -28],
      2: [0, -25],
      3: [0, -18],
      4: [0, -13],
      5: [0, -8],
      6: [0, 5],
      7: [0, 10],
      8: [0, 15],
      9: [0, 20],
      10: [0, 25],
      11: [0, 30],
      12: [0, 35],
      13: [0, 55],
      14: [0, 60],
      15: [0, 65],
      16: [0, 72],
      17: [0, 80],
      18: [0, 85],
      19: [0, 100],
      20: [0, 105],
      21: [0, 110],
      22: [0, 115],
      23: [0, 120],
      24: [0, 125],
      25: [0, 130],
      26: [0, 145],
      27: [0, 150],
      28: [0, 155],
      29: [0, 162],
      30: [0, 167],
      31: [0, 172],
      32: [0, 177],
      33: [0, 192],
      34: [0, 197],
      35: [0, 202],
      36: [0, 207],
      37: [0, 215],
      38: [0, 220],
      39: [0, 225],
      40: [0, 240],
      41: [0, 245],
      42: [0, 250],
      43: [0, 255],
      44: [0, 260],
      45: [0, 265],
      46: [0, 270],
      47: [0, 290],
      48: [0, 295],
      49: [0, 300],
      50: [0, 305],
      51: [0, 310],
      52: [0, 315],
      53: [0, 320],
      54: [0, 335],
      55: [0, 340],
      56: [0, 345],
      57: [0, 350],
      58: [0, 355],
      59: [0, 360],
      60: [0, 365],
      61: [0, 380],
    },

    ALA: {
      0: [0, -33],
      1: [0, -28],
      2: [0, -22],
      3: [0, -5],
      4: [0, 0],
      5: [0, 10],
      6: [0, 15],
      7: [0, 20],
      8: [0, 27],
      9: [0, 32],
      10: [0, 45],
      11: [0, 53],
      12: [0, 60],
      13: [0, 65],
      14: [0, 72],
      15: [0, 80],
      16: [0, 85],
      17: [0, 100],
      18: [0, 105],
      19: [0, 112],
      20: [0, 120],
      21: [0, 125],
      22: [0, 133],
      23: [0, 140],
      24: [0, 153],
      25: [0, 160],
      26: [0, 167],
      27: [0, 173],
      28: [0, 180],
      29: [0, 185],
      30: [0, 193],
      31: [0, 205],
      32: [0, 213],
      33: [0, 220],
      34: [0, 225],
      35: [0, 233],
      36: [0, 240],
      37: [0, 245],
      38: [0, 260],
      39: [0, 267],
      40: [0, 273],
      41: [0, 280],
      42: [0, 287],
      43: [0, 293],
      44: [0, 300],
      45: [0, 313],
      46: [0, 320],
      47: [0, 327],
      48: [0, 335],
      49: [0, 340],
      50: [0, 347],
      51: [0, 353],
      52: [0, 367],
      53: [0, 373],
      54: [0, 380],
      55: [0, 385],
    },

    HAZ: {
      0: [0, -248],
      1: [0, -246],
      2: [0, -246],
      3: [0, -243],
      4: [0, -240],
      5: [0, -238],
      6: [0, -234],
      7: [0, -232],
      8: [0, -228],
      9: [0, -225],
      10: [0, -222],
      11: [0, -219],
      12: [0, -217],
      13: [0, -214],
      14: [0, -211],
      15: [0, -207],
      16: [0, -205],
      17: [0, -203],
      18: [0, -198],
      19: [0, -195],
      20: [0, -196],
      21: [0, -192],
      22: [0, -189],
      23: [0, -184],
      24: [0, -180],
      25: [0, -179],
      26: [0, -178],
      27: [0, -176],
      28: [0, -173],
      29: [0, -170],
      30: [0, -167],
      31: [0, -164],
      32: [0, -161],
      33: [0, -158],
      34: [0, -156],
      35: [0, -154],
      36: [0, -152],
      37: [0, -150],
      38: [0, -147],
      39: [0, -144],
      40: [0, -141],
      41: [0, -138],
      42: [0, -134],
      43: [0, -130],
      44: [0, -130],
      45: [0, -124],
      46: [0, -120],
      47: [0, -117],
      48: [0, -114],
      49: [0, -111],
      50: [0, -109],
      51: [0, -105],
      52: [0, -102],
      53: [0, -100],
      54: [0, -97],
      55: [0, -95],
      56: [0, -91],
      57: [0, -89],
      58: [0, -86],
      59: [0, -83],
      60: [0, -82],
      61: [0, -78],
      62: [0, -73],
      63: [0, -72],
      64: [0, -67],
      65: [0, -62],
      66: [0, -61],
      67: [0, -60],
      68: [0, -57],
      69: [0, -54],
      70: [0, -52],
      71: [0, -48],
      72: [0, -45],
      73: [0, -45],
      74: [0, -40],
      75: [0, -38],
      76: [0, -36],
      77: [0, -33],
      78: [0, -30],
      79: [0, -27],
      80: [0, -25],
      81: [0, -20],
      82: [0, -15],
      83: [0, -15],
      84: [0, -10],
      85: [0, -7],
      86: [0, -4],
      87: [0, -2],
      88: [0, 2],
      89: [0, 4],
      90: [0, 6],
      91: [0, 8],
      92: [0, 11],
      93: [0, 13],
      94: [0, 17],
      95: [0, 21],
      96: [0, 24],
      97: [0, 27],
      98: [0, 29],
      99: [0, 30],
      100: [0, 33],
      101: [0, 35],
      102: [0, 37],
      103: [0, 40],
      104: [0, 42],
      105: [0, 44],
      106: [0, 48],
    },

    HOU: {
      0: [0, -33],
      1: [0, -28],
      2: [0, -19],
      3: [0, 0],
      4: [0, 5],
      5: [0, 15],
      6: [0, 20],
      7: [0, 25],
      8: [0, 32],
      9: [0, 39],
      10: [0, 45],
      11: [0, 53],
      12: [0, 65],
      13: [0, 70],
      14: [0, 77],
      15: [0, 80],
      16: [0, 88],
      17: [0, 96],
      18: [0, 102],
      19: [0, 110],
      20: [0, 120],
      21: [0, 125],
      22: [0, 128],
      23: [0, 134],
      24: [0, 142],
      25: [0, 146],
      26: [0, 153],
      27: [0, 170],
      28: [0, 176],
      29: [0, 181],
      30: [0, 183],
      31: [0, 187],
      32: [0, 200],
      33: [0, 205],
      34: [0, 210],
      35: [0, 217],
    },

    LAS: {
      0: [0, -37],
      1: [0, -22],
      2: [0, -14],
      3: [0, -7],
      4: [0, 0],
      5: [0, 8],
      6: [7, 26],
      7: [0, 33],
      8: [0, 41],
      9: [0, 55],
      10: [0, 62],
      11: [0, 87],
      12: [0, 94],
      13: [0, 100],
      14: [0, 108],
      15: [0, 115],
      16: [0, 123],
      17: [0, 130],
      18: [0, 148],
      19: [7, 154],
      20: [0, 161],
      21: [0, 176],
      22: [0, 184],
      23: [0, 193],
      24: [0, 210],
      25: [0, 218],
      26: [0, 240],
      27: [0, 247],
      28: [0, 255],
      29: [0, 270],
      30: [0, 278],
      31: [7, 286],
      32: [0, 302],
      33: [0, 310],
      34: [0, 318],
      35: [0, 331],
      36: [0, 340],
      37: [7, 353],
      38: [0, 362],
      39: [0, 368],
      40: [0, 376],
      41: [0, 390],
      42: [0, 398],
      43: [0, 405],
      44: [0, 500],
      45: [0, 500],
      46: [0, 500],
      47: [0, 500],
      48: [0, 500],
    },

    MIA: {
      0: [0, 0],
      1: [0, 10],
      2: [0, 25],
      3: [0, 35],
      4: [0, 45],
      5: [0, 80],
      6: [0, 90],
      7: [0, 100],
      8: [0, 107],
      9: [0, 115],
      10: [0, 125],
      11: [0, 145],
      12: [0, 153],
      13: [0, 160],
      14: [0, 170],
      15: [0, 180],
      16: [0, 190],
      17: [0, 200],
      18: [0, 225],
      19: [0, 233],
      20: [0, 240],
      21: [0, 250],
      22: [0, 260],
      23: [0, 270],
      24: [0, 295],
      25: [0, 305],
      26: [0, 315],
      27: [0, 330],
      28: [0, 340],
      29: [0, 367],
      30: [0, 376],
      31: [0, 385],
      32: [0, 395],
      33: [0, 405],
      34: [0, 415],
      35: [0, 430],
      36: [0, 440],
      37: [0, 448],
      38: [0, 458],
      39: [0, 468],
      40: [0, 478],
      41: [0, 5000],
      42: [0, 5000],
      43: [0, 5000],
      44: [0, 5000],
      45: [0, 5000],
      46: [0, 5000],
      47: [0, 5000],
      48: [0, 5000],
    },

    TAC: {
      0: [0, -15],
      1: [0, -32],
      2: [0, -48],
      3: [0, -65],
      4: [0, -130],
      5: [0, -143],
      6: [0, -160],
      7: [0, -175],
      8: [0, -205],
      9: [0, -222],
      10: [0, -255],
      11: [0, -272],
      12: [0, -287],
      13: [0, -335],
      14: [0, -350],
      15: [0, -382],
      16: [0, -395],
      17: [0, -415],
      18: [0, -430],
      19: [0, -475],
      20: [0, -510],
      21: [0, -525],
      22: [0, -540],
      23: [0, -555],
      24: [0, -585],
    },

    LAN: {
      0: [0, 15],
      1: [0, 17],
      2: [0, 19],
      3: [0, 17],
      4: [0, 19],
      5: [0, 19],
      6: [0, 21],
      7: [0, 21],
      8: [0, 23],
      9: [0, 25],
      10: [0, 26],
      11: [0, 29],
      12: [0, 28],
      13: [0, 30],
      14: [0, 31],
      15: [0, 35],
      16: [0, 35],
      17: [0, 36],
      18: [0, 37],
      19: [0, 38],
      20: [0, 42],
      21: [0, 41],
      22: [0, 44],
      23: [0, 44],
      24: [0, 47],
      25: [0, 49],
      26: [0, 50],
      27: [0, 51],
      28: [0, 52],
      29: [0, 53],
      30: [0, 53],
      31: [0, 54],
      32: [0, 57],
      33: [0, 56],
      34: [0, 58],
      35: [0, 59],
      36: [0, 62],
      37: [0, 63],
      38: [0, 64],
      39: [0, 66],
      40: [0, 67],
      41: [0, 68],
      42: [0, 70],
      43: [0, 71],
      44: [0, 73],
      45: [0, 75],
      46: [0, 77],
      47: [0, 79],
      48: [0, 79],
      49: [0, 80],
      50: [0, 82],
      51: [0, 81],
      52: [0, 84],
      53: [0, 84],
      54: [0, 86],
      55: [0, 88],
      56: [0, 90],
      57: [0, 91],
      58: [0, 90],
      59: [0, 92],
      60: [0, 94],
      61: [0, 96],
      62: [0, 96],
      63: [0, 98],
      64: [0, 101],
      65: [0, 101],
      66: [0, 102],
      67: [0, 103],
      68: [0, 104],
      69: [0, 106],
      70: [0, 107],
      71: [0, 111],
      72: [0, 110],
      73: [0, 113],
      74: [0, 113],
      75: [0, 113],
      76: [0, 115],
      77: [0, 116],
      78: [0, 117],
      79: [0, 119],
      80: [0, 120],
      81: [0, 122],
      82: [0, 123],
      83: [0, 126],
      84: [0, 127],
      85: [0, 129],
      86: [0, 129],
      87: [0, 131],
      88: [0, 132],
      89: [0, 134],
      90: [0, 136],
      91: [0, 137],
      92: [0, 139],
      93: [0, 139],
      94: [0, 140],
      95: [0, 141],
      96: [0, 142],
      97: [0, 144],
      98: [0, 145],
      99: [0, 146],
      100: [0, 148],
      101: [0, 150],
      102: [0, 152],
      103: [0, 153],
    },

    ST3: {
      0: [0, 278],
      1: [0, 276],
      2: [0, 274],
      3: [0, 267],
      4: [0, 266],
      5: [0, 264],
      6: [0, 260],
      7: [0, 259],
      8: [0, 255],
      9: [0, 250],
      10: [0, 243],
      11: [0, 238],
      12: [0, 236],
      13: [0, 232],
      14: [0, 230],
      15: [0, 228],
      16: [0, 225],
      17: [0, 212],
      18: [0, 211],
      19: [0, 209],
      20: [0, 207],
      21: [0, 206],
      22: [0, 205],
      23: [0, 203],
      24: [0, 195],
      25: [0, 193],
      26: [0, 191],
      27: [0, 187],
      28: [0, 185],
      29: [0, 182],
      30: [0, 179],
      31: [0, 170],
      32: [0, 167],
      33: [0, 165],
      34: [0, 161],
      35: [0, 159],
      36: [0, 157],
      37: [0, 155],
      38: [0, 148],
      39: [0, 146],
      40: [0, 143],
      41: [0, 141],
      42: [0, 138],
      43: [0, 134],
      44: [0, 130],
      45: [0, 123],
      46: [0, 121],
      47: [0, 117],
      48: [0, 114],
      49: [0, 112],
      50: [0, 110],
      51: [0, 107],
      52: [0, 101],
      53: [0, 98],
      54: [0, 95],
      55: [0, 93],
      56: [0, 90],
      57: [0, 86],
      58: [0, 83],
      59: [0, 78],
      60: [0, 75],
      61: [0, 71],
      62: [0, 69],
      63: [0, 66],
      64: [0, 64],
      65: [0, 61],
      66: [0, 55],
      67: [0, 53],
      68: [0, 50],
      69: [0, 47],
      70: [0, 43],
      71: [0, 40],
      72: [0, 37],
      73: [0, 31],
      74: [0, 29],
      75: [0, 27],
      76: [0, 24],
    },
    BAL: {
      0: [0, 278],
      1: [0, 276],
      2: [0, 274],
      3: [0, 267],
      4: [0, 266],
      5: [0, 264],
      6: [0, 260],
      7: [0, 259],
      8: [0, 255],
      9: [0, 250],
      10: [0, 245],
      11: [0, 242],
      12: [0, 239],
      13: [0, 236],
      14: [0, 233],
      15: [0, 230],
      16: [0, 227],
      17: [0, 217],
      18: [0, 214],
      19: [0, 211],
      20: [0, 209],
      21: [0, 206],
      22: [0, 203],
      23: [0, 200],
      24: [0, 190],
      25: [0, 187],
      26: [0, 184],
      27: [0, 181],
      28: [0, 178],
      29: [0, 175],
      30: [0, 172],
      31: [1, 162],
      32: [0, 159],
      33: [0, 156],
      34: [0, 153],
      35: [0, 150],
      36: [0, 147],
      37: [0, 145],
      38: [0, 136],
      39: [0, 133],
      40: [0, 130],
      41: [0, 127],
      42: [0, 124],
      43: [0, 121],
      44: [0, 118],
      45: [0, 107],
      46: [0, 105],
      47: [0, 102],
      48: [0, 99],
      49: [0, 96],
      50: [0, 93],
      51: [0, 90],
      52: [0, 82],
      53: [0, 79],
      54: [0, 76],
      55: [0, 73],
      56: [0, 70],
      57: [0, 67],
      58: [0, 65],
      59: [0, 57],
      60: [0, 54],
      61: [0, 51],
      62: [0, 48],
      63: [0, 45],
      64: [0, 42],
      65: [0, 39],
      66: [0, 30],
      67: [0, 27],
      68: [0, 24],
      69: [0, 21],
    },
    JAX: {
      0: [0, 0], // dock door 2
      1: [0, 0],
      2: [0, 0],
      3: [0, 0],
      4: [0, 0],
      5: [0, 0],
      6: [0, 0],
      7: [0, 8], // dock door 9
      8: [0, 9],
      9: [0, 10],
      10: [0, 10],
      11: [0, 10], // dock door 14
      12: [0, 11],
      13: [0, 10],
      14: [0, 11],
      15: [0, 11], // dock door 16
      16: [0, 12],
      17: [0, 13],
      18: [0, 14], // dock door 21
      19: [0, 15],
      20: [0, 16],
      21: [0, 17],
      22: [0, 18],
      23: [0, 19],
      24: [0, 20],
      25: [0, 21],
      26: [0, 22], // dock door 30
      27: [0, 23],
      28: [0, 24],
      29: [0, 25],
      30: [0, 26],
      31: [1, 27],
      32: [0, 28],
      33: [0, 29],
      34: [0, 30],
      35: [0, 31], // dock door 40
      36: [0, 32],
      37: [0, 33],
      38: [0, 34],
      39: [0, 35], // dock door 45
      40: [0, 36],
      41: [0, 37],
      42: [0, 38],
      43: [0, 39],
      44: [0, 40],
      45: [0, 42],
      46: [0, 42], // dock door 52
      47: [0, 42],
      48: [0, 46],
      49: [0, 46], // dock door  55
      50: [0, 46], //dock door 56
      51: [0, 47],
      52: [0, 48],
      53: [0, 49],
      54: [0, 50],
      55: [0, 51],
      56: [0, 52],
      57: [0, 54], // dock 63
      58: [0, 56],
      59: [0, 58],
      60: [0, 57], // 66 dock door
    },
  };
  if (positions[getLocalStorageItem("locationCode")] !== undefined) {
    return positions[getLocalStorageItem("locationCode")][index];
  }
};

export const findDockDoorPosition = () => {
  let dockDoorPosition = {
    CAR: "DOWN",
    ATL: "UP",
    DET: "UP",
    ALA: "UP",
    HAZ: "UP",
    HOU: "UP",
    LAS: "UP",
    MIA: "DOWN",
    TAC: "UP",
    JAX: "DOWN",
    LAN: "DOWN",
    ST3: "UP",
    BAL: "UP",
  };
  return dockDoorPosition[getLocalStorageItem("locationCode")];
};

export const lgvCoordinateCorrection = () => {
  let lgvCorrection = {
    CAR: { x: -120, y: 0 },
    ATL: { x: 0, y: 327 },
    DET: { x: 0, y: 510 },
    ALA: { x: -355, y: 300 },
    HAZ: { x: 30, y: 295 },
    HOU: { x: 25, y: 450 },
    LAS: { x: 35, y: 398 },
    MIA: { x: 0, y: 525 },
    TAC: { x: -240, y: 353 },
    JAX: { x: -85, y: 222 },
    LAN: { x: -100, y: -10 },
    ST3: { x: -75, y: 298 },
    BAL: { x: -779, y: -890 },
  };
  return lgvCorrection[getLocalStorageItem("locationCode")];
};

export const heatmapCoordinateCorrection = () => {
  let heatmapCorrection = {
    CAR: { x: 35, y: -35 },
    ATL: { x: 175, y: 280 },
    DET: { x: 0, y: 510 },
    ALA: { x: -355, y: 300 },
    HAZ: { x: 100, y: 265 },
    HOU: { x: 70, y: 450 },
    LAS: { x: 35, y: 398 },
    MIA: { x: 30, y: 505 },
    TAC: { x: -240, y: 333 },
    JAX: { x: -65, y: 202 },
    LAN: { x: -100, y: -10 },
    ST3: { x: -65, y: 278 },
    BAL: { x: -779, y: -890 },
  };
  return heatmapCorrection[getLocalStorageItem("locationCode")];
};

export const destinationPointersCoordinateCorrection = (actionObject) => {
  let sourcePointersCorrection = {
    CAR: { x: -83, y: -30 },
    ATL: { x: 38, y: 300 },
    DET: { x: 40, y: 480 },
    ALA: { x: -318, y: 265 },
    HOU: { x: 60, y: 420 },
    HAZ: { x: 70, y: 266 },
    LAS: { x: 70, y: 368 },
    MIA: { x: 35, y: 498 },
    TAC: { x: -202, y: 325 },
    JAX: { x: -50, y: 190 },
    LAN: { x: -60, y: -40 },
    ST3: { x: -35, y: 270 },
    BAL: { x: -745, y: -920 },
  };
  let destinationPointersCorrection = {
    CAR: { x: -20, y: -35 },
    ATL: { x: 0, y: 300 },
    DET: { x: 0, y: 480 },
    ALA: { x: -350, y: 270 },
    HOU: { x: 20, y: 420 },
    HAZ: { x: 70, y: 250 },
    LAS: { x: 20, y: 370 },
    MIA: { x: 70, y: 500 },
    TAC: { x: -250, y: 320 },
    JAX: { x: -50, y: 195 },
    LAN: { x: -60, y: -40 },
    ST3: { x: -100, y: 265 },
    BAL: { x: -795, y: -925 },
  };
  let otherDestinationPointersCorrection = {
    CAR: { x: -90, y: -30 },
    ATL: { x: 40, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -345, y: 100 },
    HOU: { x: 60, y: 420 },
    HAZ: { x: 75, y: 267 },
    LAS: { x: -50, y: 360 },
    MIA: { x: 38, y: 495 },
    TAC: { x: -200, y: 310 },
    JAX: { x: -40, y: 200 },
    LAN: { x: -60, y: -40 },
    ST3: { x: -100, y: 200 },
    BAL: { x: -855, y: -885 },
  };

  let hindOffDestinationPointersCorrection = {
    CAR: { x: -80, y: -70 },
    ATL: { x: 110, y: 310 },
    DET: { x: 110, y: 310 },
    ALA: { x: -345, y: 100 },
    HOU: { x: 60, y: 400 },
    HAZ: { x: 60, y: 400 },
    LAS: { x: -50, y: 360 },
    MIA: { x: 35, y: 520 },
    TAC: { x: -200, y: 260 },
    JAX: { x: -40, y: 130 },
    LAN: { x: -100, y: -10 },
    ST3: { x: -40, y: 360 },
    BAL: { x: -710, y: -870 },
  };

  let awOffDestinationPointersCorrection = {
    CAR: { x: -90, y: 20 },
    ATL: { x: 40, y: 290 },
    DET: { x: 40, y: 520 },
    ALA: { x: -345, y: 100 },
    HOU: { x: 60, y: 420 },
    HAZ: { x: 75, y: 267 },
    LAS: { x: -50, y: 360 },
    MIA: { x: 50, y: 480 },
    TAC: { x: -190, y: 410 },
    JAX: { x: -50, y: 240 },
    LAN: { x: -100, y: -10 },
    ST3: { x: -35, y: 275 },
    BAL: { x: -742, y: -900 },
  };

  let hwDestinationPointersCorrection = {
    CAR: { x: -85, y: -10 },
    ATL: { x: 40, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -345, y: 100 },
    HOU: { x: 60, y: 420 },
    HAZ: { x: 75, y: 267 },
    LAS: { x: -50, y: 360 },
    MIA: { x: 35, y: 500 },
    TAC: { x: -205, y: 380 },
    JAX: { x: -35, y: 180 },
    LAN: { x: -100, y: -10 },
    ST3: { x: -40, y: 275 },
    BAL: { x: -740, y: -900 },
  };

  let ceDestinationPointersCorrection = {
    CAR: { x: -90, y: 20 },
    ATL: { x: 40, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -345, y: 100 },
    HOU: { x: 60, y: 200 },
    HAZ: { x: 75, y: 267 },
    LAS: { x: -50, y: 360 },
    MIA: { x: 35, y: 460 },
    TAC: { x: -202, y: 310 },
    JAX: { x: -50, y: 180 },
    LAN: { x: -100, y: -10 },
    ST3: { x: -40, y: 260 },
    BAL: { x: -745, y: -1000 },
  };

  let jwDestinationPointersCorrection = {
    CAR: { x: -90, y: 20 },
    ATL: { x: 40, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -345, y: 100 },
    HOU: { x: 60, y: 420 },
    HAZ: { x: 75, y: 267 },
    LAS: { x: -50, y: 360 },
    MIA: { x: 50, y: 480 },
    TAC: { x: -200, y: 310 },
    ST3: { x: -42, y: 270 },
    LAN: { x: -100, y: -10 },
    JAX: { x: -50, y: 185 },
    BAL: { x: -500, y: 0 },
  };

  let ssrDestination = {
    CAR: { x: -90, y: 20 },
    ATL: { x: 40, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -340, y: 110 },
    HOU: { x: 60, y: 420 },
    HAZ: { x: 75, y: 267 },
    LAS: { x: -50, y: 360 },
    MIA: { x: 50, y: 480 },
    TAC: { x: -200, y: 310 },
    JAX: { x: -35, y: 180 },
    LAN: { x: -100, y: -10 },
    ST3: { x: -40, y: 265 },
    BAL: { x: -735, y: -913 },
  };

  let ewDestinationPointersCorrection = {
    CAR: { x: -85, y: -10 },
    ATL: { x: 40, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -345, y: 100 },
    HOU: { x: 40, y: 520 },
    HAZ: { x: 75, y: 267 },
    LAS: { x: -50, y: 360 },
    MIA: { x: 50, y: 480 },
    TAC: { x: -200, y: 310 },
    ST3: { x: 20, y: 320 },
    LAN: { x: -40, y: -10 },
    JAX: { x: -50, y: 185 },
    BAL: { x: 0, y: 0 },
  };

  let gwDestinationPointersCorrection = {
    CAR: { x: -90, y: 20 },
    ATL: { x: 40, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -345, y: 100 },
    HOU: { x: 60, y: 470 },
    HAZ: { x: 75, y: 267 },
    LAS: { x: -50, y: 360 },
    MIA: { x: 50, y: 480 },
    TAC: { x: -250, y: 320 },
    JAX: { x: -40, y: 160 },
    LAN: { x: -100, y: -10 },
    ST3: { x: -100, y: 200 },
    BAL: { x: 0, y: 0 },
  };

  let strDestinationPointersCorrection = {
    CAR: { x: -90, y: 20 },
    ATL: { x: 40, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -345, y: 100 },
    HOU: { x: 60, y: 350 },
    HAZ: { x: 75, y: 267 },
    LAS: { x: -50, y: 360 },
    MIA: { x: 50, y: 480 },
    TAC: { x: -200, y: 330 },
    JAX: { x: -35, y: 180 },
    LAN: { x: -100, y: -10 },
    ST3: { x: -100, y: 200 },
    BAL: { x: 0, y: 0 },
  };

  let sorDestinationPointersCorrection = {
    CAR: { x: -90, y: 20 },
    ATL: { x: 40, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -345, y: 100 },
    HOU: { x: 60, y: 420 },
    HAZ: { x: 75, y: 267 },
    LAS: { x: -50, y: 360 },
    MIA: { x: 50, y: 480 },
    TAC: { x: -205, y: 310 },
    JAX: { x: -45, y: 220 },
    LAN: { x: -100, y: -10 },
    ST3: { x: -30, y: 290 },
    BAL: { x: 0, y: 0 },
  };

  let iwDestinationPointersCorrection = {
    CAR: { x: -90, y: 20 },
    ATL: { x: 40, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -345, y: 100 },
    HOU: { x: 40, y: 520 },
    HAZ: { x: 75, y: 267 },
    LAS: { x: -50, y: 360 },
    MIA: { x: 50, y: 480 },
    TAC: { x: -200, y: 310 },
    JAX: { x: -50, y: 180 },
    LAN: { x: -100, y: -10 },
    ST3: { x: -35, y: 270 },
    BAL: { x: -850, y: -920 },
  };

  let battDestinationPointersCorrection = {
    CAR: { x: 100, y: -30 },
    ATL: { x: 110, y: 340 },
    DET: { x: 110, y: 340 },
    ALA: { x: -200, y: 120 },
    HOU: { x: 40, y: 520 },
    HAZ: { x: 75, y: 267 },
    LAS: { x: -50, y: 360 },
    MIA: { x: 50, y: 480 },
    TAC: { x: -250, y: 320 },
    JAX: { x: -35, y: 180 },
    ST3: { x: -100, y: 200 },
    BAL: { x: 0, y: 0 },
  };

  let aeDestinations = {
    CAR: { x: -90, y: 20 },
    ATL: { x: 40, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -345, y: 100 },
    HOU: { x: 40, y: 520 },
    HAZ: { x: 75, y: 267 },
    LAS: { x: -50, y: 360 },
    MIA: { x: 50, y: 480 },
    TAC: { x: -200, y: 100 },
    JAX: { x: -35, y: 180 },
    LAN: { x: -100, y: -10 },
    ST3: { x: -100, y: 200 },
    BAL: { x: 0, y: 0 },
  };

  let rdrkDestinationPointersCorrection = {
    CAR: { x: -90, y: 20 },
    ATL: { x: 40, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -345, y: 100 },
    HOU: { x: 40, y: 520 },
    HAZ: { x: 75, y: 267 },
    LAS: { x: -50, y: 360 },
    MIA: { x: 50, y: 480 },
    TAC: { x: -200, y: 310 },
    JAX: { x: -60, y: 200 },
    ST3: { x: -100, y: 200 },
    BAL: { x: 0, y: 0 },
  };

  let awDestinations = {
    CAR: { x: -80, y: -20 },
    ATL: { x: 40, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -320, y: 100 },
    HOU: { x: 60, y: 420 },
    HAZ: { x: 75, y: 267 },
    LAS: { x: -50, y: 360 },
    MIA: { x: 35, y: 570 },
    TAC: { x: -203, y: 340 },
    JAX: { x: -35, y: 180 },
    LAN: { x: -100, y: -10 },
    ST3: { x: -35, y: 330 },
    BAL: { x: 0, y: 0 },
  };

  let ceDestinations = {
    CAR: { x: -90, y: 20 },
    ATL: { x: 40, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -345, y: 100 },
    HOU: { x: 40, y: 520 },
    HAZ: { x: 75, y: 267 },
    LAS: { x: -50, y: 360 },
    MIA: { x: -10, y: 180 },
    TAC: { x: -200, y: 180 },
    JAX: { x: -35, y: 180 },
    LAN: { x: -100, y: -10 },
    ST3: { x: -100, y: 200 },
    BAL: { x: -100, y: -100 },
  };

  let beDestinations = {
    CAR: { x: -80, y: -10 },
    ATL: { x: 40, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -345, y: 100 },
    HOU: { x: 60, y: 420 },
    HAZ: { x: 75, y: 267 },
    LAS: { x: -50, y: 360 },
    MIA: { x: 50, y: 480 },
    TAC: { x: -200, y: 180 },
    JAX: { x: -35, y: 180 },
    LAN: { x: -100, y: -10 },
    ST3: { x: -100, y: 200 },
    BAL: { x: -0, y: -0 },
  };

  let nsrDestinations = {
    CAR: { x: -90, y: 20 },
    ATL: { x: 40, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -345, y: 100 },
    HOU: { x: 40, y: 520 },
    HAZ: { x: 75, y: 267 },
    LAS: { x: -50, y: 360 },
    MIA: { x: 50, y: 480 },
    TAC: { x: -200, y: 280 },
    JAX: { x: -35, y: 180 },
    LAN: { x: -100, y: -10 },
    ST3: { x: -100, y: 200 },
    BAL: { x: 0, y: 0 },
  };

  let deDestinations = {
    CAR: { x: -85, y: -40 },
    ATL: { x: 40, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -345, y: 100 },
    HOU: { x: 60, y: 420 },
    HAZ: { x: 75, y: 267 },
    LAS: { x: -50, y: 360 },
    MIA: { x: -200, y: 280 },
    TAC: { x: -200, y: 310 },
    JAX: { x: -35, y: 180 },
    LAN: { x: -40, y: -10 },
    ST3: { x: -35, y: 270 },
    BAL: { x: 0, y: 0 },
  };

  let cwgldDestinations = {
    CAR: { x: -90, y: 20 },
    ATL: { x: 40, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -345, y: 100 },
    HOU: { x: 40, y: 520 },
    HAZ: { x: 75, y: 267 },
    LAS: { x: -50, y: 360 },
    MIA: { x: 50, y: 480 },
    TAC: { x: -205, y: 310 },
    JAX: { x: -35, y: 180 },
    LAN: { x: -100, y: -10 },
    ST3: { x: -100, y: 200 },
    BAL: { x: -500, y: -1000 },
  };

  let srbeDestinations = {
    CAR: { x: -90, y: 20 },
    ATL: { x: 40, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -345, y: 100 },
    HOU: { x: 40, y: 520 },
    HAZ: { x: 75, y: 267 },
    LAS: { x: -50, y: 360 },
    MIA: { x: 50, y: 480 },
    TAC: { x: -200, y: 260 },
    JAX: { x: -35, y: 180 },
    LAN: { x: -100, y: -10 },
    ST3: { x: -100, y: 200 },
    BAL: { x: 0, y: 0 },
  };

  let bwdDestination = {
    CAR: { x: -90, y: 20 },
    ATL: { x: 40, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -345, y: 100 },
    HOU: { x: 60, y: 420 },
    HAZ: { x: 75, y: 267 },
    LAS: { x: -50, y: 360 },
    MIA: { x: 50, y: 480 },
    ST3: { x: -40, y: 300 },
    TAC: { x: -205, y: 315 },
    LAN: { x: -100, y: -10 },
    JAX: { x: -35, y: 180 },
    BAL: { x: 0, y: 0 },
  };

  let jedDestinationPointersCorrection = {
    CAR: { x: -90, y: 20 },
    ATL: { x: 40, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -345, y: 100 },
    HOU: { x: 40, y: 520 },
    HAZ: { x: 75, y: 267 },
    LAS: { x: -50, y: 360 },
    MIA: { x: 50, y: 480 },
    TAC: { x: -200, y: 310 },
    JAX: { x: -50, y: 150 },
    LAN: { x: -100, y: -10 },
    ST3: { x: -100, y: 200 },
    BAL: { x: 0, y: 0 },
  };

  let heDestinations = {
    CAR: { x: -90, y: 20 },
    ATL: { x: 40, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -345, y: 100 },
    HOU: { x: 58, y: 420 },
    HAZ: { x: 75, y: 267 },
    LAS: { x: -50, y: 360 },
    MIA: { x: 50, y: 480 },
    TAC: { x: -200, y: 310 },
    JAX: { x: -35, y: 180 },
    LAN: { x: -100, y: -10 },
    ST3: { x: -45, y: 260 },
    BAL: { x: 0, y: 0 },
  };

  let rspDestination = {
    CAR: { x: -90, y: 20 },
    ATL: { x: 40, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -345, y: 100 },
    HOU: { x: 40, y: 520 },
    HAZ: { x: 75, y: 267 },
    LAS: { x: -50, y: 360 },
    MIA: { x: 50, y: 480 },
    TAC: { x: -200, y: 310 },
    JAX: { x: -35, y: 180 },
    LAN: { x: -100, y: -10 },
    ST3: { x: -38, y: 290 },
    BAL: { x: 0, y: 0 },
  };

  let cwDestinationPointersCorrection = {
    CAR: { x: -80, y: 0 },
    ATL: { x: 0, y: 0 },
    DET: { x: 0, y: 0 },
    ALA: { x: 0, y: 0 },
    HOU: { x: 60, y: 420 },
    HAZ: { x: 0, y: 0 },
    LAS: { x: 0, y: 0 },
    MIA: { x: 0, y: 0 },
    TAC: { x: -200, y: 380 },
    JAX: { x: 0, y: 0 },
    LAN: { x: 0, y: 0 },
    ST3: { x: -35, y: 270 },
    BAL: { x: -800, y: -500 },
  };

  let dwDestination = {
    CAR: { x: -85, y: 0 },
    ATL: { x: 0, y: 0 },
    DET: { x: 0, y: 0 },
    ALA: { x: 0, y: 0 },
    HOU: { x: 60, y: 420 },
    HAZ: { x: 0, y: 0 },
    LAS: { x: 0, y: 0 },
    MIA: { x: 35, y: 600 },
    TAC: { x: 0, y: 0 },
    JAX: { x: 0, y: 0 },
    LAN: { x: 0, y: 0 },
    ST3: { x: -37, y: 280 },
    BAL: { x: -745, y: -860 },
  };

  let fwDestinationPointersCorrection = {
    CAR: { x: -85, y: 0 },
    ATL: { x: 0, y: 0 },
    DET: { x: 0, y: 0 },
    ALA: { x: 0, y: 0 },
    HOU: { x: 0, y: 0 },
    HAZ: { x: 0, y: 0 },
    LAS: { x: 0, y: 0 },
    MIA: { x: 0, y: 0 },
    TAC: { x: 0, y: 0 },
    JAX: { x: 0, y: 0 },
    LAN: { x: 0, y: 0 },
    ST3: { x: -35, y: 275 },
    BAL: { x: 0, y: 0 },
  };

  let geDestinationPointersCorrection = {
    CAR: { x: -85, y: -45 },
    ATL: { x: 0, y: 0 },
    DET: { x: 0, y: 0 },
    ALA: { x: 0, y: 0 },
    HOU: { x: 60, y: 420 },
    HAZ: { x: 0, y: 0 },
    LAS: { x: 0, y: 0 },
    MIA: { x: 0, y: 0 },
    TAC: { x: 0, y: 0 },
    JAX: { x: 0, y: 0 },
    LAN: { x: 0, y: 0 },
    ST3: { x: 0, y: 0 },
    BAL: { x: -760, y: -960 },
  };

  let feDestinationPointersCorrection = {
    CAR: { x: -85, y: -80 },
    ATL: { x: 0, y: 0 },
    DET: { x: 0, y: 0 },
    ALA: { x: 0, y: 0 },
    HOU: { x: 0, y: 0 },
    HAZ: { x: 0, y: 0 },
    LAS: { x: 0, y: 0 },
    MIA: { x: 0, y: 0 },
    TAC: { x: 0, y: 0 },
    LAN: { x: 0, y: 0 },
    ST3: { x: 100, y: 300 },
    JAX: { x: -45, y: 150 },
    BAL: { x: -725, y: -990 },
  };

  let ieDestinationPointersCorrection = {
    CAR: { x: -83, y: -40 },
    ATL: { x: 0, y: 0 },
    DET: { x: 0, y: 0 },
    ALA: { x: 0, y: 0 },
    HOU: { x: 0, y: 0 },
    HAZ: { x: 0, y: 0 },
    LAS: { x: 0, y: 0 },
    MIA: { x: 0, y: 0 },
    TAC: { x: 0, y: 0 },
    LAN: { x: 0, y: 0 },
    ST3: { x: 100, y: 300 },
    JAX: { x: -45, y: 150 },
    BAL: { x: -725, y: -990 },
  };

  let rwrkDestinationPointersCorrection = {
    CAR: { x: -83, y: -40 },
    ATL: { x: 0, y: 0 },
    DET: { x: 0, y: 0 },
    ALA: { x: 0, y: 0 },
    HOU: { x: 60, y: 420 },
    HAZ: { x: 0, y: 0 },
    LAS: { x: 0, y: 0 },
    MIA: { x: 0, y: 0 },
    TAC: { x: 0, y: 0 },
    LAN: { x: 0, y: 0 },
    ST3: { x: -100, y: 300 },
    JAX: { x: -45, y: 150 },
    BAL: { x: -725, y: -990 },
  };
  let dgldDestinationPointersCorrection = {
    CAR: { x: -83, y: -40 },
    ATL: { x: 0, y: 0 },
    DET: { x: 0, y: 0 },
    ALA: { x: 0, y: 0 },
    HOU: { x: 60, y: 420 },
    HAZ: { x: 0, y: 0 },
    LAS: { x: 0, y: 0 },
    MIA: { x: 0, y: 0 },
    TAC: { x: 0, y: 0 },
    LAN: { x: 0, y: 0 },
    ST3: { x: -100, y: 300 },
    JAX: { x: -45, y: 150 },
    BAL: { x: -725, y: -990 },
  };

  let l1DestinationPointersCorrection = {
    CAR: { x: -83, y: -40 },
    ATL: { x: 0, y: 0 },
    DET: { x: 0, y: 0 },
    ALA: { x: 0, y: 0 },
    HOU: { x: 0, y: 0 },
    HAZ: { x: 0, y: 0 },
    LAS: { x: 0, y: 0 },
    MIA: { x: 0, y: 0 },
    TAC: { x: 0, y: 0 },
    LAN: { x: 0, y: 0 },
    ST3: { x: -100, y: 300 },
    JAX: { x: -45, y: 150 },
    BAL: { x: -725, y: -990 },
  };

  let l3DestinationPointersCorrection = {
    CAR: { x: -83, y: -40 },
    ATL: { x: 0, y: 0 },
    DET: { x: 0, y: 0 },
    ALA: { x: 0, y: 0 },
    HOU: { x: 60, y: 420 },
    HAZ: { x: 0, y: 0 },
    LAS: { x: 0, y: 0 },
    MIA: { x: 0, y: 0 },
    TAC: { x: 0, y: 0 },
    LAN: { x: 0, y: 0 },
    ST3: { x: -100, y: 300 },
    JAX: { x: -45, y: 150 },
    BAL: { x: -725, y: -990 },
  };

  if (actionObject == "lgv") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        destinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject == "batt") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        battDestinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject == "hind") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        hindOffDestinationPointersCorrection[
          getLocalStorageItem("locationCode")
        ],
    };
  } else if (actionObject == "aed") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination: aeDestinations[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject == "awd") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination: awDestinations[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject == "ced") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination: ceDestinations[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject == "bed") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination: beDestinations[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject == "jwd") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        jwDestinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject == "jed") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        jedDestinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject == "ewd") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        ewDestinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject == "rdrk") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        rdrkDestinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject == "nsrd") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination: nsrDestinations[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject == "gw") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        awOffDestinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject == "cwgld") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination: cwgldDestinations[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject == "srbe") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination: srbeDestinations[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject == "hw") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        hwDestinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject == "hed") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination: heDestinations[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject == "sr") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        strDestinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject == "ce") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        ceDestinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject == "iw") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        iwDestinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject == "sor") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        sorDestinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject == "ded") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination: deDestinations[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject == "bw") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination: bwdDestination[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject == "ssr") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination: ssrDestination[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject == "rsp") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination: rspDestination[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject == "dw") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination: dwDestination[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject == "fw") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        fwDestinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject == "ge") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        geDestinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject == "cw") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        cwDestinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject == "fe") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        feDestinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject == "ie") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        ieDestinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject == "rwrk") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        rwrkDestinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject == "dgld") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        dgldDestinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject == "l1") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        l1DestinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject == "l3") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        l3DestinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  } else {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        otherDestinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  }
};

export const expireAuthToken = () => {
  setLocalStorageItem("isAuthExpiry", true);
  localStorage.clear();
  reloadApp();
};

export const getAvailablePlant = (locationCode) => {
  const availablePlantList = [
    "CAR",
    "ATL",
    "DET",
    "ALA",
    "HAZ",
    "HOU",
    "LAS",
    "MIA",
    "TAC",
    "JAX",
    // "LAN",
    "ST3",
    // "BAL",
  ];
  if (availablePlantList.includes(locationCode)) {
    return true;
  } else {
    return false;
  }
};
/**
 * Up to Down
 * Increase the count, go down
 * Decrease the count, go up
 * Left to Right
 * Increase to count, go
 * Decrease the count, go
 */

export const getPlantCoordinates = (plant, renderObject) => {
  if (plant == "CAR") {
    if (renderObject == "lgv" || renderObject == "heatmap") {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 14500,
        rectTopRightX: 523000,
        rectTopRightY: 168000,
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 523000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant == "ATL") {
    if (renderObject == "heatmap") {
      return {
        rectBottomLeftX: 82000,
        rectBottomLeftY: 30500,
        rectTopRightX: 463000,
        rectTopRightY: 148000,
      };
    } else if (renderObject == "lgv") {
      return {
        rectBottomLeftX: 85000, //R->L
        rectBottomLeftY: 32000, //T->B
        rectTopRightX: 472000, //R->L shrink
        rectTopRightY: 155000, // T->B shrink
      };
    } else {
      return {
        //map
        rectBottomLeftX: 47000, //15000
        rectBottomLeftY: 13000, //333000
        rectTopRightX: 523000, //0
        rectTopRightY: 171000, //143000
      };
    }
  } else if (plant == "DET") {
    if (renderObject == "heatmap") {
      return {
        rectBottomLeftX: 127000, //R->L
        rectBottomLeftY: -55000, //T->B
        rectTopRightX: 427000, //R->L shrink
        rectTopRightY: 182000, // T->B shrink
      };
    } else if (renderObject == "lgv") {
      return {
        rectBottomLeftX: 127000, //R->L
        rectBottomLeftY: 36000, //T->B
        rectTopRightX: 447000, //R->L shrink
        rectTopRightY: 170000, // T->B shrink
      };
    } else {
      return {
        //map
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 523000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant == "ALA") {
    if (renderObject == "heatmap") {
      return {
        rectBottomLeftX: 105000, //R->L
        rectBottomLeftY: 25000, //T->B
        rectTopRightX: 410000, //R->L shrink
        rectTopRightY: 172000, // T->B shrink
      };
    } else if (renderObject == "lgv") {
      return {
        rectBottomLeftX: 105000, //R->L
        rectBottomLeftY: 25000, //T->B
        rectTopRightX: 410000, //R->L shrink
        rectTopRightY: 190000, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 20000,
        rectTopRightX: 523000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant == "HAZ") {
    if (renderObject == "heatmap") {
      return {
        rectBottomLeftX: 237000, //R->L
        rectBottomLeftY: 230000, //T->B
        rectTopRightX: 832000, //R->L shrink
        rectTopRightY: 450000, // T->B shrink
      };
    } else if (renderObject == "lgv") {
      return {
        rectBottomLeftX: 237000, //R->L
        rectBottomLeftY: 270000, //T->B
        rectTopRightX: 832000, //R->L shrink
        rectTopRightY: 470000, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 523000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant == "HOU") {
    if (renderObject == "heatmap") {
      return {
        rectBottomLeftX: 108000, //R->L
        rectBottomLeftY: 13000, //T->B
        rectTopRightX: 472000, //R->L shrink
        rectTopRightY: 121000, // T->B shrink
      };
    } else if (renderObject == "lgv") {
      return {
        rectBottomLeftX: 109000, //R->L
        rectBottomLeftY: 33000, //T->B
        rectTopRightX: 462000, //R->L shrink
        rectTopRightY: 151000, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 523000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant == "LAS") {
    if (renderObject == "heatmap") {
      return {
        rectBottomLeftX: 81000, //R->L
        rectBottomLeftY: -16000, //T->B
        rectTopRightX: 370000, //R->L shrink
        rectTopRightY: 142000, // T->B shrink
      };
    } else if (renderObject == "lgv") {
      return {
        rectBottomLeftX: 81000, //R->L
        rectBottomLeftY: 33000, //T->B
        rectTopRightX: 370000, //R->L shrink
        rectTopRightY: 169000, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 523000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant == "MIA") {
    if (renderObject == "heatmap") {
      return {
        rectBottomLeftX: 81000, //R->L
        rectBottomLeftY: 0, //T->B
        rectTopRightX: 355000, //R->L shrink
        rectTopRightY: 155000, // T->B shrink
      };
    } else if (renderObject == "lgv") {
      return {
        rectBottomLeftX: 81000, //R->L
        rectBottomLeftY: 33000, //T->B
        rectTopRightX: 355000, //R->L shrink
        rectTopRightY: 190000, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 523000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant == "TAC") {
    if (renderObject == "heatmap") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -30000, //T->B
        rectTopRightX: 295000, //R->L shrink
        rectTopRightY: 140000, // T->B shrink
      };
    } else if (renderObject == "lgv") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: 17500, //T->B
        rectTopRightX: 295000, //R->L shrink
        rectTopRightY: 167000, // T->B shrink
      };
    } else if (renderObject == "destinations") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -42500, //T->B
        rectTopRightX: -292000, //R->L shrink
        rectTopRightY: -450000, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 523000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant == "JAX") {
    if (renderObject == "heatmap") {
      return {
        rectBottomLeftX: 80000, //R->L
        rectBottomLeftY: -20000, //T->B
        rectTopRightX: 525000, //R->L shrink
        rectTopRightY: 170000, // T->B shrink
      };
    } else if (renderObject == "lgv") {
      return {
        rectBottomLeftX: 80000, //R->L
        rectBottomLeftY: 20500, //T->B
        rectTopRightX: 525000, //R->L shrink
        rectTopRightY: 200000, // T->B shrink
      };
    } else if (renderObject == "destinations") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -42500, //T->B
        rectTopRightX: -292000, //R->L shrink
        rectTopRightY: -450000, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 523000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant == "LAN") {
    if (renderObject == "heatmap") {
      return {
        rectBottomLeftX: 10000, //R->L
        rectBottomLeftY: -50000, //T->B
        rectTopRightX: 524000, //R->L shrink
        rectTopRightY: 190000, // T->B shrink
      };
    } else if (renderObject == "lgv") {
      return {
        rectBottomLeftX: -2000, //R->L
        rectBottomLeftY: 20500, //T->B
        rectTopRightX: 524000, //R->L shrink
        rectTopRightY: 245000, // T->B shrink
      };
    } else if (renderObject == "destinations") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -42500, //T->B
        rectTopRightX: -292000, //R->L shrink
        rectTopRightY: -450000, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 523000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant == "ST3") {
    if (renderObject == "heatmap") {
      return {
        rectBottomLeftX: 80000, //R->L
        rectBottomLeftY: -100000, //T->B
        rectTopRightX: 462500, //R->L shrink
        rectTopRightY: 140000, // T->B shrink
      };
    } else if (renderObject == "lgv") {
      return {
        rectBottomLeftX: 80000, //R->L
        rectBottomLeftY: 20500, //T->B
        rectTopRightX: 462500, //R->L shrink
        rectTopRightY: 165000, // T->B shrink
      };
    } else if (renderObject == "destinations") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -42500, //T->B
        rectTopRightX: -292000, //R->L shrink
        rectTopRightY: -450000, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 523000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant == "BAL") {
    if (renderObject == "heatmap") {
      return {
        rectBottomLeftX: 93000, //R->L
        rectBottomLeftY: -100000, //T->B
        rectTopRightX: 463500, //R->L shrink BAL
        rectTopRightY: 140000, // T->B shrink
      };
    } else if (renderObject == "lgv") {
      return {
        rectBottomLeftX: 93000, //R->L
        rectBottomLeftY: 1500, //T->B
        rectTopRightX: 463500, //R->L shrink BAL
        rectTopRightY: 169000, // T->B shrink
      };
    } else if (renderObject == "destinations") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -42500, //T->B
        rectTopRightX: -292000, //R->L shrink
        rectTopRightY: -450000, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 523000,
        rectTopRightY: 171000,
      };
    }
  }
};

export const getBottomTopCoordinates = () => {
  // return {
  //     rectBottomLeftXNew: 0,
  //     rectBottomLeftYNew: 594.453125,
  //     rectTopRightXNew: 1446,
  //     rectTopRightYNew: 5.453125
  // }
  return {
    rectBottomLeftXNew: 0,
    rectBottomLeftYNew: 500,
    rectTopRightXNew: 1446,
    rectTopRightYNew: 5.453125,
  };
};

export const isKafkaDataAvailable = () => {
  let plantAvailable = {
    CAR: false,
    ATL: true,
    DET: true,
    ALA: true,
    HAZ: true,
    HOU: true,
    LAS: true,
    MIA: true,
    TAC: true,
    JAX: true,
    LAN: true,
    ST3: true,
    BAL: true,
  };
  return plantAvailable[getLocalStorageItem("locationCode")];
};

export const getPlantStates = (shortName) => {
  let plantList = {
    CAR: "Carolina",
    ATL: "Atlanta",
    DET: "Detroit",
    ALA: "Alabama",
    HAZ: "Hazleton",
    HOU: "Houston",
    LAS: "Las Vegas",
    MIA: "Miami",
    TAC: "Tacoma",
    JAX: "Jacksonville",
    // LAN: "Lancaster",
    ST3: "Stockton 3",
    // BAL: "Baltimore",
  };
  return plantList[shortName];
};

export const filterDuplicateProductionLine = (lineData) => {
  if (lineData && lineData !== "Error connecting to datasource!") {
    const filteredObjects = lineData.reduce((accumulator, currentObject) => {
      const existingObject = accumulator.find(
        (obj) =>
          obj.LINE_NAME === currentObject.LINE_NAME &&
          obj.LINE === currentObject.LINE
      );

      if (
        !existingObject ||
        (currentObject.STATUS === "Finishing" &&
          existingObject.STATUS !== "Finishing")
      ) {
        return [
          ...accumulator.filter(
            (obj) =>
              !(
                obj.LINE_NAME === currentObject.LINE_NAME &&
                obj.LINE === currentObject.LINE
              )
          ),
          currentObject,
        ];
      }

      return accumulator;
    }, []);
    return filteredObjects;
  }
};

export const lgvNameRenderer = (vehicleNumber) => {
  if (vehicleNumber.includes("LGV")) {
    return vehicleNumber.split("V")[1];
  } else {
    return vehicleNumber;
  }
};

export const storeDockDoorCoordinates = (mapObject, groupedData) => {
  if (groupedData.length > 20) {
    let dockCoordinates = [];
    groupedData.forEach((data, index) => {
      let dockObject = {
        index: 0,
        DOCK: "",
        LATITUDE: 0,
        LONGITUDE: 0,
        currentPos: {
          lat: 0,
          lng: 0,
        },
      };
      dockObject["index"] = index;
      dockObject["DOCK"] = data[0]["DOCK"];
      dockObject["LATITUDE"] = data[0]["LATITUDE"];
      dockObject["LONGITUDE"] = data[0]["LONGITUDE"];
      dockObject["currentPos"] = data[0]["currentPos"];
      let convertedCoordinates = mapCoordinate(
        data[0]["LONGITUDE"],
        data[0]["LATITUDE"]
      );
      let coordinateCorrection = plantDockDoorCoordinateCorrection();
      let convertedPoints = L.point(
        convertedCoordinates.x + coordinateCorrection.x,
        convertedCoordinates.y + coordinateCorrection.y
      );
      let convertedLatLng =
        mapObject.map.target.layerPointToLatLng(convertedPoints);
      dockObject["currentPos"] = {
        lat: convertedLatLng.lat,
        lng: convertedLatLng.lng,
      };
      dockCoordinates[index] = dockObject;
    });
    setLocalStorageItem("dockCoordinates", dockCoordinates);
  }
};

export const removeLgvs = () => {
  let lgvLayer = document.querySelectorAll("span.lgv-icon-style");
  for (let i = 0; i < lgvLayer.length; i++) {
    lgvLayer[i].remove();
  }
};

export const removeExistingProductionLines = () => {
  let productionLayerHolder = document.querySelectorAll(
    "div.line-prod-container"
  );
  productionLayerHolder.forEach((layer) => {
    layer.remove();
  });
};

export const removeDockDoorLayer = () => {
  let dockDoorLayer = document.querySelectorAll("div.dock-door");
  for (let i = 0; i < dockDoorLayer.length; i++) {
    dockDoorLayer[i].remove();
  }
};

export const trafficLgvDataGrouping = (trafficData) => {
  let trafficObject = {};
  trafficData.forEach((data, index) => {
    let objectName = data.LGV_ID;
    if (!trafficObject[objectName]) {
      trafficObject[objectName] = [];
    }
    trafficObject[objectName].push(data);
  });
  return trafficObject;
};

export const mapCoordinate = (coordinateX, coordinateY, renderObject) => {
  let locationCode = getLocalStorageItem("locationCode");
  if (locationCode) {
    const { rectBottomLeftX, rectBottomLeftY, rectTopRightX, rectTopRightY } =
      getPlantCoordinates(locationCode, renderObject);

    const {
      rectBottomLeftXNew,
      rectBottomLeftYNew,
      rectTopRightXNew,
      rectTopRightYNew,
    } = getBottomTopCoordinates();

    let ratioX =
      (coordinateX - rectBottomLeftX) / (rectTopRightX - rectBottomLeftX);
    let ratioY =
      (coordinateY - rectBottomLeftY) / (rectTopRightY - rectBottomLeftY);

    let translatedX =
      rectBottomLeftXNew + ratioX * (rectTopRightXNew - rectBottomLeftXNew);
    let translatedY =
      rectBottomLeftYNew + ratioY * (rectTopRightYNew - rectBottomLeftYNew);

    return {
      x: translatedX,
      y: translatedY,
    };
  }
};

export const convertReactComponentToHtmlIcon = (
  reactComponent,
  className,
  iconSize,
  iconAnchor
) => {
  const reactToHtml = ReactDOMServer.renderToString(reactComponent);
  return L.divIcon({
    className: className,
    iconSize: iconSize,
    iconAnchor: iconAnchor ? iconAnchor : null,
    html: reactToHtml,
  });
};

export const convertReactComponentToStringHtml = (reactComponent) => {
  return ReactDOMServer.renderToString(reactComponent);
};

//Combine Kafka LGV data and Table LGV data
export const combineKafkaSocketAndLGVDetailsSocket = (
  lgvDetails,
  kafkaDetails
) => {
  let filteredData = kafkaDetails.filter(
    (lgv) => lgv.latitude !== "undefined" && lgv.longitude !== "undefined"
  );
  if (filteredData !== "NO_DATA" || filteredData !== undefined) {
    lgvDetails.forEach((lgv) => {
      filteredData.forEach((kafkaLgv) => {
        let lgvNumber = kafkaLgv.vehicleNumber.split("V")[1];
        if (lgv.vehicleNumber == lgvNumber) {
          let currentPos = {
            lat: kafkaLgv.latitude,
            lng: kafkaLgv.longitude,
          };
          lgv.currentPos = currentPos;
        }
      });
    });
    return lgvDetails;
  } else {
    return lgvDetails;
  }
};

export const checkIsLocationCodeValid = (locationCode, locationCodeLocal) => {
  if (locationCode !== "" || locationCodeLocal !== null) {
    return true;
  } else {
    return false;
  }
};

export const checkIsTimeMachineEnabled = () => {
  if (getLocalStorageItem("isTimeMachineModeEnabled")) {
    return true;
  } else {
    return false;
  }
};
