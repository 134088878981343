import { useContext, useEffect, useState } from 'react';
import '../../../assets/css/DockDoor/dockDoorPopup.css';
import { apiHeadersFormatter, dockDoorStatusColor, expireAuthToken, findDockDoorPosition, setLocalStorageItem } from '../../../util/helper/helperFunctions';
import { AppContext } from '../../../Context/Context';
import getApi from '../../../util/network/getApi';
import { dockDoorBriefStatus } from '../../../util/network/apiUrl';
import { Progress } from 'antd';
import { noDataDockDetails } from '../../../util/helper/constants';

const DockDoorPopup = ({dockData, dockMode}) => {
    const {isMarkerPopupOpen, setIsMarkerPopupOpen} = useContext(AppContext);
    const [dockDoorBriefData, setDockDoorBriefData] = useState();
    const [dockModeLocData, setDockModeLocData] = useState();
    const {selectedMarkerData, setSelectedMarkerData} = useContext(AppContext);
    const [palletsLoader, setPalletsLoader] = useState(true);
    const noData = 'No Data Found';
    let dockDetailsInterval;

    document.addEventListener('click', (e) => {
        if(e.target == document.getElementById('dock-door-wrapper-id')) {
            killTimeInterval();
            document.body.classList.remove('prevent-scroll');
            setIsMarkerPopupOpen(false);
        }
    })

    useEffect(() => {
        let dockDoorPosition = findDockDoorPosition();
        if(dockDoorPosition == 'DOWN') {
            let localDockDoor = JSON.parse(JSON.stringify(dockData));
            setDockModeLocData(localDockDoor.reverse());
        } else {
            setDockModeLocData(dockData);
        }
        setTimeout(() => {
            setPalletsLoader(false);
        }, 1500);

        return () => {
            setPalletsLoader(true);
        }
    }, [])

    useEffect(() => {
        if(selectedMarkerData) {
            let dockDoorPosition = findDockDoorPosition();
            if(dockDoorPosition == 'DOWN') {
                let localDockDoor = JSON.parse(JSON.stringify(selectedMarkerData));
                setDockModeLocData(localDockDoor.reverse());
            } else {
                setDockModeLocData(selectedMarkerData);
            }
            setTimeout(() => {
                setPalletsLoader(false);
            }, 1500);
        }
    }, [selectedMarkerData])

    const killTimeInterval = () => {
        clearInterval(dockDetailsInterval);
        dockDetailsInterval = null;
    }

    useEffect(() => {
        document.body.classList.add('prevent-scroll');
        setLocalStorageItem('dockDoorTouched', dockData[0]['DOCK']);
    }, [])

    useEffect(() => {
        if(isMarkerPopupOpen && dockData[0]['DOCK'] !== 0) {
            let auth = JSON.parse(localStorage.getItem('auth'));
            let locationCodeLocal = JSON.parse(localStorage.getItem('locationCode'));
            if(auth && locationCodeLocal) {
                const getDockDoorDetails = async () => {
                    let selectedDock = dockData[0]['DOCK'];
                    let dockDoorData = await getApi(`${dockDoorBriefStatus}/${selectedDock}?location=${locationCodeLocal}`, apiHeadersFormatter(auth.accessToken));
                    localStorage.setItem('isModalDetailExist', JSON.stringify(true));
                    if(dockDoorData.status == 200) {
                        if(dockDoorData.data !== '') {
                            setDockDoorBriefData(dockDoorData.data);
                        } else {
                            setDockDoorBriefData(noDataDockDetails);
                        }
                    } else {
                        if(dockDoorData.response.status == 401) {
                            expireAuthToken();
                        }
                    }
                }
                
                dockDetailsInterval = setInterval(() => {
                    getDockDoorDetails();
                }, 2000)
            }
            return () => {
                clearInterval(dockDetailsInterval);
                dockDetailsInterval = null;
            }
        }
    }, [isMarkerPopupOpen]);


    const parseCurrentTimeStamp = (timestamp) => {
        if(timestamp !== 'No Data Found') {
            let date = timestamp.split('T');
            let time = date[1].split('Z');
            return String(date[0].concat(time[0]));
        } else {
            return 'No Data Found'
        }
    }

    return (
        <div id='dock-door-wrapper-id' className='dock-door-wrapper'>
            <div className='dock-door-wrapper-container'>
                <div className='dock-door-details-section'>
                    <div className='dock-door-detail-left-section'>
                        <div className='left-detail-title-section'>
                            <h3>Dock Door Details</h3>
                        </div>
                        {dockDoorBriefData ?
                            <div className='left-detail-data-section'>
                                <div className='detail-section'>
                                    <h4>Door</h4>
                                    <p>{dockDoorBriefData?.['DOOR'] !== "" ? dockDoorBriefData?.['DOOR'] : noData}</p>
                                </div>
                                <div className='detail-section'>
                                    <h4>Shipment</h4>
                                    <p>{dockDoorBriefData?.['SHIPMENT'] !== "" ? dockDoorBriefData?.['SHIPMENT'] : noData}</p>
                                </div>
                                <div className='detail-section'>
                                    <h4>Snapshot</h4>
                                    <p>{dockDoorBriefData['CURRENT_DATE_TIME'] !== "" ? parseCurrentTimeStamp(dockDoorBriefData?.['CURRENT_DATE_TIME']) : noData}</p>
                                </div>
                                <div className='detail-section'>
                                    <h4>Type</h4>
                                    <p>{dockDoorBriefData?.['TYPE'] !== "" ? dockDoorBriefData?.['TYPE'] : noData}</p>
                                </div>
                                <div className='detail-section'>
                                    <h4>Status</h4>
                                    <p>{dockDoorBriefData?.['STATUS'] !== "" ? dockDoorBriefData?.['STATUS'] : noData}</p>
                                </div>
                                <div className='detail-section'>
                                    <h4>RemainPall</h4>
                                    <p>{dockDoorBriefData?.['REMAINPALL']}</p>
                                </div>
                                <div className='detail-section'>
                                    <h4>Load Time</h4>
                                    <p>{dockDoorBriefData?.['LOADTIME']}</p>
                                </div>
                                <div className='detail-section'>
                                    <h4>Process Time</h4>
                                    <p>{dockDoorBriefData?.['PROCESSTIME']}</p>
                                </div>
                                <div className='detail-section'>
                                    <h4>Progress</h4>
                                    <Progress percent={dockDoorBriefData?.['PROGRESS'] ? dockDoorBriefData?.['PROGRESS'] : 0} />
                                </div>
                                <div className='detail-section'>
                                    <h4>Allocated</h4>
                                    <p>{dockDoorBriefData['ALLOCATED']}</p>
                                </div>
                                <div className='detail-section'>
                                    <h4>Released</h4>
                                    <p>{dockDoorBriefData['RELEASED']}</p>
                                </div>
                                <div className='detail-section'>
                                    <h4>Active</h4>
                                    <p>{dockDoorBriefData?.['ACTIVE']}</p>
                                </div>
                            </div> : null}
                        {!dockDoorBriefData?.['ACTIVE'] ? 
                            <div className='dock-door-brief-loader'>
                                {dockDoorBriefData == noDataDockDetails ? 
                                    <h4>No Data Available!</h4> : 
                                    <>
                                        <h4>Fetching Dock Data...</h4>
                                        <div className='spinner'></div>
                                    </>
                                }
                            </div>
                        : null}
                    </div>
                    <div className='dock-door-detail-right-section'>
                        <div className='dock-door-popup'>
                            <div className="dock-door-popup-container">
                                {!palletsLoader ? 
                                    <>
                                        <div className='dock-popup-top-section'>{dockModeLocData && dockModeLocData[0]['DOCK'].split('.')[1]}</div>
                                        <div className='dock-popup-bottom-section'>
                                            {dockModeLocData.map((value, index) => {
                                                if(value !== undefined) {
                                                    if(value.PALLETS == 1) {
                                                        return <div key={index} style={{backgroundColor: dockDoorStatusColor(value.STATUS, !dockMode ? 'SIMPLE' : 'COMPLEX')}} className={`dock-popup dock-popup-small`}>
                                                            <h4>{value.SKU}<br/>{value.STATUS}</h4>
                                                        </div>
                                                    } else {
                                                        return <div key={index} className='dock dock-popup-big'>
                                                            <div style={{backgroundColor: dockDoorStatusColor(value.STATUS, !dockMode ? 'SIMPLE' : 'COMPLEX')}} className='dock-popup dock-popup-big-complex-left'>
                                                                <h4>{value.SKU}<br/>{value.STATUS}</h4>
                                                            </div>
                                                            <div style={{backgroundColor: dockDoorStatusColor(value.STATUS, !dockMode ? 'SIMPLE' : 'COMPLEX')}} className='dock-popup dock-popup-big-complex-right'>
                                                                <h4>{value.SKU}<br/>{value.STATUS}</h4>
                                                            </div>
                                                        </div>
                                                    }
                                                }
                                        })}
                                        </div>
                                    </> : 
                                    <div className='rm-loader'>
                                        <h4>Loading...</h4>
                                        <div className='spinner'></div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DockDoorPopup;